import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Landing_Footer from "../../components/Landing_Footer";
import Heading_Section from "../../components/Heading_Section";
import InputWithSearch from "../../components/InputWithSearch";
import { imagePath } from "../../constants/imagePath";
import Image_Text_Comp from "../../components/Image_Text_Comp";
import Card_With_Heading from "../../components/Card_With_Heading";
import Cards from "../../components/Cards";
import CardReactFormContainer from "card-react";
import Testimonial from "../../components/Testimonial";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Date_Input from "../../components/Date_Input";

const LandingPage_6 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-six">
          <div className="landing-page-six-section">
            <div className="container">
              <Image_Text_Comp
                white={true}
                fontSize={17}
                image1={imagePath.Interior_Design_3}
                image2={imagePath.Interior_Design_4}
                padding="40px 0px"
                vectorImage={imagePath.Design_vector_yellow}
                heading_White="Looking for a Furnished Rental? We’ve Got You Covered!"
                subcontent="Submit your preferences and get matched with one of our great furnished units"
              />
            </div>
          </div>
          <div className="six-page-bg">
            <img src={imagePath.Landing_Page_Bg} />
          </div>
        </div>
        <div className="gray-background">
          <div className="container">
            <Card_With_Heading
              heading_black="How "
              heading_black2="Work ?"
              heading_green="Angies Helpers"
              subContent="Providing expert insights and updates on the latest trends in home stay and property management"
            />

            <div className="landing-grid-container" id="landing-page-2-grid">
              <Cards
                image={imagePath.Personalized_Setting}
                cardTitle="Personalized Rental Matching"
                cardDescription="We handle listing optimization, pricing, guest communication, check-ins/outs, cleaning, and maintenance to maximize occupancy and income."
              />

              <Cards
                image={imagePath.Professional_Quality}
                cardTitle="High-Quality"
                cardDescription="We provide tenant screening, lease management, rent collection, maintenance, and tenant relations to ensure satisfaction and upkeep."
              />

              <Cards
                image={imagePath.Flexible}
                cardTitle="Flexible"
                cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
              />

              <Cards
                image={imagePath.Headphone}
                cardTitle="Comprehensive Support"
                cardDescription="We provide tenant screening, lease management, rent collection, maintenance, and tenant relations to ensure satisfaction and upkeep."
              />

              <Cards
                image={imagePath.Maintenance_Home}
                cardTitle="Maintenance and Upkeep"
                cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
              />

              <Cards
                image={imagePath.Dollar}
                cardTitle="Transparent Pricing"
                cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
              />
            </div>
          </div>
        </div>

        <div className="choose-your-appointment-section">
          <div className="container">
            <div className="choose-your-appointment">
              <Heading_Section
                white={false}
                heading_Black="Property"
                heading_Green="Details"
                subcontent="At AngiesHelpers.com, our journey began with a simple mission: to provide property owners with a hassle-free, profitable rental management experience"
              />

              <div className="property-detail-section" id="landing-page-5-form">
                <div className="container">
                  <div className="property-detail-form">
                    <div
                      className="property-form-section"
                      id="property-section"
                    >
                      <div id="property-details-form">
                        <div className="property-form-5">
                          <p className="fontSize18_black">
                            Enter Your Rental Preferences Below
                          </p>
                          <form>
                            <div className="date-input">
                              <Date_Input placeholder="Move-In-Date" />
                              <Date_Input placeholder="Year" />
                            </div>

                            <select
                              class="form-select mb-2"
                              id="property-selection"
                              aria-label="Default select example"
                            >
                              <option selected>Rental Duration</option>
                              <option value="Apartment">
                                <img src={imagePath.Left_Caret} alt="" /> Months
                              </option>
                              <option value="House">
                                <img src={imagePath.Left_Caret} alt="" />
                                Year
                              </option>
                              <option value="Condo">
                                <img src={imagePath.Left_Caret} alt="" /> other
                              </option>
                            </select>
                            <select
                              class="form-select mb-2"
                              id="property-selection"
                              aria-label="Default select example"
                            >
                              <option selected>Property Type</option>
                              <option value="Apartment">
                                <img src={imagePath.Left_Caret} alt="" />{" "}
                                Apartment
                              </option>
                              <option value="House">
                                <img src={imagePath.Left_Caret} alt="" /> House
                              </option>
                              <option value="Condo">
                                <img src={imagePath.Left_Caret} alt="" /> Condo
                              </option>
                            </select>

                            <select
                              class="form-select mb-2"
                              aria-label="Default select example"
                            >
                              <option selected>Number Of Bedrooms</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>

                            <select
                              class="form-select mb-2"
                              aria-label="Default select example"
                            >
                              <option selected>Number Of Bathrooms</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>

                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Preferred Location"
                              />
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Budget Range"
                              />
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input "
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                Pool
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Garage
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Furnished
                              </label>
                            </div>

                            <button className="btn-style" id="form-button">
                              Next: Review Matches
                            </button>
                          </form>
                        </div>
                      </div>
                      <div className="property-image">
                        <img src={imagePath.Property_Details_6} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="property-description-section">
          <div className="container">
            <Heading_Section
              left
              heading_Black="Find Your"
              heading_Green=" Perfect Matches "
              subcontent="We're working hard to find you the best matches. This may take a few seconds."
            />
            <div className="property-description">
              <div className="property-one">
                <img src={imagePath.Perfect_Match_1} />
                <div>
                  <h1 className="fontSize20_black">Sunny 2BR in SOMA</h1>
                  <p className="fontSize16_gray">$2800/month - 2BR, 1BA</p>
                </div>
              </div>
              <div className="property-one">
                <img src={imagePath.Perfect_Match_2} />
                <div>
                  <h1 className="fontSize20_black">Beautiful 1BR in Mission</h1>
                  <p className="fontSize16_gray">$2000/month - 1BR, 1BA</p>
                </div>
              </div>
            </div>

            <div className="why-choose-btn">
              <button className="btn-style">Request More Info</button>
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div id="enter-email-card-6">
              <h3 className="fontSize20_white">
                Enter Your Email to Receive Detailed Information on Your Matches
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-testimonials-main-section">
          <div className="container">
            <div className="client-testimonials" id="landing-page-6-slide">
              <div className="testimonials">
                <h1 className="fontSize48_black" id="heading">
                  What Our<span className="fontSize48_green"> Client Says</span>{" "}
                </h1>
                <p className="fontSize22_gray ">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>

              <Slider {...settings}>
                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <p className="fontSize18_Gray_2  d-flex gap-1 mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum ipsum velit, imperdiet congue gravida varius,
                    viverra ut turpis. Maecenas non eros non tellus fermentum
                    gravida at et lectus. Nulla enim nulla, ultricies et
                    bibendum luctus, aliquet at nisl. Phasellus vitae
                    consectetur ipsum, non ultrices augue.
                  </p>
                  <div className="testimonial-user">
                    <img src={imagePath.Client_7} alt="" />
                    <div className="user-name d-flex flex-column gap-1">
                      <b>
                        {" "}
                        <h3 className="fontSize24_green">Brian Baker</h3>
                      </b>
                      <h5 className="fontSize17_DarkGray m-0">Developer</h5>
                    </div>
                  </div>
                </div>

                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <p className="fontSize18_Gray_2  d-flex gap-1 mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum ipsum velit, imperdiet congue gravida varius,
                    viverra ut turpis. Maecenas non eros non tellus fermentum
                    gravida at et lectus. Nulla enim nulla, ultricies et
                    bibendum luctus, aliquet at nisl. Phasellus vitae
                    consectetur ipsum, non ultrices augue.
                  </p>
                  <div className="testimonial-user">
                    <img src={imagePath.Client_8} alt="" />
                    <div className="user-name d-flex flex-column gap-1">
                      <b>
                        {" "}
                        <h3 className="fontSize24_green">Anita Bath</h3>
                      </b>
                      <h5 className="fontSize17_DarkGray m-0">Developer</h5>
                    </div>
                  </div>
                </div>

                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <p className="fontSize18_Gray_2  d-flex gap-1 mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum ipsum velit, imperdiet congue gravida varius,
                    viverra ut turpis. Maecenas non eros non tellus fermentum
                    gravida at et lectus. Nulla enim nulla, ultricies et
                    bibendum luctus, aliquet at nisl. Phasellus vitae
                    consectetur ipsum, non ultrices augue.
                  </p>
                  <div className="testimonial-user">
                    <img src={imagePath.Client_7} alt="" />
                    <div className="user-name d-flex flex-column gap-1">
                      <b>
                        {" "}
                        <h3 className="fontSize24_green">Brian Baker</h3>
                      </b>
                      <h5 className="fontSize17_DarkGray m-0">Developer</h5>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section" id="why-choose-us">
          <div className="container" id="landing-page-6-container">
            <div className="core-values-main ">
              <Heading_Section
                white={false}
                heading_Black="Why Choose Our "
                heading_Green=" Furnished Rentals?"
                subcontent=" Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings."
              />

              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Professional_Quality}
                  cardTitle="High-Quality Units"
                  cardDescription="Utilize our advanced AI technology to compare rental incomes with unparalleled precision, ensuring you make informed and profitable decisions."
                />

                <Cards
                  image={imagePath.Hollow_Location_2}
                  cardTitle="Convenient Locations"
                  cardDescription="Enhance your rental approach with our expertly crafted strategies, specifically designed to help you optimize your property and achieve maximum profit"
                />

                <Cards
                  image={imagePath.Flexible}
                  cardTitle="Flexible Terms"
                  cardDescription="Access detailed and in-depth reports that provide actionable insights, helping you understand your rental market better and make smarter investment choices."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <Landing_Footer />
    </>
  );
};

export default LandingPage_6;
