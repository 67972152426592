import React from "react";
import Landing_Footer from "../../components/Landing_Footer";
import Navbar from "../../components/Navbar";
import { imagePath } from "../../constants/imagePath";
import RightSection_Comp from "../../components/RightSection_Comp";
import Heading_Section from "../../components/Heading_Section";
import Testimonial from "../../components/Testimonial";
import Testimonial_video from "../../components/testimonial_video";
import Included_Checklist from "../../components/Included_Checklist";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cards from "../../components/Cards";
import LeftSection_Comp from "../../components/LeftSection_Comp";

const LandingPage_10 = () => {
  const settings = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 3,
    arrow: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid" id="landing-page-ten-section">
        <div className="landing-page-ten-section">
          <div className="container">
            <div className="streamline-operations">
              <h1 className="fontSize50_white">
                Find the Best Tenants with Our Comprehensive Screening Guide !
              </h1>
              <p className="fontSize17_white ">
                Learn how to effectively screen tenants and secure reliable
                renters
              </p>
            </div>
          </div>
        </div>

        <div className="container" id="screening-guide">
          <LeftSection_Comp
            mainHeading="Find Top Tenants: Detailed "
            subHeading="Screening Guide"
            paragraph="Our market report offers insights into current trends, future forecasts, and essential data for informed rental property decisions. It helps you stay ahead of the curve and refine your rental strategy. With detailed information, you'll optimize your approach effectively. Stay informed and make strategic choices with confidence."
            btnText="Get Your Guide Now"
            image={imagePath.Screening_Guide}
            visibleButton
          />
        </div>

        <div className="checklist-main-section">
          <div className="container">
            <div className="checklist-section" id="lease-generation-slide">
              <Heading_Section
                white={false}
                heading_Black="See What’s "
                heading_Green="Inside"
                fontSize="20"
                subcontent="Discover essential tips and tasks to ensure everything is in order for a smooth and successful stay. From preparation to guest experience, our checklist has you covered"
              />
              <Testimonial_video
                leaseGeneration={true}
                leaseImage={true}
                greenText
                rounded
                blackText
                visibleButton={true}
                btnText="The best modern home"
                id="testimonial-id"
                image={true}
                image1={imagePath.Lease_Generation_1}
                image2={imagePath.Lease_Generation_2}
                image3={imagePath.Lease_Generation_3}
                image4={imagePath.Lease_Generation_4}
                image5={imagePath.Lease_Generation_1}
                image6={imagePath.Lease_Generation_2}
              />
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card" id="enter-email-card-10">
              <h3 className="fontSize20_white">
                Enter Your Email to Download the Full Market Report
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="included-in-checklist">
          <div className="container">
            <Heading_Section
              heading_Black="What’s Included in the "
              heading_Green="Guide?"
              subcontent="Download our free 8-point checklist to make sure you're not missing anything important."
              left={true}
            />
            <Included_Checklist listContent="Step-by-Step Screening Process" />

            <Included_Checklist listContent="Essential Background Checks" />

            <Included_Checklist listContent="Interview Questions to Ask" />

            <Included_Checklist listContent="Red Flags to Watch For" />

            <Included_Checklist listContent="Legal Considerations" />
            <Included_Checklist listContent="Expert Tips and Best Practices" />
            <div className="why-choose-btn">
              <button className="btn-style">Get My Report Now</button>
            </div>
          </div>
        </div>

        <div
          className="landing-testimonials-main-section"
          id="landing-section-eight"
        >
          <div className="container">
            <div className="client-testimonials" id="landing-page-10-slide">
              <div className="testimonials">
                <h1 className="fontSize48_black" id="heading">
                  What Our<span className="fontSize48_green"> Client Says</span>{" "}
                </h1>
                <p className="fontSize22_gray ">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>

              <Slider {...settings}>
                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div
                    className="d-flex flex-column-reverse"
                    id="feedback-section"
                  >
                    <div className="qoutes-img-left">
                      <p className="fontSize16_black  d-flex gap-1 mb-2">
                        That was amazing! I loved the pancakes. I’ve never had
                        that type of taste hit my mouth before. Unreal!
                      </p>
                    </div>

                    <div className="testimonial-user">
                      <img src={imagePath.Client_11} alt="" />
                      <div className="user-name d-flex flex-column">
                        <b>
                          {" "}
                          <h3 className="fontSize16_Green">John Doe</h3>
                        </b>
                        <h5 className="fontSize14_MediumGray m-0">
                          SomeCompany LLC.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div
                    className="d-flex flex-column-reverse"
                    id="feedback-section"
                  >
                    <div className="qoutes-img-left">
                      <p className="fontSize16_black  d-flex gap-1 mb-2">
                        That was amazing! I loved the pancakes. I’ve never had
                        that type of taste hit my mouth before. Unreal!
                      </p>
                    </div>

                    <div className="testimonial-user">
                      <img src={imagePath.Client_12} alt="" />
                      <div className="user-name d-flex flex-column">
                        <b>
                          {" "}
                          <h3 className="fontSize16_Green">John Doe</h3>
                        </b>
                        <h5 className="fontSize14_MediumGray m-0">
                          SomeCompany LLC.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div
                    className="d-flex flex-column-reverse"
                    id="feedback-section"
                  >
                    <div className="qoutes-img-left">
                      <p className="fontSize16_black d-flex gap-1 mb-2">
                        That was amazing! I loved the pancakes. I’ve never had
                        that type of taste hit my mouth before. Unreal!
                      </p>
                    </div>
                    <div className="testimonial-user">
                      <img src={imagePath.Client_13} alt="" />
                      <div className="user-name d-flex flex-column">
                        <b>
                          {" "}
                          <h3 className="fontSize16_Green">John Doe</h3>
                        </b>
                        <h5 className="fontSize14_MediumGray m-0">
                          SomeCompany LLC.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section" id="why-choose-us">
          <div className="container" id="landing-page-6-container">
            <div className="core-values-main ">
              <Heading_Section
                white={false}
                heading_Black="Why Use Our"
                heading_Green=" Tenant Screening Guide?"
                subcontent=" Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings."
              />

              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Expert_Analysis}
                  cardTitle="Thorough Screening"
                  cardDescription="Professional quality means delivering exceptional work that meets high standards, ensuring reliability, expertise, and client satisfaction."
                />

                <Cards
                  image={imagePath.Reduce_Risk}
                  cardTitle="Reduce Risk"
                  cardDescription="Time-saving techniques streamline tasks, enhance productivity, reduce stress, and free up time for meaningful activities"
                />

                <Cards
                  image={imagePath.Advocate}
                  cardTitle="Expert Advice"
                  cardDescription="Wide reach allows ideas and messages to impact diverse audiences, fostering broader connections and understanding."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <Landing_Footer />
    </>
  );
};

export default LandingPage_10;
