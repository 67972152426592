import Logo from "../assets/images/AngiesHelpers_logo.svg";
import Call from "../assets/images/call.svg";
import Login from "../assets/images/login.png";
import Signup from "../assets/images/signup.png";
import Home from "../assets/images/home_Icon.svg";
import PhoneCall from "../assets/images/phone-call.svg";
import Mail from "../assets/images/emailll.svg";
import Left_Caret from "../assets/images/triangle.svg";
import Star from "../assets/images/star.svg";
import Search from "../assets/images/search.svg";
import Search2 from "../assets/images/search-normal.svg";
import Estimation from "../assets/images/estimation.svg";
import Market from "../assets/images/market.svg";
import Timer from "../assets/images/timer.svg";
import Dollar from "../assets/images/dollar.svg";
import Tenant_Retention from "../assets/images/tenant-retention.png"

import Green_Call from "../assets/images/call-green.svg";
import Green_Email from "../assets/images/mail-green.svg";

import Green_Location from "../assets/images/location-green.svg";
import Location from "../assets/images/location.svg";
import Hollow_Green_Location from "../assets/images/green-location.svg";
import Hollow_Location_2 from "../assets/images/hollow_location_2.svg";
import Hollow_Green_Size from "../assets/images/green-size.svg";
import Hollow_Green_Time from "../assets/images/green-time.svg";
import Hollow_Green_Home from "../assets/images/green-home.svg";
import Hollow_Green_Calendar from "../assets/images/green-calendar.svg";
import Hollow_Green_Bed from "../assets/images/bed.svg";

import Email from "../assets/images/email.svg";
import Graph1 from "../assets/images/graph.png";
import Graph2 from "../assets/images/graph1.png";

import Feedback_img from "../assets/images/feedback.png";
import Property_Details_2 from "../assets/images/property-details-2.svg";
import Property_Details_4 from "../assets/images/home2.png";
import Property_Details_6 from "../assets/images/home6.svg";
import Chart1 from "../assets/images/airbnb-chart.png";
import Chart2 from "../assets/images/long-term-chart.png";
import Landing_Image from "../assets/images/landing-page.png";
import Property_Details from "../assets/images/property-details.png";
import Report from "../assets/images/report.svg";
import Expert_Analysis from "../assets/images/expert-analysis.svg";
import Proven_Strategies from "../assets/images/proven-strategies.svg";
import Social_bg from "../assets/images/social-link-bg.png";
import People from "../assets/images/team-4.svg";
import Double_Qoutes from "../assets/images/double-qoutes.svg";
import White_Qoutes from "../assets/images/gray-qoutes.svg";
import Heart from "../assets/images/team-1.svg";
import Accountability from "../assets/images/team-5.svg";
import Wrong from "../assets/images/wrong.svg";
import Socket from "../assets/images/socket.svg";
import Star_Network from "../assets/images/star-network.svg";
import Accuracy from "../assets/images/accuracy.svg"

import Rocket from "../assets/images/team-3.svg";
import Airbnb from "../assets/images/Airbnb.svg";
import Headphone from "../assets/images/headphone.svg";
import Minus from "../assets/images/minus-cirlce.svg";
import Calender from "../assets/images/Calendar.svg";
import Plus from "../assets/images/add-circle.svg";
import Settings from "../assets/images/settings.svg";
import main_Settings from "../assets/images/team-2.svg";
import Industy_Img from "../assets/images/industry-img.png";
import Correct from "../assets/images/correct.svg";
import Light from "../assets/images/team-6.svg";

import G_fb from "../assets/images/G-facebook.svg";
import G_insta from "../assets/images/G-insta.svg";
import G_twitter from "../assets/images/G-twitter.svg";
import G_Linkedin from "../assets/images/G-linkedin.svg";
import Green_fb from "../assets/images/green-fb.svg";
import Green_insta from "../assets/images/green-insta.svg";
import Green_twitter from "../assets/images/green-twitter.svg";
import Green_Linkedin from "../assets/images/green-linkedin.svg";
import Facebook from "../assets/images/facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import Twitter from "../assets/images/twitter.svg";
import Linkedin from "../assets/images/skype.svg";

import Innovation from "../assets/images/innovation-img.png";
import New_Features from "../assets/images/new-features-img.png";
import Rental_Management from "../assets/images/rental-Managment.png";
import Regular_Maintanence from "../assets/images/regular-maintainence.png";
import Financial_Planning from "../assets/images/financial-planning.png";
import Tenant_Management from "../assets/images/tenant-management.png";
import Sustainability from "../assets/images/sustainability.png";

import Pricing_Page_Img_1 from "../assets/images/pricing-page-img1.png";
import Pricing_Page_Img_2 from "../assets/images/pricing-page-img2.png";
import Pricing_Page_Img_3 from "../assets/images/pricing-page-img3.png";

import TeamMate1 from "../assets/images/team-1-image.png";
import TeamMate2 from "../assets/images/teamMate-2.svg";
import TeamMate3 from "../assets/images/teamMate-3.svg";
import TeamMate4 from "../assets/images/teamMate-4.svg";
import TeamMate5 from "../assets/images/teamMate-5.svg";
import TeamMate6 from "../assets/images/teamMate-6.svg";
import TeamMate7 from "../assets/images/teamMate-7.svg";
import TeamMate8 from "../assets/images/teamMate-8.svg";

import Details_List from "../assets/images/detail-list.svg";
import Robot from "../assets/images/robot.svg";
import Wifi from "../assets/images/wifi.svg";
import Expertise from "../assets/images/expertize.svg";
import Bar_Chart_1 from "../assets/images/bar-chart1.png"
import Bar_Chart_2 from "../assets/images/bar-chart2.png"

import caseStudies_Img_1 from "../assets/images/case-studies-img1.png";
import caseStudies_Img_2 from "../assets/images/case-studies-img2.png";
import caseStudies_Img_3 from "../assets/images/case-studies-img3.png";
import caseStudies_Img_4 from "../assets/images/case-studies-img4.png";
import caseStudies_Img_5 from "../assets/images/case-studies-img5.png";
import caseStudies_Img_6 from "../assets/images/case-studies-img6.png";

import Hero_Img_1 from "../assets/images/hero-img.png";
import Hero_Img_2 from "../assets/images/hero-img-2.png";
import Hero_Img_3 from "../assets/images/hero-img-3.png";
import Hero_Img_4 from "../assets/images/hero-img-4.png";
import Hero_Img_5 from "../assets/images/hero-img-5.png";
import Hero_Img_6 from "../assets/images/hero-img-6.png";
import Hero_Img_7 from "../assets/images/hero-img-7.png";

import Blog_Img_1 from "../assets/images/blog-img-1.png";
import Blog_Img_2 from "../assets/images/blog-img-2.png";
import Blog_Img_3 from "../assets/images/blog-img-3.png";

import Income_Step_1 from "../assets/images/income-step-1.svg";
import Income_Step_2 from "../assets/images/income-step-2.svg";
import Income_Step_3 from "../assets/images/income-step-3.svg";
import Income_Step_4 from "../assets/images/income-step-4.svg";
import Income_Step_5 from "../assets/images/income-step-5.svg";
import Income_Step_6 from "../assets/images/income-step-6.svg";
import Income_Step_7 from "../assets/images/income-step-7.svg";
import Income_Step_8 from "../assets/images/income-step-8.svg";

import Service_Img_1 from "../assets/images/services-img1.png";
import Service_Img_2 from "../assets/images/services-img-2.png";
import Service_Img_3 from "../assets/images/services-img-3.png";
import Service_Img_4 from "../assets/images/services-img-4.png";

import Testimonial_bg from "../assets/images/testimonial-bg.png";
import Testimonial_1 from "../assets/images/testimonial1.svg";
import Testimonial_2 from "../assets/images/testimonial2.svg";
import Testimonial_3 from "../assets/images/testimonial3.svg";
import Contact_Help from "../assets/images/contactUs-help-img.png";

import About_Sec_Img_1 from "../assets/images/about-sec-img-1.png";
import About_Sec_Img_2 from "../assets/images/about-sec-img-2.png";
import Quotes from "../assets/images/qoutes.svg";
import YellowQoutes from "../assets/images/yellowquotes.svg";
import whiteQuotes from "../assets/images/whiteQuetes.png";
import Green_Qoutes from "../assets/images/quote-up.svg";

import Arrow_Rotate from "../assets/images/arrow-2.svg";
import Detail_Board from "../assets/images/detail-board.svg";

import Client_1 from "../assets/images/client-1.svg";
import Client_2 from "../assets/images/client-2.svg";
import Client_3 from "../assets/images/client-3.png";
import Client_4 from "../assets/images/client-4.png";
import Client_5 from "../assets/images/client-5.png";
import Client_7 from "../assets/images/client-6.svg";
import Client_8 from "../assets/images/client-7.svg";
import Client_9 from "../assets/images/client-9.svg";
import Client_10 from "../assets/images/client-10.svg";
import Client_11 from "../assets/images/client-11.svg";
import Client_12 from "../assets/images/client-12.svg";
import Client_13 from "../assets/images/client-13.svg";

import Perfect_Match_1 from "../assets/images/perfect-match-1.png";
import Perfect_Match_2 from "../assets/images/prfect-match-2.png";
import Filter from "../assets/images/filter.svg";
import Email_Capture_8 from "../assets/images/email-capture-8.svg";
import Email_Capture_9 from "../assets/images/email-capture-9.svg";
import Email_Capture_10 from "../assets/images/email-capture-10.svg";
import Email_Capture_11 from "../assets/images/email-capture-11.svg";
import Email_Capture_12 from "../assets/images/email-capture-12.svg";
import Email_Capture_13 from "../assets/images/email-capture-13.svg";
import Email_Capture from "../assets/images/email-capture-2.svg";
import Email_Capture_3 from "../assets/images/email-capture-3.png";
import Email_Capture_5 from "../assets/images/email-capture-5.svg";
import Email_Capture_6 from "../assets/images/email-capture-6.svg";
import Professional_Quality from "../assets/images/professional.svg";
import Property_Details_3 from "../assets/images/property-detail-3.jpg";
import Yellow_Star from "../assets/images/yellow-star.svg";
import Appointment_Bg from "../assets/images/landing-form-bg.png";
import Appointment_home from "../assets/images/Home.png";
import Flexible from "../assets/images/flexible.svg";

import Rating_3 from "../assets/images/3-rating.png";
import Rating_4 from "../assets/images/4-rating.png";
import Rating_5 from "../assets/images/5-rating.png";

import Interior_Design_1 from "../assets/images/interior-design-1.png";
import Interior_Design_2 from "../assets/images/interior-design-2.png";
import Interior_Design_3 from "../assets/images/interior-design-3.png";
import Interior_Design_4 from "../assets/images/interior-design-4.png";
import Interior_Design_5 from "../assets/images/interior-design-5.png";
import Interior_Design_6 from "../assets/images/interior-design-6.png";
import Design_vector_green from "../assets/images/design-vector.svg";
import Design_vector_yellow from "../assets/images/yellow-vector.svg"
import Time_Saving from "../assets/images/time-saving.svg";

import Property_Desc_1 from "../assets/images/property-desc-1.png";
import Property_Desc_2 from "../assets/images/property-dec-2.png";
import Payement_Details from "../assets/images/payment-details.png";
import Client_6 from "../assets/images/client-page-5.png";
import Landing_Page_Bg from "../assets/images/home-bg.png";

import Personalized_Setting from "../assets/images/personalized-setting.svg";
import Maintenance_Home from "../assets/images/maintenance-home.svg";

import Checklist_1 from "../assets/images/checklist-1.svg"
import Checklist_2 from "../assets/images/checklist-2.svg"
import Checklist_3 from "../assets/images/checklist-3.svg"

import Inside_1 from "../assets/images/inside-img-1.png"
import Inside_2 from "../assets/images/inside-img-2.png"
import Inside_3 from "../assets/images/inside-img-3.png"
import Rental_Market from "../assets/images/rental-market.png"
import Landing_image_9 from "../assets/images/home-9.png"
import Landing_image_10 from "../assets/images/home-10.png"

import Dollar_Timer from "../assets/images/dollar-timer.svg"
import Idea from "../assets/images/idea.svg"
import Double_Qoutes_Green from "../assets/images/double-qoutes-green.svg"
import Vector_Star from "../assets/images/vector-star.png"
import Lease_Generation_1 from "../assets/images/lease-generation-1.png"
import Lease_Generation_2 from "../assets/images/lease-generation-2.png"
import Lease_Generation_3 from "../assets/images/lease-generation-3.png"
import Lease_Generation_4 from "../assets/images/lease-generation-4.png"

import Advocate from "../assets/images/advocate.svg"
import Reduce_Risk from "../assets/images/reduce-risk.svg"
import Screening_Guide from "../assets/images/screening-guide.png"
import Right_Caret from "../assets/images/right-caret.svg"

import Reduce_Turnover from "../assets/images/reduce-turnover.svg"
import Satisfaction from "../assets/images/satisfaction.svg"
import UI_designer_1 from "../assets/images/Ui-Designer-1.svg"
import UI_designer_2 from "../assets/images/Ui-Designer-2.svg"
import star_4 from "../assets/images/star_4.svg"
import Thumbs_up_green from "../assets/images/thumbs-up-green.svg"
import Thumbs_up from "../assets/images/thumbs-up.svg"
import Informed_Decision from "../assets/images/informed-decision.svg"
import feedback_userImage from "../assets/images/slide-13-user.png"
import Maximise_Returns from "../assets/images/maximize-returns.svg"

import Preview_Image_1 from "../assets/images/preview-img-1.png"
import Preview_Image_2 from "../assets/images/preview-img-2.png"
import Preview_Image_3 from "../assets/images/preview-img-3.png"
import Preview_Image_4 from "../assets/images/preview-img-4.png"
import Preview_Image_5 from "../assets/images/preview-img-5.png"
import Preview_Image_6 from "../assets/images/preview-img-6.png"
import Lease_Renewals from "../assets/images/lease-renewals.png"
import Expert_Insights_2 from "../assets/images/expert-insights-2.svg"
import Profit from "../assets/images/Profits.svg"
import Client_13_1 from "../assets/images/client-13-1.svg"
import Client_13_2 from "../assets/images/client-13-2.svg"
import Client_13_3 from "../assets/images/client-13-3.svg"

export const imagePath = {
  Lease_Renewals,
  Profit,
  Client_13_2,
  Client_13_1,
  Client_13_3,
  Expert_Insights_2,
  Preview_Image_1,
  Preview_Image_2,
  Preview_Image_3,
  Preview_Image_4,
  Preview_Image_5,
  Preview_Image_6,
  Thumbs_up_green,
  Right_Caret,
  Maximise_Returns,
  feedback_userImage,
  star_4,
  Thumbs_up,
  Informed_Decision,
  UI_designer_2,
  UI_designer_1,
  Email_Capture_8,
  Email_Capture_9,
  Email_Capture_10,
  Email_Capture_11,
  Email_Capture_12,
  Email_Capture_13,
  Advocate,
  Reduce_Turnover,
  Satisfaction,
  Reduce_Risk,
  Screening_Guide,
  Lease_Generation_1,
  Lease_Generation_2,
  Lease_Generation_3,
  Lease_Generation_4,
  Vector_Star,
  Double_Qoutes_Green,
  Dollar_Timer,
  Idea,
  Checklist_1,
  Checklist_2,
  Checklist_3,
  Inside_1,
  Inside_2,
  Inside_3,
  Rental_Market,
  Landing_image_9,
  Landing_image_10,
  Property_Desc_1,
  Property_Desc_2,
  Payement_Details,
  Client_6,
  Bar_Chart_1,
  Bar_Chart_2,
  Expertise,
  Tenant_Retention,
  Design_vector_yellow,
  Landing_Page_Bg,
  Interior_Design_1,
  Interior_Design_2,
  Interior_Design_5,
  Interior_Design_6,
  Design_vector_green,
  Property_Details_4,
  whiteQuotes,
  Maintenance_Home,
  Personalized_Setting,
  Time_Saving,
  Property_Details_6,
  Rating_3,
  Rating_4,
  Rating_5,
  Client_3,
  Client_7,
  Client_8,
  Client_9,
  Client_10,
  Client_11,
  Client_12,
  Client_13,
  Perfect_Match_2,
  Perfect_Match_1,
  Hollow_Location_2,
  Client_4,
  Client_5,
  Star_Network,
  White_Qoutes,
  Logo,
  Call,
  Yellow_Star,
  Flexible,
  Green_Qoutes,
  Quotes,
  Arrow_Rotate,
  YellowQoutes,
  Signup,
  Login,
  Home,
  Star,
  Robot,
  Wifi,
  Chart1,
  Chart2,
  Accuracy,
  Detail_Board,
  Appointment_Bg,
  Appointment_home,
  Client_1,
  Client_2,
  Filter,
  Email_Capture_3,
  Email_Capture_5,
  Email_Capture_6,
  Professional_Quality,
  Property_Details_3,
  G_fb,
  G_insta,
  G_twitter,
  G_Linkedin,
  Landing_Image,
  Social_bg,
  Green_Location,
  Green_Call,
  Green_Email,
  Property_Details,
  Expert_Analysis,
  Proven_Strategies,
  Report,
  Accountability,
  Details_List,
  Hollow_Green_Location,
  Hollow_Green_Size,
  Hollow_Green_Time,
  Hollow_Green_Calendar,
  Hollow_Green_Home,
  Hollow_Green_Bed,

  Interior_Design_4,
  Interior_Design_3,
  Green_fb,
  Green_insta,
  Green_twitter,
  Green_Linkedin,
  Testimonial_bg,
  Email,
  Socket,
  Heart,
  Search2,
  Light,
  Minus,
  Wrong,
  PhoneCall,
  Mail,
  Plus,
  Search,
  People,
  Facebook,
  Instagram,
  Calender,
  Twitter,
  Linkedin,
  Graph1,
  Graph2,
  Email_Capture,
  Feedback_img,
  Correct,
  Estimation,
  Market,
  Dollar,
  Timer,
  Property_Details_2,
  Rocket,
  Innovation,
  New_Features,
  Rental_Management,
  Regular_Maintanence,
  Financial_Planning,
  Location,
  Left_Caret,
  Blog_Img_1,
  Blog_Img_2,
  Blog_Img_3,
  Contact_Help,
  TeamMate1,
  TeamMate2,
  TeamMate3,
  TeamMate4,
  TeamMate5,
  TeamMate6,
  TeamMate7,
  TeamMate8,
  Pricing_Page_Img_1,
  Pricing_Page_Img_2,
  Pricing_Page_Img_3,
  Tenant_Management,
  About_Sec_Img_1,
  About_Sec_Img_2,
  Testimonial_1,
  Testimonial_2,
  Testimonial_3,
  Airbnb,
  Headphone,
  Sustainability,
  Double_Qoutes,
  Settings,
  main_Settings,
  caseStudies_Img_1,
  caseStudies_Img_2,
  caseStudies_Img_3,
  caseStudies_Img_4,
  caseStudies_Img_5,
  caseStudies_Img_6,
  Hero_Img_1,
  Hero_Img_2,
  Hero_Img_3,
  Hero_Img_4,
  Hero_Img_5,
  Hero_Img_6,
  Hero_Img_7,
  Industy_Img,
  Income_Step_1,
  Income_Step_2,
  Income_Step_3,
  Income_Step_4,
  Income_Step_5,
  Income_Step_6,
  Income_Step_7,
  Income_Step_8,
  Service_Img_1,
  Service_Img_2,
  Service_Img_3,
  Service_Img_4,
};
