import React from 'react';

import styles from './PrivacyPolicy.module.css';

import PolicySection from './PolicySection';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';


const termsCondition = [
    {
      title: "What is covered by this Privacy Policy?",
      content: "All interactions with our customers and visitors, including property rentals, rental management, and marketing of rental listings, your use of Angies Helpers website or applications, social media, or communications relating to any services offered by Angies Helpers (\"Services\") are governed by this Privacy Policy. This Privacy Policy does not apply to any products, services, websites, or content that are offered by third parties (\"Third Party Services\", \"service providers\") or to third-party websites on which Angies Helpers advertises, which are governed by their respective privacy policies. We may amend this Privacy Policy from time to time. We will provide notice of any material changes made to our Privacy Policy by prominently posting the revised Policy with an updated date of revision on our homepage. We encourage users to check this page periodically for any changes. If we make any material changes that affect information we have previously collected about you, we will provide you with notice via email or within the Service."
    },
    {
      title: "What Personal Information Do We Collect?",
      content: (
  <>
  <p>As part of our website and services, you may be asked to provide certain information about you, and we may collect information automatically through your usage. We collect personal information that you provide or input on the site, including:</p>
        <ul>
          <li>Name, date of birth, and contact information, including your phone number and email address</li>
          <li>Name and contact information of other guests or renters in your party</li>
          <li>Proof of identity documents, such as Driver's License, Passport, and Tax identification documents</li>
          <li>Login details, including email address, username, and password</li>
          <li>Your age and the ages of the other guests in your party</li>
          <li>Information about your automobile, including your license plate number</li>
          <li>Social media account information and profile image, including Facebook or Google</li>
          <li>Information sent in correspondence with us</li>
          <li>Payment information, including your address and account information</li>
          <li>Demographic information</li>
          <li>Pictures, video, and other user-uploaded or submitted content</li>
          <li>Information about any entity you own or manage</li>
          <li>Location data, if the user chooses to share that information</li>
          <li>Information required to collect or remit lodging or sales taxes on your behalf, apply for or renew a government license or permit relating to the rental management service provided to you</li>
          <li>Other information as required by law</li>
        </ul>
        <p>Additionally, we may collect information automatically about users via a variety of methods, such as cookies, web beacons, JavaScript, and log files. This information may include user IP addresses, browser types, domain names, device type, time stamp, referring URL, and other log file information; user activities within the Service; aggregate and statistical information regarding overall server/visitor traffic and navigation patterns for the Service. Web servers collect this type of basic information automatically as part of Web log processes. <br />
        Finally, we may collect certain information about you from third parties. These third parties collect and process your information under their own privacy policy and outside of our control. Angies Helpers advises all of its users to understand the privacy policy of any third party to which they are providing their personal information. For example, if you use a third party to book a stay in a property serviced by Angies Helpers, that third party may send us your personal information related to your stay. To the extent allowed by law, we may obtain police or background check information or potential fraud alerts from third-party providers of identity verification, criminal background check, or money laundering prevention services</p>
  </>
      )
    },
    {
      title: "Consent to Monitoring and Disclosure",
      content: "Angies Helpers is under no obligation to monitor the information residing or transmitted to this site. However, anyone accessing this site agrees that Angies Helpers may monitor the site to (1) comply with any necessary laws, regulations or governmental requests; (2) to, in its sole discretion, operate the Site in a manner it deems proper or to protect against conduct it deems inappropriate. Angies Helpers shall have the right but not the obligation, to reject or eliminate any information residing on or transmitted to the Site that it, in its sole discretion, believes is unacceptable or inconsistent with these terms and conditions. In the event Angies Helpers receives information or materials through the Site, including but not limited to feedback, data, answers, questions, comments, suggestions, plans, ideas or the like, such information or material shall be deemed to be non-confidential and Angies Helpers assumes no obligation to protect same from disclosure. The submission of information or materials to Angies Helpers shall in no way prevent the purchase, manufacture or use of similar products, services, plans and ideas by Angies Helpers for any purpose whatever. In addition, you grant Angies Helpers the right, in any form now or hereafter known, to reproduce, use, disclose, distribute, transmit, create derivative works from, and publicly display and perform, any information or materials you submit to through any Site"
    },
    {
      title: "How Do We Use Your Personal Information?",
      content: (
       <>
       <p>We may use the personal data you provide to Angies Helpers for the following purposes:</p>
        <ul>
          <li>To set up and maintain your registration with Angies Helpers</li>
          <li>To communicate with you</li>
          <li>To notify you regarding a stay that you have booked and to provide related offers</li>
          <li>To fulfill the terms of a contract between You and Us</li>
          <li>To tailor our content and information that we may send or display to you, to offer personalized help and instructions, and to otherwise personalize your experience with Angies Helpers</li>
          <li>To provide a more personalized user experience when using Angies Helpers</li>
          <li>To provide our Services to you and our Clients</li>
          <li>To develop, improve, and protect Angies Helpers</li>
          <li>To respond to your inquiries or requests</li>
          <li>To communicate with you about reservations and stays</li>
          <li>To provide customer service</li>
          <li>To provide rental management or similar services</li>
          <li>To make or receive payments to or from you</li>
          <li>To respond to comments, suggestions, and reviews</li>
          <li>For direct marketing and market research, including for example reading recommendations and targeted marketing, in accordance with applicable law</li>
          <li>To use user content as part of our Service as well as advertising and marketing campaigns to promote the services</li>
          <li>To find locations near to you and provide directions to locations of interest to you, including the location of a booking you made</li>
          <li>To customize, improve, and optimize your user experience and our Services</li>
          <li>To comply with applicable legal obligations, including responding to a subpoena or court order</li>
          <li>To enforce our terms, conditions, and policies</li>
          <li>To prevent, detect, and investigate fraud, illegal acts, and other misuses</li>
          <li>To protect our rights and/or our property</li>
        </ul>
       </>
      )
    },
    {
      title: "Does the Company use Cookies?",
      content: 
      <>
     <p>Cookies are small pieces of data, stored in text files, that are stored on your computer or other device when websites are loaded in a browser. They are widely used to “remember” you and your preferences, either for a single visit (through a “session cookie”) or for multiple repeat visits (using a “persistent cookie”). They ensure a consistent and efficient experience for visitors, and perform essential functions such as allowing users to register and remain logged in. Cookies may be set by the site that you are visiting (known as “first party cookies”), or by third parties, such as those who serve content or provide advertising or analytics services on the website (“third party cookies”). Both websites and HTML emails may also contain other tracking technologies such as “web beacons” or “pixels.” These are typically small transparent images that provide us with statistics, for similar purposes as cookies. They are often used in conjunction with cookies, though they are not stored on your computer in the same way. As a result, if you disable cookies, web beacons may still load, but their functionality will be restricted</p>
     <br />
     <p>Essential Cookies. <br /> These are cookies are placed in your browser by Angies Helpers and are also known as “strictly necessary” cookies. These are necessary to allow you to move around the Angies Helpers site and use its features, such as “Save and View Favorites."
     Analytics Cookies.</p>
     <p>Also known as “performance cookies,” analytics cookies collect information about visits to our sites and how the services we offer are being used. We use this data to make improvements and report our performance. For example, these cookies collect information about how visitors use our sites, which site the visitor came from, the number of each user’s visits, and how long a user stays on the site. We might also use analytics cookies to test new ads, pages, or features to see how users react to them. Analytics cookies are primarily third-party cookies.</p>
      </>
    },
    {
      title: "What Additional Rights Do California Users Have",
      content: (
        <>
          <p>Right to Know</p>
          <p>You have the right to know and see what data we have collected about you over the past twelve (12) months, including:</p>
          <ul>
            <li>The categories of personal information we have collected about you</li>
            <li>The categories of sources from which the personal information is collected</li>
            <li>The business or commercial purpose for collecting your personal information</li>
            <li>The categories of third parties with whom we have shared your personal information</li>
            <li>The specific pieces of personal information we have collected about you</li>
          </ul>
          <p>Right to Delete</p>
          <p>You have the right to request that we delete the personal information we have collected from you (and direct our service providers to do the same). There are a number of exceptions, however, that include, but are not limited to, when the information is necessary for us or a third party to do any of the following:</p>
          <ul>
            <li>Provide you with a good or service, or otherwise perform a contract between us and you</li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity</li>
            <li>Fix our system in the case of a bug</li>
            <li>Protect the free speech rights of you or other users</li>
            <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et seq.)</li>
            <li>Comply with a legal obligation</li>
            <li>Make other internal and lawful uses of the information that are compatible with the context in which you provided it</li>
          </ul>
          <p>Other Rights</p>
          <p>You have the right to request and obtain from us once a year, free of charge, information about the personal information (if any) we disclose to third parties for their own direct marketing purposes in the preceding calendar year. If applicable, this information would include a list of the categories of personal information that was shared and the names and addresses of all third parties with which we shared information in the immediately preceding calendar year. You also have the right not to be discriminated against for exercising any of the rights listed above.</p>
        </>
      )
    },
    {

      title: "How can you contact us if you have questions about this Privacy Policy?",
  
      content: 
      <>
      <p>If you have any questions regarding this Privacy Policy, you may contact us using the information below: </p>
      <p> <strong>By Email: angieshelpers@gmail.com</strong> </p>
      </>
  
    }

  ];


function Terms_Condition() {

  return (

    <>
    <Navbar mainNavbar/>
    <div className="contact-heading-section">
        <div className="container">
          <h1 className='fontSize55_black'>Terms And Conditions</h1>
        </div>
      </div>
   <div className="container">
  
   <main className={styles.container}>

<h1 className={styles.title}>Privacy Policy</h1>

<p className={styles.paragraph}>

  Angies Helpers is dedicated to ensuring the accuracy, confidentiality, and security of your personal data. This Privacy Policy outlines how your personal information is collected, used, and shared when you sign up for an account, use any of our services, our mobile application, or visit our website, estaga.com.

</p>

<p className={styles.subParagraph}>

  By visiting our website or using any of our services or mobile application, you agree to this Privacy Policy. By doing so, you accept the practices described in this policy and consent to receive it electronically via publication on the web. This agreement is between you ("you," "user," "your") and Estaga, including its subsidiaries and related entities ("the service," "us," "we," "our," "company").

</p>

<p className={styles.subParagraph}>

  This Privacy Policy is a part of our Terms and Conditions, which govern your use of our site, application, and services. Please read this policy carefully to understand our practices regarding your personal data and how we handle it. By using our website or services, you acknowledge that you have read, understood, and consented to this Privacy Policy.

</p>

{termsCondition.map((section, index) => (

  <PolicySection key={index} title={section.title} content={section.content} />

))}

</main>
   </div>
    <Footer/>
    </>

  );

}


export default Terms_Condition;


