import React from "react";

const FAQItem = ({ question, answer, id }) => {
  return (
    <div className="accordion" id="accordionFAQ">
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading-${id}`}>
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-${id}`}
            aria-expanded="true"
            aria-controls={`collapse-${id}`}
          >
            {question}
          </button>
        </h2>
        <div
          id={`collapse-${id}`}
          className="accordion-collapse collapse"
          aria-labelledby={`heading-${id}`}
          data-bs-parent="#accordionFAQ"
        >
          <div className="accordion-body">{answer}</div>
        </div>
      </div>
    </div>
  );
};

export default FAQItem;
