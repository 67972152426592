import React, { useState } from "react";

import styles from "./SignUpPage.module.css";
import Navbar from "../../components/Navbar";
import { imagePath } from "../../constants/imagePath";
import { Link } from "react-router-dom";

const SignUpPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  return (
    <>
      <main className={styles.container}>
        <div className="container">
          <div className={styles.content}>
            <section className={styles.formColumn}>
              <div className={styles.formWrapper}>
                <h1 className={styles.title}>Welcome to Angies Helpers</h1>

                <p className={styles.subtitle}>
                  Your trusted partner in property management for Airbnb.
                </p>

                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className={styles.inputLabel}>
                      Email Address
                    </label>

                    <div className={styles.emailInputWrapper}>
                      <input
                        type="email"
                        id="Email"
                        className={styles.inputField}
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className={styles.inputLabel}>
                      Create Password
                    </label>

                    <div className={styles.passwordInputWrapper}>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className={styles.passwordInput}
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />

                      <button
                        type="button"
                        className={styles.passwordToggle}
                        onClick={togglePasswordVisibility}
                      >
                        <i
                          className={
                            showPassword
                              ? "fa-regular fa-eye"
                              : "fa-regular fa-eye-slash" 
                          }
                        ></i>
                      </button>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="confirmPassword"
                      className={styles.inputLabel}
                    >
                      Confirm Password
                    </label>

                    <div className={styles.passwordInputWrapper}>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        className={styles.passwordInput}
                        placeholder="Enter your password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />

                      <button
                        type="button"
                        className={styles.passwordToggle}
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <i
                          className={
                            showConfirmPassword
                            
                            ? "fa-regular fa-eye"
                              : "fa-regular fa-eye-slash"
                          }
                        ></i>
                      </button>
                    </div>
                  </div>

                  <div className={styles.formOptions}>
                    <label className={styles.rememberMe}>
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        className={styles.checkbox}
                      />

                      <span className={styles.rememberMeText}>Remember Me</span>
                    </label>

                    <a href="#" className={styles.forgotPassword}>
                      Forgot password?
                    </a>
                  </div>

                  <Link to="/">
                    <button type="submit" className={styles.signUpButton}>
                      Sign Up
                    </button>
                  </Link>
                </form>

                <div className={styles.divider}>
                  <div className={styles.dividerLine} />

                  <span className={styles.dividerText}>Or Login with</span>

                  <div className={styles.dividerLine} />
                </div>

                <button className={styles.googleSignIn}>
                  <div className={styles.googleSignInContent}>
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/98fe0b3dc50f319f1792d8369d52e26ae39af9bd2cf0b6886673ae7014e9e912?apiKey=bc29958e556a4912bfcf05761f7c7e15&"
                      alt="Google logo"
                      className={styles.googleIcon}
                    />

                    <span className={styles.googleSignInText}>
                      Sign In with Google
                    </span>
                  </div>
                </button>

                <p className={styles.loginLink}>
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    style={{
                      fontWeight: "600",
                      textDecoration: "underline",
                      color: "#1aae67",
                    }}
                  >
                    Login
                  </Link>
                </p>
              </div>
            </section>

            <section className={styles.imageColumn}>
              <img
                src={imagePath.Signup}
                alt="Property management illustration"
                className={styles.heroImage}
              />
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default SignUpPage;
