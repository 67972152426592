import React from "react";
import Heading_Section from "./Heading_Section";

const Included_Checklist = ({
  listContent,

}) => {
  return (
    <>
          <div className="checklist-list-section">
            <ul className="list-group">
              <li className="list-group-item mb-2" id="checklist-list">
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  value=""
                  id="firstCheckbox"
                />
                <label className="form-check-label " for="firstCheckbox" id="list">
                {listContent}
                </label>
              </li>

            </ul>
       
     
   
          </div>
    </>
  );
};

export default Included_Checklist;
