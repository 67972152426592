import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Landing_Footer from "../../components/Landing_Footer";
import Heading_Section from "../../components/Heading_Section";
import InputWithSearch from "../../components/InputWithSearch";
import { imagePath } from "../../constants/imagePath";
import Image_Text_Comp from "../../components/Image_Text_Comp";
import Card_With_Heading from "../../components/Card_With_Heading";
import Cards from "../../components/Cards";
import CardReactFormContainer from "card-react";
import Testimonial from "../../components/Testimonial";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LandingPage_5 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768, // screens smaller than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480, // screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="container">
          <div className="landing-page-five-section">
            <Image_Text_Comp
              white={false}
              image1={imagePath.Interior_Design_1}
              image2={imagePath.Interior_Design_2}
              padding="40px 0px"
              vector_green
              vectorImage={imagePath.Design_vector_green}
              heading_Black="Need a Property Description? Let Our AI Write It for You!"
              subcontent="Enter your property details and get a professional description instantly"
            />

            <InputWithSearch
              width="60%"
              btnText="Get An Start"
              Placeholder="Enter Your Address"
              BackgroundColor="#EEEFEF"
            />
          </div>
        </div>
        <div className="gray-background">
          <div className="container">
            <Card_With_Heading
              heading_black="How "
              heading_black2="Work ?"
              heading_green="Angies Helpers"
              subContent="Providing expert insights and updates on the latest trends in home stay and property management"
            />

            <div className="landing-grid-container" id="landing-page-2-grid">
              <Cards
                image={imagePath.Details_List}
                cardTitle="Enter Property Details"
                cardDescription="We handle listing optimization, pricing, guest communication, check-ins/outs, cleaning, and maintenance to maximize occupancy and income."
              />

              <Cards
                image={imagePath.Robot}
                cardTitle="Generate Description"
                cardDescription="We provide tenant screening, lease management, rent collection, maintenance, and tenant relations to ensure satisfaction and upkeep."
              />

              <Cards
                image={imagePath.Wifi}
                cardTitle="Post to MLS"
                cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
              />
            </div>
          </div>
        </div>

        <div className="choose-your-appointment-section">
          <div className="container">
            <div className="choose-your-appointment">
              <Heading_Section
                white={false}
                heading_Black="Property"
                heading_Green="Details"
                subcontent="At AngiesHelpers.com, our journey began with a simple mission: to provide property owners with a hassle-free, profitable rental management experience"
              />

              <div className="property-detail-section" id="landing-page-5-form">
                <div className="container">
                  <div className="property-detail-form">
                    <div
                      className="property-form-section"
                      id="property-section"
                    >
                      <div id="property-details-form">
                        <div className="property-form-5">
                          <p className="fontSize18_black">
                            Enter Your Property Details Below
                          </p>
                          <form>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                              />
                            </div>
                            <select
                              class="form-select mb-2"
                              id="property-selection"
                              aria-label="Default select example"
                            >
                              <option selected>Property Type</option>
                              <option value="Apartment">
                                <img src={imagePath.Left_Caret} alt="" />{" "}
                                Apartment
                              </option>
                              <option value="House">
                                <img src={imagePath.Left_Caret} alt="" /> House
                              </option>
                              <option value="Condo">
                                <img src={imagePath.Left_Caret} alt="" /> Condo
                              </option>
                            </select>

                            <select
                              class="form-select mb-2"
                              aria-label="Default select example"
                            >
                              <option selected>Number Of Bedrooms</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>

                            <select
                              class="form-select mb-2"
                              aria-label="Default select example"
                            >
                              <option selected>Number Of Bathrooms</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Square Footage"
                              />
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input "
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                Pool
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Garage
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Furnished
                              </label>
                            </div>

                            <button className="btn-style" id="form-button">
                              Get Estimate
                            </button>
                          </form>
                        </div>
                      </div>
                      <div className="property-image">
                        <img src={imagePath.Property_Details_3} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="property-description-section">
          <div className="container">
            <Heading_Section
              left
              heading_Black="Your Property"
              heading_Green=" Description"
              subcontent="Your property description is being generated. This may take a few minutes."
            />
            <div className="property-description">
              <div className="property-one">
                <img src={imagePath.Property_Desc_1} />
                <div>
                  <h1 className="fontSize20_black">
                    Vibrant 2BR in the heart of the city
                  </h1>
                  <p className="fontSize16_gray">
                    $150/night, 4.8(200) · Superhost
                  </p>
                </div>
              </div>
              <div className="property-one">
                <img src={imagePath.Property_Desc_2} />
                <div>
                  <h1 className="fontSize20_black">
                    Vibrant 2BR in the heart of the city
                  </h1>
                  <p className="fontSize16_gray">
                    $150/night, 4.8(200) · Superhost
                  </p>
                </div>
              </div>
            </div>

            <div className="why-choose-btn">
              <button className="btn-style">Get Full Description</button>
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card" id="enter-email-card-5">
              <h3 className="fontSize20_white">
                Enter Your Email to Receive Your Full Description and Posting
                Service Details
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="property-description-section">
          <div className="container">
            <div className="testimonials" id="page3-slider">
              <h1 className="fontSize48_black" id="heading">
                Payment <span className="fontSize48_green">Details</span>{" "}
              </h1>
              <p className="fontSize16_gray">Details of Payment</p>
            </div>
            <div className="property-description">
              <div className="payment-card">
                <div className="d-flex flex-column aligen-items-center text-center">
                  <h1 className="fontSize20_black">
                    Pay $49.99 to post on the MLS
                  </h1>
                  <p className="fontSize13_bluishGray">
                    You're about to pay $49.99 to post your listing on the MLS
                    and hundreds of other sites. This is a one-time fee that is
                    non-refundable.
                  </p>
                </div>

                <CardReactFormContainer
                  container="card-wrapper"
                  formInputsNames={{
                    number: "CCnumber",
                    expiry: "CCexpiry",
                    cvc: "CCcvc",
                    name: "CCname",
                  }}
                  classes={{
                    valid: "valid-input",
                    invalid: "invalid-input",
                  }}
                  formatting={true}
                >
                  <form className="payment-card-form">
                    <input
                      placeholder="Card number"
                      type="text"
                      name="CCnumber"
                      className="card-number"
                    />
                    <div className="card-expiry">
                      <input
                        placeholder="MM/YY"
                        type="text"
                        name="CCexpiry"
                        className="expiry-date"
                      />
                      <input
                        placeholder="CVC"
                        type="text"
                        name="CCcvc"
                        className="CVC_number"
                      />
                    </div>
                    <button className="btn-style" id="form-button">
                      $Pay 49.99
                    </button>
                  </form>
                </CardReactFormContainer>
              </div>
              <div className="payment-image">
                <img src={imagePath.Payement_Details} />
              </div>
            </div>
          </div>
        </div>

        <div className="clients-feedback-section">
          <div className="container">
            <div className="feedback-section-page5">
              <Heading_Section
                heading_Black="What Our"
                heading_Green="Client Says"
                subcontent="Discover how our services have transformed property management.Hear firsthand from property owners about their experiences and success stories"
              />

              <div className="container">
                <div className="client-feedback-slider" id="client-say-section">
                  <Slider {...settings}>
                    <div className="client-feedback-card">
                      <div className="client-image">
                        <img src={imagePath.Client_6} />
                      </div>
                      <div className="client-feedback">
                        <div className="left-img">
                          <img src={imagePath.White_Qoutes} />
                        </div>
                        <div>
                          <img src={imagePath.Rating_3} />
                          <p
                            className="fontSize24_white_regular"
                            id="feedback-text"
                          >
                            Lorem ipsum dolor sit amet consectetur. Consequat
                            auctor consectetur nunc vitae dolor blandit. Elit
                            enim massa etiam neque laoreet lorem sed.
                          </p>
                          <div className="user-name d-flex flex-column gap-1">
                            <b>
                              {" "}
                              <p className="fontSize16_white">
                                Anthony Bahringer
                              </p>
                            </b>
                            <p className="fontSize12_Green">
                              Senior Research Manager
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="client-feedback-card">
                      <div className="client-image">
                        <img src={imagePath.Client_6} />
                      </div>
                      <div className="client-feedback">
                        <div className="left-img">
                          <img src={imagePath.White_Qoutes} />
                        </div>
                        <div>
                          <img src={imagePath.Rating_3} />
                          <p
                            className="fontSize24_white_regular"
                            id="feedback-text"
                          >
                            Lorem ipsum dolor sit amet consectetur. Consequat
                            auctor consectetur nunc vitae dolor blandit. Elit
                            enim massa etiam neque laoreet lorem sed.
                          </p>
                          <div className="user-name d-flex flex-column gap-1">
                            <b>
                              {" "}
                              <p className="fontSize16_white">
                                Anthony Bahringer
                              </p>
                            </b>
                            <p className="fontSize12_Green">
                              Senior Research Manager
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="client-feedback-card">
                      <div className="client-image">
                        <img src={imagePath.Client_6} />
                      </div>
                      <div className="client-feedback">
                        <div className="left-img">
                          <img src={imagePath.White_Qoutes} />
                        </div>
                        <div>
                          <img src={imagePath.Rating_3} />
                          <p
                            className="fontSize24_white_regular"
                            id="feedback-text"
                          >
                            Lorem ipsum dolor sit amet consectetur. Consequat
                            auctor consectetur nunc vitae dolor blandit. Elit
                            enim massa etiam neque laoreet lorem sed.
                          </p>
                          <div className="user-name d-flex flex-column gap-1">
                            <b>
                              {" "}
                              <p className="fontSize16_white">
                                Anthony Bahringer
                              </p>
                            </b>
                            <p className="fontSize12_Green">
                              Senior Research Manager
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="client-feedback-card">
                      <div className="client-image">
                        <img src={imagePath.Client_6} />
                      </div>
                      <div className="client-feedback">
                        <div className="left-img">
                          <img src={imagePath.White_Qoutes} />
                        </div>
                        <div>
                          <img src={imagePath.Rating_3} />
                          <p
                            className="fontSize24_white_regular"
                            id="feedback-text"
                          >
                            Lorem ipsum dolor sit amet consectetur. Consequat
                            auctor consectetur nunc vitae dolor blandit. Elit
                            enim massa etiam neque laoreet lorem sed.
                          </p>
                          <div className="user-name d-flex flex-column gap-1">
                            <b>
                              {" "}
                              <p className="fontSize16_white">
                                Anthony Bahringer
                              </p>
                            </b>
                            <p className="fontSize12_Green">
                              Senior Research Manager
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section" id="why-choose-us">
          <div className="container">
            <div className="core-values-main ">
              <Heading_Section
                heading_Black="Why Choose Our"
                heading_Green=" Description Writer? "
                subcontent=" Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings."
              />

              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Professional_Quality}
                  cardTitle="Professional Quality"
                  cardDescription="Professional quality means delivering exceptional work that meets high standards, ensuring reliability, expertise, and client satisfaction."
                />

                <Cards
                  image={imagePath.Time_Saving}
                  cardTitle="Time-Saving"
                  cardDescription="Time-saving techniques streamline tasks, enhance productivity, reduce stress, and free up time for meaningful activities"
                />

                <Cards
                  image={imagePath.Star_Network}
                  cardTitle="Wide Reach"
                  cardDescription="Wide reach allows ideas and messages to impact diverse audiences, fostering broader connections and understanding."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <Landing_Footer />
    </>
  );
};

export default LandingPage_5;
