import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { imagePath } from '../constants/imagePath'
import HeroSection from '../components/HeroSection'
import RightSection_Comp from '../components/RightSection_Comp'
import LeftSection_Comp from '../components/LeftSection_Comp'
import wrongImg from '../assets/images/wrong.svg'
import correctImg from '../assets/images/correct.svg' 

const Pricing = () => {
  return (
  <>
  <Navbar mainNavbar/>
  <div className="container-fluid">
        <div className="about-main-section">
          <div className="container">
            <HeroSection
              mainHeading="Transparent Pricing, No Surprises"
              paragraph="At AngiesHelpers.com, we believe in transparent and competitive pricing. Our service packages are designed to provide maximum value for your investment. Explore our range of affordable plans and choose the one that best fits your property management needs."
              btnText="Get Started Now"
              image={imagePath.Hero_Img_5}
            />
          </div>
        </div>
        <div className="pricing-container">
        <div className="why-choose-angies-heading" id='pricing-heading'>
                  <h1 className="fontSize48_black" id="heading">
                  Affordable Property{"  "}
                    <span className="fontSize48_green">Management</span>{" "}
                  </h1>
                  <p className="fontSize22_gray ">
                  Explore our competitive, transparent pricing options tailored to your property management needs. Choose from a range of affordable plans to maximize value.
                  </p>
                </div>
    </div>
  <div className="table-section">
  <div className="container">
          <div className="table-responsive pricing-plan-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" >
                    <div style={{width:'250px'}}>
                      <h2>Our Service Packages</h2>
                    </div>
                  </th>
                  <th scope="col" >
                    <div style={{width:'250px'}}>
                      <h4>Basic</h4>
                      <p>The Basic Package provides essential management services.</p>
                        <button className='btn-style'>Get Started Now<i className="fa-solid fa-angle-right"></i></button>
                    </div>
                  </th>

                  <th scope="col" >
                    <div style={{width:'250px'}}>
                      <h4>Standard</h4>
                      <p>ideal for property owners who want to maximize their income.</p>
                        <button className='btn-style'>Get Started Now<i className="fa-solid fa-angle-right"></i></button>
                    </div>
                  </th>
                  <th scope="col" >
                    <div style={{width:'250px'}}>
                      <h4>Premium</h4>
                      <p>Ideal for hands-off property owners seeking top-tier service.</p>
                        <button className='btn-style'>Get Started Now<i className="fa-solid fa-angle-right"></i></button>
                    </div></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Price</th>
                  <td>$30/month</td>
                  <td>$50/month</td>
                  <td>$80/month</td>
                </tr>
                <tr>
                  <th scope="row">Listing Optimization</th>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
                <tr>
                  <th scope="row">Guest Communication</th>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
                <tr>
                  <th scope="row">Professional photos</th>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
                <tr>
                  <th scope="row">booking management</th>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
                <tr>
                  <th scope="row">Dynamic Pricing</th>
                  <td><img src={wrongImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
                <tr>
                  <th scope="row">property maintenance coordination</th>
                  <td><img src={wrongImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
                <tr>
                  <th scope="row">legal and compliance support</th>
                  <td><img src={wrongImg} alt="svgIcon" /></td>
                  <td><img src={wrongImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
                <tr>
                  <th scope="row">renovation oversight</th>
                  <td><img src={wrongImg} alt="svgIcon" /></td>
                  <td><img src={wrongImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
                <tr>
                  <th scope="row">personalized marketing strategies</th>
                  <td><img src={wrongImg} alt="svgIcon" /></td>
                  <td><img src={wrongImg} alt="svgIcon" /></td>
                  <td><img src={correctImg} alt="svgIcon" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  </div>
    <div className="container">
    <RightSection_Comp
              mainHeading="Get a Custom"
              subHeading="Quote"
              paragraph="For property owners with unique needs or larger portfolios, we offer custom quotes. Contact us to discuss your specific requirements, and we will tailor a pricing plan that fits your budget and management needs."
              btnText="Get Custom Quote Now"
              image={imagePath.Pricing_Page_Img_1}
              visibleButton
            />

<LeftSection_Comp
            mainHeading="Additional "
            paragraph="While our packages cover most services, additional fees may apply for specific needs like extensive renovations, legal disputes, or emergency repairs."
            subHeading="Fees"
            image={imagePath.Pricing_Page_Img_2}
            btnText="Want To know more"
            visibleButton
          />

<RightSection_Comp
              mainHeading="Payment "
              subHeading="Terms"
              paragraph="We offer flexible payment terms to make our services accessible and affordable. Payments can be made monthly, quarterly, or annually, depending on your preference."
              btnText="Get Started Now"
              image={imagePath.Pricing_Page_Img_3}
              visibleButton
            />
    </div>
        </div>
  <Footer/>
  </>
  )
}

export default Pricing