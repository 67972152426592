import React from "react";
import { imagePath } from "../constants/imagePath";

const Testimonial = ({
  userName,
  place,
  feedback,
  image,
  paraId,
  id,
  yellowQuotes,
  backgrundColor,
  paraColor,
  paraFont,
  userNameColor,userNameFont,placeNameColor,placeNameFont,
  quetesImage,
  greenQoutes,
  maxWidth,
  maxWidthQuote,
  qoutesImg,
  starImg,
  ratings,
  greenImage,
  greenUserName,
  greenMaxWidth,
  greenId,
  greenBackgroundColor,

}) => {
  return (
    <>
      { 
      yellowQuotes ? (
        <div className="testimonials-card" id="landing-testimonials-card">
          <img src={quetesImage}  alt="" />
          <p className="fontSize22_white" id={paraId}>
            {feedback}
          </p>
          <div className="testimonial-user">
            <img src={image} alt="" />
            <div className="user-name">
              <b>
                {" "}
                <h3 className="fontSize22_white_regular" id={id}>
                  {userName}
                </h3>
              </b>
              <h5 className="fontSize18_Green">{place}</h5>
            </div>
          </div>
        </div>
      )   
      : greenQoutes? (
        
        <div className="testimonials-card" id="landing-testimonials-card" style={{ backgroundColor: greenBackgroundColor }}>
          <img src={qoutesImg} id="quotes"/>
          <p className="d-flex align-items-center gap-2">
            <span><img src={starImg} id="star-img"/></span>
            <span className="fontSize18_black">{ratings}</span>
          </p>
          <p className="fontSize18_Gray_2">{feedback}</p>
          <div className="testimonial-user">
            <img src={greenImage} style={{ maxWidth: greenMaxWidth }} alt="" />
            <div className="user-name d-flex flex-column gap-1">
              <b>
                <h3 className="fontSize20_black" id={greenId} >
                  {greenUserName}
                </h3>
              </b>
            </div>
          </div>
        </div>
      ) 
      : (
        <div className="testimonials-card" id="landing-testimonials-card" style={{backgroundColor:backgrundColor}}>
          
          <p className="fontSize22_white d-flex gap-1 mb-2" id={paraId} style={{color:paraColor,fontSize:paraFont}}>
         <sup> <img src={quetesImage} style={{maxWidth:maxWidthQuote,height:'unset'}} alt="" /></sup> {feedback}
          </p>
          <div className="testimonial-user">
            <img src={image} style={{maxWidth:maxWidth}} alt="" />
            <div className="user-name d-flex flex-column gap-1">
              <b>
                {" "}
                <h3 className="fontSize22_white_regular m-0" id={id} style={{color: userNameColor,fontSize:userNameFont}}>
                  {userName}
                </h3>
              </b>
              <h5 className="fontSize18_Green m-0" style={{color: placeNameColor,fontSize:placeNameFont}}>{place}</h5>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Testimonial;
