import React, { useState } from "react";

import styles from "./LoginPage.module.css";
import Navbar from "../../components/Navbar";
import { imagePath } from "../../constants/imagePath";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <main className={styles.loginContainer}>
        <div className="container">
          <div className={styles.contentWrapper}>
            <section className={styles.formColumn}>
              <div className={styles.formContent}>
                <h1 className={styles.welcomeTitle}>Welcome Back !</h1>

                <p className={styles.welcomeSubtitle}>
                  Your Ultimate Property Management Solution for Airbnb Hosts
                </p>

                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className={styles.inputLabel}>
                      Email Address
                    </label>

                    <div className={styles.emailInputWrapper}>
                      <input
                        type="email"
                        id="Email"
                        className={styles.inputField}
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className={styles.inputLabel}>
                      Password
                    </label>

                    <div className={styles.passwordInputWrapper}>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className={styles.passwordInput}
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <button
                        type="button"
                        className={styles.passwordToggle}
                        onClick={togglePasswordVisibility}
                      >
                        <i
                          className={
                            showPassword
                            ? "fa-regular fa-eye"
                              : "fa-regular fa-eye-slash"
                          }
                        ></i>
                      </button>
                    </div>
                  </div>

                  <div className={styles.loginOptions}>
                    <div className={styles.rememberMe}>
                      <input
                        type="checkbox"
                        id="rememberMe"
                        className={styles.rememberMeCheckbox}
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                      />

                      <label
                        htmlFor="rememberMe"
                        className={styles.rememberMeLabel}
                      >
                        Remember Me
                      </label>
                    </div>

                    <a href="#" className={styles.forgotPassword}>
                      Forgot password?
                    </a>
                  </div>

                  <Link to="/">
                    {" "}
                    <button type="submit" className={styles.loginButton}>
                      Login
                    </button>
                  </Link>
                </form>

                <div className={styles.orLoginWith}>
                  <div className={styles.divider} />

                  <span className={styles.orLoginWithText}>Or Login with</span>

                  <div className={styles.divider} />
                </div>

                <button className={styles.googleSignIn}>
                  <div className={styles.googleSignInContent}>
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca9ce25754cd9f7d1992d9b2a68542f06baeaf9e7edf45f209a8ba35af1f3065?apiKey=bc29958e556a4912bfcf05761f7c7e15&"
                      alt="Google logo"
                      className={styles.googleLogo}
                    />

                    <span className={styles.googleSignInText}>
                      Sign In with Google
                    </span>
                  </div>
                </button>

                <p className={styles.signUpPrompt}>
                  Don't have an account ?{" "}
                  <Link
                    to="/signup"
                    style={{
                      fontWeight: "600",
                      textDecoration: "underline",
                      color: "#1aae67",
                    }}
                  >
                    Sign Up Now
                  </Link>
                </p>
              </div>
            </section>

            <section className={styles.imageColumn}>
              <img
                src={imagePath.Login}
                alt="Property management illustration"
                className={styles.heroImage}
              />
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginPage;

// import React, { useState } from 'react';
// import styles from './LoginPage.module.css';
// import Navbar from '../../components/Navbar';
// import { imagePath } from '../../constants/imagePath';
// import { Link } from 'react-router-dom';

// const LoginPage = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [rememberMe, setRememberMe] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };

//   return (
//     <>
//       <main className={styles.loginContainer}>
//         <div className="container">
//           <div className={styles.contentWrapper}>
//             <section className={styles.formColumn}>
//               <div className={styles.formContent}>
//                 <h1 className={styles.welcomeTitle}>Welcome Back !</h1>
//                 <p className={styles.welcomeSubtitle}>
//                   Your Ultimate Property Management Solution for Airbnb Hosts
//                 </p>
//                 <form onSubmit={handleSubmit}>
//                   <div>
//                     <label htmlFor="email" className={styles.inputLabel}>
//                       Email Address
//                     </label>
//                     <div className={styles.emailInputWrapper}>
//                       <input
//                         type="email"
//                         id="email"
//                         className={styles.inputField}
//                         placeholder="Enter your email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                       />
//                     </div>
//                   </div>
//                   <div>
//                     <label htmlFor="password" className={styles.inputLabel}>
//                       Password
//                     </label>
//                     <div className={styles.passwordInputWrapper}>
//                       <input
//                         type={showPassword ? 'text' : 'password'}
//                         id="password"
//                         className={styles.passwordInput}
//                         placeholder="Enter your password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                       />
//                       <button
//                         type="button"
//                         className={styles.passwordToggle}
//                         onClick={togglePasswordVisibility}
//                       >
//                         <i className={showPassword ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'}></i>
//                       </button>
//                     </div>
//                   </div>
//                   <div className={styles.loginOptions}>
//                     <div className={styles.rememberMe}>
//                       <input
//                         type="checkbox"
//                         id="rememberMe"
//                         className={styles.rememberMeCheckbox}
//                         checked={rememberMe}
//                         onChange={() => setRememberMe(!rememberMe)}
//                       />
//                       <label htmlFor="rememberMe" className={styles.rememberMeLabel}>
//                         Remember Me
//                       </label>
//                     </div>
//                     <a href="#" className={styles.forgotPassword}>
//                       Forgot password?
//                     </a>
//                   </div>
//                   <Link to="/">
//                     <button type="submit" className={styles.loginButton}>
//                       Login
//                     </button>
//                   </Link>
//                 </form>
//                 <div className={styles.orLoginWith}>
//                   <div className={styles.divider} />
//                   <span className={styles.orLoginWithText}>Or Login with</span>
//                   <div className={styles.divider} />
//                 </div>
//                 <button className={styles.googleSignIn}>
//                   <div className={styles.googleSignInContent}>
//                     <img
//                       src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca9ce25754cd9f7d1992d9b2a68542f06baeaf9e7edf45f209a8ba35af1f3065?apiKey=bc29958e556a4912bfcf05761f7c7e15&"
//                       alt="Google logo"
//                       className={styles.googleLogo}
//                     />
//                     <span className={styles.googleSignInText}>Sign In with Google</span>
//                   </div>
//                 </button>
//                 <p className={styles.signUpPrompt}>
//                   Don't have an account ?{" "}
//                   <Link to="/signup" style={{ fontWeight: '600', textDecoration: 'underline', color: '#1aae67' }}>
//                     Sign Up Now
//                   </Link>
//                 </p>
//               </div>
//             </section>
//             <section className={styles.imageColumn}>
//               <img
//                 src={imagePath.Login}
//                 alt="Property management illustration"
//                 className={styles.heroImage}
//               />
//             </section>
//           </div>
//         </div>
//       </main>
//     </>
//   );
// };

// export default LoginPage;
