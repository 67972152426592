import React from "react";
import Navbar from "../../components/Navbar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Landing_Footer from "../../components/Landing_Footer";
import { imagePath } from "../../constants/imagePath";
import Cards from "../../components/Cards";
import Testimonial from "../../components/Testimonial";
import Heading_Section from "../../components/Heading_Section";
import Detail_Card from "../../components/Detail_Card";
import Date_Input from "../../components/Date_Input";

const LandingPage_4 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768, // screens smaller than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480, // screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-four-section">
          <div className="container">
            <div className="landing-page-3">
              <h1 className="fontSize67_white ">Rental Market Analysis</h1>
              <p className="fontSize22_white">
                Experts provide accurate rental value estimates.
              </p>
            </div>
          </div>
        </div>

        <div className="comprehensive-rental-section">
          <div className="container">
            <div className="comprehensive-rental">
              <h1 className="fontSize48_black" id="whyChoose-heading">
                Comprehensive Rental <br />
                <span className="fontSize48_green"> Market Analysis</span>{" "}
              </h1>
              <p className="fontSize20_gray">
                Whether you're local or remote, our experts provide a thorough
                rental market analysis of your property. Choose an in-person
                visit or a virtual consultation to receive an accurate estimate
                of your property's rental value. Our comprehensive service
                includes a detailed examination of market trends, comparable
                property rates, and unique property features to ensure you get
                the most precise and insightful evaluation possible. Whether you
                prefer the convenience of a virtual meeting or the personal
                touch of an in-person assessment, we are committed to delivering
                top-notch service tailored to your needs.
              </p>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Schedule Your Estimate</button>
            </div>
          </div>
        </div>

        <div className="choose-your-appointment-section">
          <div className="container">
            <div className="choose-your-appointment">
              <h1 className="fontSize48_black" id="landing-heading">
                Choose Your Appointment{" "}
                <span className="fontSize48_green"> Type and Time</span>{" "}
              </h1>

              <div
                className="contact-section row"
                id="appointment-form-section"
              >
                <div className="contact-form col-md-8" id="property-details">
                  <div className="property-form" id="appointment-form">
                    <p className="fontSize18_black">Appoint Details</p>
                    <div className="d-flex justify-content-between">
                      <div className="radio-btn">
                        <label for="radio-1" className="radio-label">
                          Virtual
                        </label>
                        <input id="radio-1" name="radio" type="radio" />
                      </div>

                      <div className="radio-btn">
                        <label for="radio-1" className="radio-label">
                          In - Person
                        </label>
                        <input id="radio-1" name="radio" type="radio" />
                      </div>
                    </div>
                    <form>
                      <div>
                        <div class="form-floating">
                          <textarea
                            class="form-control"
                            placeholder="Additional Clauses"
                            id="floatingTextarea4"
                            rows="4"
                          ></textarea>
                          <label for="floatingTextarea2">
                            Additional Clauses
                          </label>
                        </div>
                        <div className="date-input">
                          <div className="mb-4 start-date">
                           <Date_Input placeholder="Select Date"/>
                          </div>
                          <select
                            class="form-select end-date mb-4"
                            id="property-selection"
                            aria-label="Default select example"
                          >
                            <option selected>Preferred Time</option>
                            <option value="Apartment">
                              <img src={imagePath.Left_Caret} alt="" /> 10 AM
                            </option>
                            <option value="House">
                              <img src={imagePath.Left_Caret} alt="" /> 2 PM
                            </option>
                            <option value="Condo">
                              <img src={imagePath.Left_Caret} alt="" /> 5 PM
                            </option>
                          </select>
                        </div>

                        <div className="date-input">
                          <select
                            class="form-select mb-4"
                            id="property-selection"
                            aria-label="Default select example"
                          >
                            <option selected>Property Type</option>
                            <option value="Apartment">
                              <img src={imagePath.Left_Caret} alt="" />{" "}
                              Apartment
                            </option>
                            <option value="House">
                              <img src={imagePath.Left_Caret} alt="" /> House
                            </option>
                            <option value="Condo">
                              <img src={imagePath.Left_Caret} alt="" /> Condo
                            </option>
                          </select>
                          <select
                            class="form-select mb-4"
                            id="property-selection"
                            aria-label="Default select example"
                          >
                            <option selected>Number of Bedrooms</option>
                            <option value="Apartment">
                              <img src={imagePath.Left_Caret} alt="" /> one
                            </option>
                            <option value="House">
                              <img src={imagePath.Left_Caret} alt="" /> two
                            </option>
                            <option value="Condo">
                              <img src={imagePath.Left_Caret} alt="" /> three
                            </option>
                          </select>
                        </div>
                        <select
                          class="form-select mb-4"
                          aria-label="Default select example"
                        >
                          <option selected>Number Of Bathrooms</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <button className="btn-style" id="form-button">
                        Confirm Details
                      </button>
                    </form>
                  </div>
                </div>
                <div id="appointment-img" className="col-md-4">
                  <img className="w-100" src={imagePath.Appointment_home} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="appointment-confirmation-section">
          <div className="container">
            <Heading_Section
              left
              heading_Black="Appointment Details"
              heading_Green="Confirmation"
              subcontent="Your Lease Agreement"
            />
            <Detail_Card
              image={imagePath.Hollow_Green_Location}
              Property_Detail="Property Address"
              Property_Description="1234 Main St, San Francisco, CA 94110"
              Button_Name="Change"
            />

            <Detail_Card
              image={imagePath.Hollow_Green_Size}
              Property_Detail="Property Size"
              Property_Description="1,000 sqft"
              Button_Name="Change"
            />

            <Detail_Card
              image={imagePath.Hollow_Green_Calendar}
              Property_Detail="Preferred Appointment Date"
              Property_Description="June 16th, 2023"
              Button_Name="Change"
            />

            <Detail_Card
              image={imagePath.Hollow_Green_Home}
              Property_Detail="Property Type"
              Property_Description="Single Family Home"
              Button_Name="Change"
            />

            <Detail_Card
              image={imagePath.Hollow_Green_Bed}
              Property_Detail="Property Bedrooms/Bathrooms"
              Property_Description="3/2"
              Button_Name="Change"
            />

            <Detail_Card
              image={imagePath.Hollow_Green_Time}
              Property_Detail="Preferred Appointment Time"
              Property_Description="Morning"
              Button_Name="Change"
            />
            <div className="why-choose-btn">
              <button className="btn-style">Confirm Appointment</button>
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card" id="enter-email-card-4">
              <h3 className="fontSize20_white">
                Enter Your Email to Receive Your Full Analysis Report
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-testimonials-main-section">
          <div className="container">
            <div className="client-testimonials" id="client-testimonial-page4">
              <Heading_Section
                left
                heading_Black="What Our"
                heading_Green="Clients Say "
                subcontent=" Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories"
              />
              <div
                className="Client-appointment-slider"
                id="client-say-section"
              >
                <Slider {...settings}>
                  <Cards
                    slide
                    slideImg={imagePath.Client_3}
                    useName="James Pattinson"
                    ratingImg={imagePath.Rating_4}
                    feedback="“Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.”"
                  />
                  <Cards
                    slide
                    slideImg={imagePath.Client_4}
                    useName="Greg Stuart"
                    ratingImg={imagePath.Rating_5}
                    feedback="“Vestibulum, cum nam non amet consectetur morbi aenean condimentum eget. Ultricies integer nunc neque accumsan laoreet. Viverra nibh ultrices.”"
                  />
                  <Cards
                    slide
                    slideImg={imagePath.Client_5}
                    useName="Trevor Mitchell"
                    ratingImg={imagePath.Rating_3}
                    feedback="“Ut tristique viverra sed porttitor senectus. A facilisis metus pretium ut habitant lorem. Velit vel bibendum eget aliquet sem nec, id sed. Tincidunt.”"
                  />
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section">
          <div className="container">
            <div className="core-values-main ">
              <Heading_Section
                heading_Black="Why Choose Us for"
                heading_Green="Your Rental Estimate? "
                subcontent=" Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings."
              />

              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Estimation}
                  cardTitle="Expert Analysis"
                  cardDescription="Utilize our advanced AI technology to compare rental incomes with unparalleled precision, ensuring you make informed and profitable decisions."
                />

                <Cards
                  image={imagePath.Flexible}
                  cardTitle="Flexible Options"
                  cardDescription="Enhance your rental approach with our expertly crafted strategies, specifically designed to help you optimize your property and achieve maximum profit"
                />

                <Cards
                  image={imagePath.Report}
                  cardTitle="Comprehensive Reports"
                  cardDescription="Access detailed and in-depth reports that provide actionable insights, helping you understand your rental market better and make smarter investment choices."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <Landing_Footer />
    </>
  );
};

export default LandingPage_4;
