import React, { useState } from 'react'

const LeftSection_Comp = ({
    mainHeading,
    paragraph,
    btnText,
    image,
    subParagraph,
    subHeading,
    visibleButton
}) => {

   
  return (
   <>
    <div className="hero-main-section">
  <div className="hero-section-part1">
  <h1 className='fontSize48_black'>{mainHeading} <span className='fontSize48_green'>{subHeading}</span> </h1>
    <p className='fontSize18_Gray'>{paragraph}</p>
    <p className='fontSize18_Gray'>{subParagraph}</p>
   {
    visibleButton ?  <button className='btn-style'>{btnText}<i className="fa-solid fa-angle-right"></i></button> :  null
   }
  </div>
  <div className="hero-section-part-2">
    <img src={image} alt=''/>
  </div>
  </div>
   </>
  )
}

export default LeftSection_Comp