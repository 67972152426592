import React from 'react'
import { imagePath } from '../constants/imagePath'

const Blog_Card_Vertical = (
    {
        topic,
        image,
        information,
        visibleButton,
        btnText,
        detail,
        date,
        calendar,
        btn_info,
        info,
        left
    }
) => {
  return (
   <>
   {
    left ?
    
    <div className="blog-card-horizontal" id='vertical-card' >
    <div >
    <img src={image} className="horizontal-img" alt="" />
    </div>
   <div className='blog-content-card'>
   <div className="date-card">
    {detail ? <button className="detail-btn">{btn_info}</button> : ""}
     {calendar ? (
         <span className="fontSize16_DarkGray " id="calendar">
           <img src={imagePath.Calender} /> {date}
         </span>
       ) : (
         ""
       )}
    </div>
     <div className="blog-subcard">
    
       <h5 className="fontSize24_black_Tiempos">
         {topic}
       </h5>
 
   
       {info ? (
         <p className="fontSize18_Gray">
           {information}
         </p>
       ) : (
         ""
       )}
        
   
       {visibleButton ? (
         <button className="btn-style" style={{ width: "fit-content" }}>
           {btnText}
           <i className="fa-solid fa-angle-right"></i>
         </button>
       ) :  ""}
   
   
     </div>
   </div>
   </div>
   : 


   <div className="blog-card-horizontal-right">
   <div>
   <img src={image} className="horizontal-img" alt="" />
   </div>
  <div className='blog-content-card'>
  <div className="date-card">
   {detail ? <button className="detail-btn">{btn_info}</button> : ""}
    {calendar ? (
        <span className="fontSize16_DarkGray " id="calendar">
          <img src={imagePath.Calender} /> {date}
        </span>
      ) : (
        ""
      )}
   </div>
    <div className="blog-subcard">
   
      <h5 className="fontSize24_black_Tiempos">
        {topic}
      </h5>
  
     
  
  
      {info ? (
        <p className="fontSize18_Gray">
          {information}
        </p>
      ) : (
        ""
      )}
  
  
      {visibleButton ? (
        <button className="btn-style" style={{ width: "fit-content" }}>
          {btnText}
          <i className="fa-solid fa-angle-right"></i>
        </button>
      ) :  <button className="btn-style" style={{ width: "fit-content" }}>
      {btnText}
      <i className="fa-solid fa-angle-right"></i>
    </button>}
  
  
    </div>
  </div>
 
  </div>
   }
   </>
  )
}

export default Blog_Card_Vertical