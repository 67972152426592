import React from "react";
import { imagePath } from "../constants/imagePath";

const InputWithSearch = ({ BackgroundColor, btnText, Placeholder, width }) => {
  return (
    <>
      <div
        className="search-input-section"
        style={{ backgroundColor: BackgroundColor , width : width }}
      >
        <div className="d-flex gap-2" id="common-input">
          <img src={imagePath.Search2} />
          <input type="text" placeholder={Placeholder} />
        </div>
        <button className="btn-style">{btnText}</button>
      </div>
    </>
  );
};

export default InputWithSearch;
