import React from "react";
import Cards from "../components/Cards";

const Card_With_Heading = ({
  heading_black,
  heading_green,
  subContent,
  heading_black2,
  heading_white,
  white,
  heading_white2
}) => {
  return (
    <>
      <div className="why-choose-angies-main">
      <div
                className="why-choose-angies-heading"
                id="landing-page-heading"
              >
              {
                white ?
                <h1 className="fontSize48_white" id="whyChoose-heading">
 
                {heading_white}<span className="fontSize48_green">{heading_green }</span>{" "}
                                <span className="fontSize48_white">{heading_white2}</span>{" "}
                              </h1>
                              :
                              <h1 className="fontSize48_black" id="whyChoose-heading">
 
                              {heading_black}<span className="fontSize48_green">{heading_green }</span>{" "}
                                              <span className="fontSize48_black">{heading_black2}</span>{" "}
                                            </h1>
              }
                <p className="fontSize22_gray" id="why-choose-para">
                 {subContent}
                </p>
              </div>
      </div>
    </>
  );
};

export default Card_With_Heading;
