import React from "react";

import FAQSection from "./FAQSection";

import styles from "./FAQComponent.module.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const FAQComponent = () => {
  const faqData = {
    about: [
      {
        question: "What is Angies Helpers.com?",
        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 1,
      },

      {
        question:
          "What vacation rental management services does Angies Helpers offer?",

        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 2,
      },

      {
        question: "How do I get started with AngiesHelpers.com?",
        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 3,
      },
    ],

    serviceSpecific: [
      {
        question: "What does your Airbnb management service include?",
        id: 4,
      },

      {
        question: "How do you handle maintenance requests?",

        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 5,
      },
    ],

    pricingAndFees: [
      {
        question: "How much do your property management services cost?",

        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 6,
      },

      {
        question: "Are there any additional fees I should be aware of?",
        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 7,
      },

      {
        question: "How do I pay rent?",
        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 8,
      },

      {
        question: "What are the service charges at Angies Helpers?",

        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 9,
      },

      {
        question: "Can I get a breakdown of fees associated with each booking?",
        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",

        id: 10,
      },

      {
        question: "How does Angies Helpers handle refunds?",
        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 11,
      },

      {
        question:
          "Is there a fee for guest support or assistance during their stay?",

        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 12,
      },
    ],

    supportAndCommunication: [
      {
        question: "How do you keep me informed about my property's status?",
        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 13,
      },

      {
        question: "What if I have a question or need support?",
        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 14,
      },

      {
        question: "How does Angies Helpers handle urgent support issues?",

        answer:
          "Boost your rental income with Angies Helpers. We handle listings, pricing, guest communication, and maintenance details for you. Maximize your rental income with Angies Helpers. We specialize in comprehensive management services tailored to your needs. From optimizing listings and strategic pricing to seamless guest communication and proactive maintenance, we handle every detail. Experience the benefits of stress-free property management with Angies Helpers by your side.",
        id: 15,
      },
    ],
  };

  return (
    <>
      <Navbar mainNavbar/>
      <div className="contact-heading-section">
        <div className="container">
          <h1 className="fontSize55_black">FAQ</h1>
        </div>
      </div>
      <div className="Faq-main-section">
      <div className="container">
        <header className={styles.header}>Frequently asked questions</header>
        <section className={styles.container}>
          <FAQSection
            title="About"
            subTitle="Angies Helpers"
            faqs={faqData.about}
          />

          <FAQSection
            title="FAQs for Service-Specific"
            faqs={faqData.serviceSpecific}
          />

          <FAQSection
            title="FAQs for Pricing and Fees"
            faqs={faqData.pricingAndFees}
          />

          <FAQSection
            title="Support and Communication"
            faqs={faqData.supportAndCommunication}
          />
        </section>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQComponent;
