import React from "react";

import styles from "./PrivacyPolicy.module.css";

import PolicySection from "./PolicySection";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const policySections = [
  {
    title: "Information We Collect",

    content: (
      <>
        <p>
          Certainly! Here's the updated text with "Angies Helpers" substituted
          in: "We collect information that identifies, relates to, describes,
          references, is reasonably capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular
          consumer, household, or device (“personal information”). Personal
          information does not include: <br />
          Publicly available information from government records; Deidentified
          or aggregated consumer information; Information excluded from the
          scope of the California Consumer Privacy Act of 2018 (“CCPA”), like:
          health or medical information covered by the Health Insurance
          Portability and Accountability Act of 1996 (HIPAA) and the California
          Confidentiality of Medical Information Act (CMIA) or clinical trial
          data; and personal information covered by certain sector-specific
          privacy laws, including the Fair Credit Reporting Act (FCRA), the
          Gramm-Leach-Bliley Act (GLBA) or California Financial Information
          Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994
        </p>
        <br />
        <p>
          A. Identifiers Common examples: A real name, alias, postal address,
          unique personal identifier, online identifier, Internet Protocol
          address, email address, account name, social security number, and
          other similar identifiers.
          </p>
          <p>
            B. Personal information categories listed in the California Customer
            Records statute (Cal. Civ. Code § 1798.80(e)) A name, signature,
            social security number, physical characteristics or description,
            address, and telephone number. Some personal information included in
            this category may overlap with other categories.
            </p>
            <p>
              C. Protected classification characteristics under California or
              federal law Age, race, color, ancestry, national origin,
              citizenship, religion or creed, marital status, medical condition,
              physical or mental disability, or sex (including gender, gender
              identity, gender expression, pregnancy or childbirth and related
              medical conditions).
              </p>
              <p>
                D. Commercial information Records of products or services
                purchased, obtained, or considered, or other purchasing or
                consuming histories or tendencies.
                </p>
                <p>
                  E. Internet or other similar network activity Browsing
                  history, search history, information on a consumer's
                  interaction with a website, application, or advertisement.</p>
                  <p>F. Geolocation data Physical location.</p>
                  <p>We collect this information:</p>
                  <p>Automatically as you navigate through Angie's Helpers, which information may include usage details, IP addresses, and other information collected through cookies, web beacons, and other tracking technologies; <br />
When you purchase, order, return, exchange, or request information about our products and services while using Angie's Helpers; <br />
When you participate in interactive features on Angie's Helpers; <br />
When you sign up for our e-mails, mobile messages, or social media notifications;<br />
When you enter a contest or sweepstakes, respond to one of our surveys, or participate in a focus group;<br />
When you provide us with comments, suggestions, or other input; and
Directly from you when you otherwise provide it to us.
The types of personal information we collect depend on your level of engagement with Angie's Helpers. The higher the level, the more personal information we require to provide our services.</p>

              <p>Personal Information We Collect Across All Sites <br />
From registered and unregistered users of Angie's Helpers, we automatically receive and record personal information whenever you interact with the site. This includes IP address data from our server logs, cookie information, the pages you request, and the URL of the site you visited before Angie's Helpers. If you use our mobile applications (“App Users”), we also collect the user resettable mobile device ID (such as the ID for Advertising on iOS and Android ID on Android) and your geo-location information so that we can interact with you to provide geo-specific information, such as properties for sale near you and information about listing your property for sale. Your IP address serves the same function if you are using our website. If you want to turn off our access to your geo-location, you can modify “location services” within your iOS or Android device settings.
Personal Information We Collect from Registered Angie's Helpers Users (including Registered App Users)
If you are registered for an account through Angie's Helpers, we are required to obtain your name and email address as a condition of our licenses with the multiple real estate listing services (the MLSs) in order to allow you to access the full MLS listings. You give us this information when you create your Angie's Helpers account. When you give us this information at one of our events, or upon request, we will create an Angie's Helpers account for you and this Privacy Policy and our Terms of Use apply. In addition, when you are signed in to your Angie's Helpers account and using Angie's Helpers through Facebook, Twitter, or any other third-party authorization service, we may make use of the data that they provide us to further customize your experience through the site.</p>  
<p>
Personal Information We Collect Through Tours and Evaluations
When you submit a property tour, evaluation, or offer request through Angie's Helpers, we will ask for your tour date and time preferences, as well as your phone number. We will also ask some questions so that our agent can verify your identity. When you meet up with one of our agents at the property, the agent will ask you to verify your identity, and may even ask for a photo ID. At this point, your relationship with us is not just online.</p>
<p>Personal Information We Collect From Our Clients <br />
When you are preparing to buy or sell a property and you start working with our coordinators and agents through Angie's Helpers, you become an Angie's Helpers Client. As part of the property purchase or sale process, you will need to provide personal information as is customary for a property purchase or sale transaction. This personal information can include, without limitation, your name, address, phone number, email address, buyer loan pre-approval documentation, and seller property information. We ask you, as a buyer, for loan pre-approvals from a mortgage broker or lender to determine which properties are in your price range, and to help you make an offer more quickly when you locate a property of interest. You, as a seller, are legally required to disclose certain information about your property to prospective buyers. Once you sign a Listing Agreement or Buyer Agreement with us, your relationship with us is governed by that agreement, as well as, to the extent applicable, our online Terms of Use and this Privacy Policy.</p>
</>
    ),
  },

  {
    title: "Consent to Monitoring and Disclosure",

    content:
      "Angies Helpers is under no obligation to monitor the information residing or transmitted to this site. However, anyone accessing this site agrees that Angies Helpers may monitor the site to (1) comply with any necessary laws, regulations or governmental requests; (2) to, in its sole discretion, operate the Site in a manner it deems proper or to protect against conduct it deems inappropriate. Angies Helpers shall have the right but not the obligation, to reject or eliminate any information residing on or transmitted to the Site that it, in its sole discretion, believes is unacceptable or inconsistent with these terms and conditions. In the event Angies Helpers receives information or materials through the Site, including but not limited to feedback, data, answers, questions, comments, suggestions, plans, ideas or the like, such information or material shall be deemed to be non-confidential and Angies Helpers assumes no obligation to protect same from disclosure. The submission of information or materials to Angies Helpers shall in no way prevent the purchase, manufacture or use of similar products, services, plans and ideas by Angies Helpers for any purpose whatever. In addition, you grant Angies Helpers the right, in any form now or hereafter known, to reproduce, use, disclose, distribute, transmit, create derivative works from, and publicly display and perform, any information or materials you submit to through any Site",
  },

  {
    title: " Privacy of Mobile Applications",

    content:
     <>
     <p>With respect to your use of the mobile application portion of our Sites (the “App”), the App may include functionality to allow you to engage in a wide variety of activities and services. In addition, the App may collect certain information automatically, such as the type of mobile device you use, the temporary or persistent unique device identifiers (sometimes called UDID) placed by us or our service providers, the unique identifier assigned by us to your device, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the App. <br />
     Additionally, the App may collect precise information about the location of your device. You must first provide permission through your device before our App obtains such location information from technologies like GPS, Wi-Fi, or cell tower proximity (or a combination of these technologies). We, and our service providers, may use and store this information, in combination with other location-based information such as the IP address and billing or postal code provided by you at the time of your App registration, to provide enhanced location-based services, such as store-specific services, search results, and other content. You can stop all collection of information by the App easily by uninstalling the App. Also, you may at any time opt-out from further allowing us to have access to your location data by adjusting the permissions in your mobile device.</p>
     </>
  },

  {
    title: "Our Use of Tracking Technology",

    content:
     <>
     
     <p>
     We put Angies Helpers cookies on your computer to help us keep track of when you were last on our Sites, and what you last searched on the Sites. While you are free to disable cookies on your browser, we recommend that you leave cookies activated to take advantage of some of the most useful Angies Helpers features. We use third parties such as Google, Segment, Amplitude, and other 3rd party sites to help us analyze how people are using the Sites, and those third parties are authorized to use cookies and other tracking technologies to access personal information of visitors to the Sites. We use the Google Analytics Advertising service to provide us with demographic data about our users on an anonymous basis, and that Google service uses a cookie that can be recognized by Google or its affiliate DoubleClick when you visit other websites. <br />
     As mentioned above, we may share the information that you provide to us, and the information that we collect about you, with others (including advertising service providers and networks) to serve you ads tailored to your interests or to tailor ads that may be served to you on our Sites, as well as other websites and on social media networks. You may also see ads for Angies Helpers on other websites and social media because we participate in advertising networks. Advertising networks allow Angies Helpers to target our advertising to you through demographic, behavioral and contextual means. These advertising networks track your online activities over time by collecting information through automated means, including through the use of cookies, web-beacons and other methods. Advertising networks use this information to show you advertisements that they believe are tailored to your interests. The information our advertising network vendors collect includes information about your visit to our Sites as well as pages you have viewed, and the actions you have taken on the Sites.
     </p>
     </>
  },

  {
    title:
      "Our Protection of Your Information",

    content:
     <>
     <p>We store the information we collect from you in the United States. If you are located outside the United States and choose to use Angies Helpers, we may store and process the information you provide to us in the United States. We take appropriate administrative, physical, and electronic measures designed to protect the information that we collect from or about you from unauthorized access, use, or disclosure. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will attempt to protect your personal information, transmission of personal information to and from Angies Helpers is at your own risk. You should only access Angies Helpers within a secure environment.</p>
     </>
  },

  {
    title:
      "Updates to This Policy",

    content:
      "We may update this privacy policy at any time and from time to time. The updated version will be indicated by an updated “Last updated” date below and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how Angies Helpers is protecting your information.",
  },
  {
    title:
      "Contacting Us",

    content:
     <>
     
     <p>If you have questions regarding this privacy policy or wish to obtain additional information, please send an e-mail to our Data Protection Officer at <strong>angieshelpers@gmail</strong> .com You can also write to us at: 560 Haight St. Apt 106, San Francisco, CA 94117  </p>
     </>
  },
];

function PrivacyPolicy() {
  return (
    <>
      <Navbar mainNavbar/>
      <div className="contact-heading-section">
        <div className="container">
          <h1 className="fontSize55_black">Privacy Policy</h1>
        </div>
      </div>
      <div className="container">
        <main className={styles.container}>
          <h1 className={styles.title}>Privacy Policy</h1>

          <p className={styles.paragraph}>
            Angies Helpers is dedicated to ensuring the accuracy,
            confidentiality, and security of your personal data. This Privacy
            Policy outlines how your personal information is collected, used,
            and shared when you sign up for an account, use any of our services,
            our mobile application, or visit our website, estaga.com.
          </p>

          <p className={styles.subParagraph}>
            By visiting our website or using any of our services or mobile
            application, you agree to this Privacy Policy. By doing so, you
            accept the practices described in this policy and consent to receive
            it electronically via publication on the web. This agreement is
            between you ("you," "user," "your") and Estaga, including its
            subsidiaries and related entities ("the service," "us," "we," "our,"
            "company").
          </p>

          <p className={styles.subParagraph}>
            This Privacy Policy is a part of our Terms and Conditions, which
            govern your use of our site, application, and services. Please read
            this policy carefully to understand our practices regarding your
            personal data and how we handle it. By using our website or
            services, you acknowledge that you have read, understood, and
            consented to this Privacy Policy.
          </p>

          {policySections.map((section, index) => (
            <PolicySection
              key={index}
              title={section.title}
              content={section.content}
            />
          ))}
        </main>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
