import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { imagePath } from '../constants/imagePath'
import HeroSection from '../components/HeroSection'
import LeftSection_Comp from '../components/LeftSection_Comp'
import RightSection_Comp from '../components/RightSection_Comp'
import Cards from '../components/Cards'

const Services = () => {
  return (
  <>
  <Navbar mainNavbar/>
  <div className="container-fluid">
        <div className="about-main-section">
          <div className="container">
          <HeroSection
              mainHeading="Expert Property Management Services"
              paragraph="AngiesHelpers.com offers comprehensive rental management, including pricing, guest communication, maintenance, and legal compliance. Our expert team enhances property value and guest satisfaction with tailored solutions."
              btnText="Get Started Now"
              image={imagePath.Hero_Img_3}
            />
            </div>
            </div>

            <div className="container">
          <RightSection_Comp
            mainHeading="Airbnb"
            subHeading="Management"
            paragraph="Our Airbnb Management services include everything you need to successfully manage your short-term rental property."
            subParagraph="We handle listing optimization, dynamic pricing, guest communication, and cleaning and maintenance. Our goal is to maximize your occupancy rates and rental income while ensuring your guests have an exceptional experience."
            image={imagePath.Service_Img_1}

          />

          <LeftSection_Comp
            mainHeading="Long-Term"
            subHeading="Rentals"
            paragraph="Our Long-Term Rental services provide full-spectrum support for leasing properties on a long-term basis. This includes tenant screening, lease management, rent collection, and ongoing property maintenance."
            subParagraph="We ensure your property is well-maintained and your tenants are satisfied, providing you with a stable and reliable income stream."
            image={imagePath.Service_Img_2}
          />

<RightSection_Comp
            mainHeading="On-Site"
            subHeading="Assistance"
            paragraph="Our Airbnb Management services cover everything needed for successful short-term rental management. Our local experts provide hands-on support for all property needs, including emergency maintenance, regular inspections, and guest interactions."
            subParagraph="With our on-site assistance, we ensure any issues are promptly addressed, maintaining your property's quality and appeal."
            image={imagePath.Service_Img_3}

          />

          <LeftSection_Comp
            mainHeading="24/7 Offshore "
            subHeading="Support"
            paragraph="Our offshore team offers continuous support and manages all administrative tasks, including guest inquiries, booking management, and customer service."
            subParagraph="This ensures that your property operations are supported around the clock, reducing downtime and enhancing efficiency. With 24/7 offshore support, you can rest assured that your property is always in good hands."
            image={imagePath.Service_Img_4}
          />
        </div>
        <div className="core-values-section">
            <div className="container">
              <div className="core-values-main ">
                <div className="why-choose-angies-heading">
                  <h1 className="fontSize48_white" id="heading">
                  Additional Services 
                    <span className="fontSize48_green">Offered</span>{" "}
                  </h1>
                  <p className="fontSize22_white_regular ">
                  Discover more services like interior design, renovations, and marketing to enhance your property's appeal and value. Customize your package for tailored solutions.
                  </p>
                </div>
                <div className="grid-container" id="services">
                  <Cards
                    image={imagePath.Home}
                    cardTitle="Additional Services"
                    cardDescription="Beyond standard property management, we offer interior design consultations, renovation oversight, and marketing strategies to enhance your property’s appeal and value."
                  />

                  <Cards
                    image={imagePath.Socket}
                    cardTitle="Technology Integration"
                    cardDescription="We use advanced software for streamlined operations, performance tracking, and transparent reporting, helping you stay informed and make data-driven decisions."
                  />

                  <Cards
                    image={imagePath.Star}
                    cardTitle="Customized Service Packages"
                    cardDescription="Customize your service package to fit your needs, choosing full-service management or select services. Pay only for what you need."
                  />

                </div>
              </div>
            </div>
          </div>

            </div>
  <Footer/>
  </>
  )
}

export default Services