import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../assets/images/testimonila_vdo1.svg";
import img2 from "../assets/images/testimonila_vdo2.svg";
import img3 from "../assets/images/testimonila_vdo3.svg";
import vdoPath from "../assets/videos/tech.mp4";
import { imagePath } from "../constants/imagePath";
import { Link } from "react-router-dom";

const Testimonial_video = ({
  image,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  id,
  rounded,
  leaseImage,
  greenText,
  blackText,
  visibleButton = false,
  btnText,
  orangeText,
  whiteText,
  leaseGeneration,
}) => {
  var imageSettings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="container mb-5" id={id}>
      {image ? (
        <Slider {...imageSettings}>
          <div className={leaseGeneration ? "lease-generation" : "p-3"}>
            {leaseImage ? (
              <img
                src={image1}
                className={
                  rounded ? "w-100 rounded-0 mb-2" : "w-100 rounded-4 mb-2"
                }
                id="lease-image"
              />
            ) : (
              <img src={image1} className="w-100 rounded-4 mb-2" />
            )}
            <div className="py-2 px-3">
              {greenText ? (
                <p className="fontSize26_green ">Lease Generation</p>
              ) : orangeText ? (
                <p className="fontSize26_orange">Lease Generation</p>
              ) : (
                <p className="fontSize17_black_bold">Lease Generation</p>
              )}

              {blackText ? (
                <p className="fontSize13_Black">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : whiteText ? (
                <p className="fontSize13_white">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : (
                <p className="fontSize13_LightGray">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              )}

              {visibleButton ? (
                <Link to="">
                  <div className="d-flex justify-content-between align-items-center my-4">
                    <p className="fontSize20_gray">{btnText}</p>
                    <button className="caret-button">
                      <img src={imagePath.Right_Caret} />
                    </button>
                  </div>
                </Link>
              ) : (
                " "
              )}
            </div>
          </div>

          <div className={leaseGeneration ? "lease-generation" : "p-3"}>
            {leaseImage ? (
              <img
                src={image2}
                className={
                  rounded ? "w-100 rounded-0 mb-2" : "w-100 rounded-4 mb-2"
                }
                id="lease-image"
              />
            ) : (
              <img src={image2} className="w-100 rounded-4 mb-2" />
            )}
            <div className="py-2 px-3">
              {greenText ? (
                <p className="fontSize26_green ">Lease Generation</p>
              ) : orangeText ? (
                <p className="fontSize26_orange">Lease Generation</p>
              ) : (
                <p className="fontSize17_black_bold">Lease Generation</p>
              )}

              {blackText ? (
                <p className="fontSize13_Black">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : whiteText ? (
                <p className="fontSize13_white">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : (
                <p className="fontSize13_LightGray">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              )}

              {visibleButton ? (
                <Link to="">
                  <div className="d-flex justify-content-between align-items-center my-4">
                    <p className="fontSize20_gray">{btnText}</p>
                    <button className="caret-button">
                      <img src={imagePath.Right_Caret} />
                    </button>
                  </div>
                </Link>
              ) : (
                " "
              )}
            </div>
          </div>

          <div className={leaseGeneration ? "lease-generation" : "p-3"}>
            {leaseImage ? (
              <img
                src={image3}
                className={
                  rounded ? "w-100 rounded-0 mb-2" : "w-100 rounded-4 mb-2"
                }
                id="lease-image"
              />
            ) : (
              <img src={image3} className="w-100 rounded-4 mb-2" />
            )}
            <div className="py-2 px-3">
              {greenText ? (
                <p className="fontSize26_green ">Lease Generation</p>
              ) : orangeText ? (
                <p className="fontSize26_orange">Lease Generation</p>
              ) : (
                <p className="fontSize17_black_bold">Lease Generation</p>
              )}

              {blackText ? (
                <p className="fontSize13_Black">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : whiteText ? (
                <p className="fontSize13_white">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : (
                <p className="fontSize13_LightGray">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              )}

              {visibleButton ? (
                <Link to="">
                  <div className="d-flex justify-content-between align-items-center my-4">
                    <p className="fontSize20_gray">{btnText}</p>
                    <button className="caret-button">
                      <img src={imagePath.Right_Caret} />
                    </button>
                  </div>
                </Link>
              ) : (
                " "
              )}
            </div>
          </div>

          <div className={leaseGeneration ? "lease-generation" : "p-3"}>
            {leaseImage ? (
              <img
                src={image4}
                className={
                  rounded ? "w-100 rounded-0 mb-2" : "w-100 rounded-4 mb-2"
                }
                id="lease-image"
              />
            ) : (
              <img src={image4} className="w-100 rounded-4 mb-2" />
            )}
            <div className="py-2 px-3">
              {greenText ? (
                <p className="fontSize26_green ">Lease Generation</p>
              ) : orangeText ? (
                <p className="fontSize26_orange">Lease Generation</p>
              ) : (
                <p className="fontSize17_black_bold">Lease Generation</p>
              )}

              {blackText ? (
                <p className="fontSize13_Black">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : whiteText ? (
                <p className="fontSize13_white">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : (
                <p className="fontSize13_LightGray">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              )}

              {visibleButton ? (
                <Link to="">
                  <div className="d-flex justify-content-between align-items-center my-4">
                    <p className="fontSize20_gray">{btnText}</p>
                    <button className="caret-button">
                      <img src={imagePath.Right_Caret} />
                    </button>
                  </div>
                </Link>
              ) : (
                " "
              )}
            </div>
          </div>

          <div className={leaseGeneration ? "lease-generation" : "p-3"}>
            {leaseImage ? (
              <img
                src={image5}
                className={
                  rounded ? "w-100 rounded-0 mb-2" : "w-100 rounded-4 mb-2"
                }
                id="lease-image"
              />
            ) : (
              <img src={image5} className="w-100 rounded-4 mb-2" />
            )}
            <div className="py-2 px-3">
              {greenText ? (
                <p className="fontSize26_green ">Lease Generation</p>
              ) : orangeText ? (
                <p className="fontSize26_orange">Lease Generation</p>
              ) : (
                <p className="fontSize17_black_bold">Lease Generation</p>
              )}

              {blackText ? (
                <p className="fontSize13_Black">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : whiteText ? (
                <p className="fontSize13_white">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : (
                <p className="fontSize13_LightGray">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              )}

              {visibleButton ? (
                <Link to="">
                  <div className="d-flex justify-content-between align-items-center my-4">
                    <p className="fontSize20_gray">{btnText}</p>
                    <button className="caret-button">
                      <img src={imagePath.Right_Caret} />
                    </button>
                  </div>
                </Link>
              ) : (
                " "
              )}
            </div>
          </div>
          <div className={leaseGeneration ? "lease-generation" : "p-3"}>
            {leaseImage ? (
              <img
                src={image6}
                className={
                  rounded ? "w-100 rounded-0 mb-2" : "w-100 rounded-4 mb-2"
                }
                id="lease-image"
              />
            ) : (
              <img src={image6} className="w-100 rounded-4 mb-2" />
            )}
            <div className="py-2 px-3">
              {greenText ? (
                <p className="fontSize26_green ">Lease Generation</p>
              ) : orangeText ? (
                <p className="fontSize26_orange">Lease Generation</p>
              ) : (
                <p className="fontSize17_black_bold">Lease Generation</p>
              )}

              {blackText ? (
                <p className="fontSize13_Black">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : whiteText ? (
                <p className="fontSize13_white">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              ) : (
                <p className="fontSize13_LightGray">
                  Our checklist includes essential tasks for every property
                  manager. Stay organized and efficient with this easy-to-follow
                  guide
                </p>
              )}

              {visibleButton ? (
                <Link to="">
                  <div className="d-flex justify-content-between align-items-center my-4">
                    <p className="fontSize20_gray">{btnText}</p>
                    <button className="caret-button">
                      <img src={imagePath.Right_Caret} />
                    </button>
                  </div>
                </Link>
              ) : (
                " "
              )}
            </div>
          </div>
        </Slider>
      ) : (
        <Slider {...settings}>
          <div className="p-3">
            <video
              src={vdoPath}
              poster={img1}
              controls
              className="w-100 rounded-4"
            ></video>
          </div>

          <div className="p-3">
            <video
              src={vdoPath}
              poster={img2}
              controls
              className="w-100 rounded-4"
            ></video>
          </div>
          <div className="p-3">
            <video
              src={vdoPath}
              poster={img3}
              controls
              className="w-100 rounded-4"
            ></video>
          </div>
          <div className="p-3">
            <video
              src={vdoPath}
              poster={img1}
              controls
              className="w-100 rounded-4"
            ></video>
          </div>

          <div className="p-3">
            <video
              src={vdoPath}
              poster={img2}
              controls
              className="w-100 rounded-4"
            ></video>
          </div>
          <div className="p-3">
            <video
              src={vdoPath}
              poster={img3}
              controls
              className="w-100 rounded-4"
            ></video>
          </div>
        </Slider>
      )}
    </div>
  );
};

export default Testimonial_video;
