import React from "react";
import Landing_Footer from "../../components/Landing_Footer";
import Navbar from "../../components/Navbar";
import { imagePath } from "../../constants/imagePath";
import RightSection_Comp from "../../components/RightSection_Comp";
import Heading_Section from "../../components/Heading_Section";

import Testimonial_video from "../../components/testimonial_video";
import Included_Checklist from "../../components/Included_Checklist";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cards from "../../components/Cards";
import LeftSection_Comp from "../../components/LeftSection_Comp";

const LandingPage_9 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-nine-section">
          <div className="container">
            <div className="streamline-operations" id="streamline">
              <div className="streamline-heading">
                <h1 className="fontSize50_black_TestTiempos">
                  Stay Ahead with Our Comprehensive Rental Market Report!
                </h1>
                <p className="fontSize17_MediumGray">
                  Download our comprehensive checklist to stay organized and
                  efficient.
                </p>
              </div>
              <div id="testimonial-id" className="my-4">
                <img src={imagePath.Landing_image_9} className="page-9-img" />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <LeftSection_Comp
            mainHeading="Stay Informed About the"
            subHeading=" Rental Market"
            paragraph="Our market report offers insights into current trends, future forecasts, and essential data for informed rental property decisions. It helps you stay ahead of the curve and refine your rental strategy. With detailed information, you'll optimize your approach effectively. Stay informed and make strategic choices with confidence."
            btnText="Get Your Report Now"
            image={imagePath.Rental_Market}
            visibleButton
          />
        </div>

        <div className="checklist-main-section">
          <div className="container">
            <div className="checklist-section">
              <Heading_Section
                white={false}
                heading_Black="See What’s "
                heading_Green="Inside"
                fontSize="20"
                subcontent="Discover essential tips and tasks to ensure everything is in order for a smooth and successful stay. From preparation to guest experience, our checklist has you covered"
              />
              <Testimonial_video
                id="testimonial-id"
                image={true}
                image1={imagePath.Inside_1}
                image2={imagePath.Inside_2}
                image3={imagePath.Inside_3}
                image4={imagePath.Inside_1}
                image5={imagePath.Inside_2}
                image6={imagePath.Inside_3}
              />
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card" id="enter-email-card-3">
              <h3 className="fontSize20_white">
                Enter Your Email to Download the Full Market Report
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="included-in-checklist">
          <div className="container">
            <Heading_Section
              heading_Black="What’s Included"
              heading_Green=" in the Report?"
              subcontent="Download our free 8-point checklist to make sure you're not missing anything important."
              left={true}
            />
            <Included_Checklist listContent="Current Rental Market Trends" />

            <Included_Checklist listContent="Future Market Forecasts" />

            <Included_Checklist listContent="Rental Rates by Area" />

            <Included_Checklist listContent="Occupancy Rates" />

            <Included_Checklist listContent="Investment Opportunities" />
            <Included_Checklist listContent="Expert Analysis and Insights" />
            <div className="why-choose-btn">
              <button className="btn-style">Get My Report Now</button>
            </div>
          </div>
        </div>

        <div className="landing-testimonials-main-section">
          <div className="container">
            <div className="client-testimonials" id="client-testimonial-page4">
              <Heading_Section
                left
                heading_Black="What Our"
                heading_Green="Clients Say "
                subcontent=" Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories"
              />
              <div
                className="Client-appointment-slider"
                id="client-say-section"
              >
                <Slider {...settings}>
                  <Cards
                    slide
                    slideImg={imagePath.Client_3}
                    useName="James Pattinson"
                    qoutesImg={imagePath.Double_Qoutes_Green}
                    ratingImg={imagePath.Rating_4}
                    feedback="“Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.”"
                  />
                  <Cards
                    slide
                    slideImg={imagePath.Client_4}
                    useName="Greg Stuart"
                    qoutesImg={imagePath.Double_Qoutes_Green}
                    ratingImg={imagePath.Rating_5}
                    feedback="“Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.”"
                  />
                  <Cards
                    slide
                    slideImg={imagePath.Client_5}
                    useName="Trevor Mitchell"
                    qoutesImg={imagePath.Double_Qoutes_Green}
                    ratingImg={imagePath.Rating_3}
                    feedback="“Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.”"
                  />
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section" id="why-choose-us">
          <div className="container" id="landing-page-6-container">
            <div className="core-values-main ">
              <Heading_Section
                white={false}
                heading_Black="Why Download Our  "
                heading_Green="Rental Market Report?"
                subcontent=" Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings."
              />

              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Expert_Analysis}
                  cardTitle="In-Depth Analysis"
                  cardDescription="Professional quality means delivering exceptional work that meets high standards, ensuring reliability, expertise, and client satisfaction."
                />

                <Cards
                  image={imagePath.Dollar_Timer}
                  cardTitle="Stay Informed"
                  cardDescription="Time-saving techniques streamline tasks, enhance productivity, reduce stress, and free up time for meaningful activities"
                />

                <Cards
                  image={imagePath.Idea}
                  cardTitle="Expert Insights"
                  cardDescription="Wide reach allows ideas and messages to impact diverse audiences, fostering broader connections and understanding."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <Landing_Footer />
    </>
  );
};

export default LandingPage_9;
