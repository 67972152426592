import React from 'react'

const RightSection_Comp = ({
    mainHeading,
    paragraph,
    btnText,
    image,
    subHeading,
    visibleButton,
    subParagraph
}) => {
  return (
  <>
   <div className="right-main-section">
   <div className="right-section-part-1">
    <img src={image} alt=''/>
  </div>
  <div className="right-section-part-2">
    <h1 className='fontSize48_black'>{mainHeading} {"  "}<span className='fontSize48_green'>{subHeading}</span> </h1>
    <p className='fontSize18_Gray'>{paragraph}</p>
    <p className='fontSize18_Gray'>{subParagraph}</p>
   {
    visibleButton ?  <button className='btn-style'>{btnText}<i className="fa-solid fa-angle-right"></i></button> : null
   }
  </div>
 
  </div>
  </>
  )
}

export default RightSection_Comp