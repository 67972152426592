import React from 'react'

const Cards = ({
  image,
  cardTitle,
  cardDescription,
  useName,
  ratingImg,
  feedback,
  slideImg,
  slide,
  qoutesImg
}) => {
  return (
    <>
{
  slide ?
  <div className="Slider-card">
    <img src={slideImg} alt='' className='userImg'/>
    <div className='card-section'>
      <h2 className='fontSize20_black'>{useName}</h2>
      <img src={ratingImg} className='rate-img'/>
      <img src={qoutesImg} className='qoutes-img'/>
      <p className='fontSize16_DarkGray'>{feedback}</p>
    </div>
    </div>
  :
  <div className="card">
  <img src={image}  alt="cards"/>
  <div className="card-content">
    <h1 className="fontSize24_black">{cardTitle}</h1>
    <p className="fontSize16_gray">{cardDescription}</p>
  </div>
</div>
}

    </>
  )
}

export default Cards