import React from "react";
import Landing_Footer from "../../components/Landing_Footer";
import Navbar from "../../components/Navbar";
import { imagePath } from "../../constants/imagePath";
import RightSection_Comp from "../../components/RightSection_Comp";
import Heading_Section from "../../components/Heading_Section";
import Testimonial from "../../components/Testimonial";
import Testimonial_video from "../../components/testimonial_video";
import Included_Checklist from "../../components/Included_Checklist";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cards from "../../components/Cards";

const LandingPage_8 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-eight-section">
          <div className="container">
            <div className="streamline-operations">
              <h1 className="fontSize50_white">
                Streamline Your Rental Operations with Our Property Management
                Checklist!
              </h1>
              <p className="fontSize17_white ">
                Download our comprehensive checklist to stay organized and
                efficient.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <RightSection_Comp
            mainHeading="Your Trusted Partner in"
            subHeading="Rental Management"
            paragraph="At AngiesHelpers.com, we aim to provide property owners with hassle-free, profitable rental management. Founded by industry experts, we blend local knowledge and offshore support to offer top-tier services for both short-term Airbnb rentals and long-term leases. Our commitment to excellence ensures optimized listings and seamless guest experiences."
            btnText="Get Started Now"
            image={imagePath.Rental_Management}
            visibleButton
          />
        </div>

        <div className="checklist-main-section">
          <div className="container">
            <div className="checklist-section">
              <Heading_Section
                white={false}
                heading_Black="Take a Peek at "
                heading_Green="Our Checklist"
                fontSize="20"
                subcontent="Discover essential tips and tasks to ensure everything is in order for a smooth and successful stay. From preparation to guest experience, our checklist has you covered"
              />
              <Testimonial_video
                id="testimonial-id"
                image={true}
                image1={imagePath.Checklist_1}
                image2={imagePath.Checklist_2}
                image3={imagePath.Checklist_3}
                image4={imagePath.Checklist_1}
                image5={imagePath.Checklist_2}
                image6={imagePath.Checklist_3}
              />
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card" id="enter-email-card-3">
              <h3 className="fontSize20_white">
                Enter Your Email to Download the Full Checklist
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="included-in-checklist">
          <div className="container">
            <Heading_Section
              heading_Black="What’s Included"
              heading_Green=" in the Checklist?"
              subcontent="Download our free 8-point checklist to make sure you're not missing anything important."
              left={true}
            />
            <Included_Checklist listContent="Tenant Screening Procedures" />

            <Included_Checklist listContent="Lease Agreement Essentials" />

            <Included_Checklist listContent="Move-In and Move-Out Checklists" />

            <Included_Checklist listContent="Maintenance Schedules" />

            <Included_Checklist listContent="Rent Collection Best Practices" />
            <Included_Checklist listContent="Property Inspection Tips" />
            <div className="why-choose-btn">
              <button className="btn-style">Get My Checklist Now</button>
            </div>
          </div>
        </div>

        <div
          className="landing-testimonials-main-section"
          id="landing-section-eight"
        >
          <div className="container">
            <div className="client-testimonials" id="landing-page-8-slide">
              <div className="testimonials">
                <h1 className="fontSize48_black" id="heading">
                  What Our<span className="fontSize48_green"> Client Says</span>{" "}
                </h1>
                <p className="fontSize22_gray ">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>

              <Slider {...settings}>
                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div className="qoutes-img-left">
                    <p className="fontSize16_black  d-flex gap-1 mb-2">
                      The service was absolutely impeccible. I see myself and my
                      family visiting here much more frequently.
                    </p>
                  </div>

                  <div className="testimonial-user">
                    <img src={imagePath.Client_11} alt="" />
                    <div className="user-name d-flex flex-column">
                      <b>
                        {" "}
                        <h3 className="fontSize16_Green">John Doe</h3>
                      </b>
                      <h5 className="fontSize14_MediumGray m-0">
                        SomeCompany LLC.
                      </h5>
                    </div>
                  </div>
                </div>

                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div className="qoutes-img-left">
                    <p className="fontSize16_black  d-flex gap-1 mb-2">
                      I was so surprised at the quality of food. I didn’t expect
                      it to be this good for how cheap it all was. Awesome!
                    </p>
                  </div>

                  <div className="testimonial-user">
                    <img src={imagePath.Client_12} alt="" />
                    <div className="user-name d-flex flex-column">
                      <b>
                        {" "}
                        <h3 className="fontSize16_Green">John Doe</h3>
                      </b>
                      <h5 className="fontSize14_MediumGray m-0">
                        SomeCompany LLC.
                      </h5>
                    </div>
                  </div>
                </div>

                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div className="qoutes-img-left">
                    <p className="fontSize16_black d-flex gap-1 mb-2">
                      That was amazing! I loved the pancakes. I’ve never had
                      that type of taste hit my mouth before. Unreal!
                    </p>
                  </div>
                  <div className="testimonial-user">
                    <img src={imagePath.Client_13} alt="" />
                    <div className="user-name d-flex flex-column">
                      <b>
                        {" "}
                        <h3 className="fontSize16_Green">John Doe</h3>
                      </b>
                      <h5 className="fontSize14_MediumGray m-0">
                        SomeCompany LLC.
                      </h5>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section" id="why-choose-us">
          <div className="container" id="landing-page-6-container">
            <div className="core-values-main ">
              <Heading_Section
                white={false}
                heading_Black="Why Use Our "
                heading_Green="Property Management Checklist?"
                subcontent=" Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings."
              />

              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Report}
                  cardTitle="Comprehensive Coverage"
                  cardDescription="Professional quality means delivering exceptional work that meets high standards, ensuring reliability, expertise, and client satisfaction."
                />

                <Cards
                  image={imagePath.Dollar_Timer}
                  cardTitle="Time-Saving"
                  cardDescription="Time-saving techniques streamline tasks, enhance productivity, reduce stress, and free up time for meaningful activities"
                />

                <Cards
                  image={imagePath.Idea}
                  cardTitle="Expert Tips"
                  cardDescription="Wide reach allows ideas and messages to impact diverse audiences, fostering broader connections and understanding."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <Landing_Footer />
    </>
  );
};

export default LandingPage_8;
