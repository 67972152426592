import React from 'react'

const Left_Step_Comp = ({
    image,
    heading,
    paragraph,
    subParagraph
}) => {
  return (
   <>
     <div className="Step-section" id='stepper'>
   
    <div className="step-content">
        <h1 className='fontSize42_black'>{heading}</h1>
        <p className='fontSize17_Gray '>{paragraph}</p>
        <p className='fontSize17_Gray '>{subParagraph}</p>
    </div>
    <div className="step-img">
        <img src={image}/>
    </div>
  </div>
   </>
  )
}

export default Left_Step_Comp