import React from "react";
import Navbar from "../../components/Navbar";
import Cards from "../../components/Cards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { imagePath } from "../../constants/imagePath";
import Testimonial from "../../components/Testimonial";
import Landing_Footer from "../../components/Landing_Footer";
import Blog_Card_Vertical from "../../components/Blog_Card_Vertical";
import InputWithSearch from "../../components/InputWithSearch";

const LandingPage_2 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // screens smaller than 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // screens smaller than 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const industrSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-hero-section">
          <div className="container">
            <div className="landing-page-2-heading">
              <h1 className="fontSize48_black">
                What’s the Rent? Get an Instant Rental Estimate!
              </h1>
              <p className="fontSize17_Gray">
                Enter your property details and let our AI calculate your rental
                income potential
              </p>
              <div className="arrow-image">
                <img src={imagePath.Arrow_Rotate} />
              </div>
            </div>
            <div className="landing-page-img-2">
              <div className="top-email-section">
               <div className="d-flex gap-2">
               <img src={imagePath.Search2} />
               <input type="text" placeholder="Enter Your Address" />
               </div>
                <button className="btn-style">Get An Estimate</button>
              </div>
            </div>
          </div>
        </div>

        <div className="why-choose-section-landing-page">
          <div className="container">
            <div className="why-choose-angies-main">
              <div
                className="why-choose-angies-heading"
                id="landing-page-heading"
              >
                <h1 className="fontSize48_black" id="whyChoose-heading">
                  How <span className="fontSize48_green"> Angies Helpers</span>{" "}
                  <span className="fontSize48_black">Work ?</span>{" "}
                </h1>
                <p className="fontSize22_gray" id="why-choose-para">
                  Providing expert insights and updates on the latest trends in
                  home stay and property management
                </p>
              </div>
              <div className="landing-grid-container" id="landing-page-2-grid">
                <Cards
                  image={imagePath.Search}
                  cardTitle="Enter Your Property Address"
                  cardDescription="We handle listing optimization, pricing, guest communication, check-ins/outs, cleaning, and maintenance to maximize occupancy and income."
                />

                <Cards
                  image={imagePath.Market}
                  cardTitle="Compare to Market"
                  cardDescription="We provide tenant screening, lease management, rent collection, maintenance, and tenant relations to ensure satisfaction and upkeep."
                />

                <Cards
                  image={imagePath.Dollar}
                  cardTitle="Instant Rent Estimate"
                  cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
                />
              </div>
            </div>
          </div>
          <div className="why-choose-btn">
            <button className="btn-style">Get Start Now</button>
          </div>
        </div>

        <div className="property-detail-section">
          <div className="container">
            <div className="property-detail-form">
              <div className="why-choose-angies-heading">
                <h1 className="fontSize48_black" id="landing-heading">
                  Property <span className="fontSize48_green"> Details</span>{" "}
                </h1>
                <p className="fontSize22_gray">
                  At AngiesHelpers.com, our journey began with a simple mission:
                  to provide property owners with a hassle-free, profitable
                  rental management experience
                </p>
              </div>
              <div className="contact-section" id="property-section">
                <div className="contact-form" id="property-details">
                  <div className="contact-bg-left-card"></div>
                  <div className="form">
                    <form>
                      <div className="mb-4">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Address"
                        />
                      </div>
                      <select
                        class="form-select mb-4"
                        id="property-selection"
                        aria-label="Default select example"
                      >
                        <option selected>Property Type</option>
                        <option value="Apartment">
                          <img src={imagePath.Left_Caret} alt="" /> Apartment
                        </option>
                        <option value="House">
                          <img src={imagePath.Left_Caret} alt="" /> House
                        </option>
                        <option value="Condo">
                          <img src={imagePath.Left_Caret} alt="" /> Condo
                        </option>
                      </select>

                      <select
                        class="form-select mb-4"
                        aria-label="Default select example"
                      >
                        <option selected>Number Of Bedrooms</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>

                      <select
                        class="form-select mb-4"
                        aria-label="Default select example"
                      >
                        <option selected>Number Of Bathrooms</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>

                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Pool
                        </label>
                      </div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label class="form-check-label" for="flexCheckChecked">
                          Garage
                        </label>
                      </div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label class="form-check-label" for="flexCheckChecked">
                          Furnished
                        </label>
                      </div>

                      <button className="btn-style" id="form-button">
                        Income Potential
                      </button>
                    </form>
                  </div>
                  <div className="contact-bg-right-card"></div>
                </div>
                <div className="property-detail-img">
                  <img src={imagePath.Property_Details_2} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="property-potential-earning">
          <div className="container">
            <div className="testimonials">
              <h1 className="fontSize48_black" id="heading">
                Your Property's{" "}
                <span className="fontSize48_green"> Potential Earnings</span>{" "}
              </h1>
              <p className="fontSize22_gray">
                Unlock the maximum rental income with expert management and
                strategic property enhancement.
              </p>
            </div>
            <p className="fontSize16_black" id="earnings-2">
              Your property's potential earnings
            </p>
            <div className="potential-earnings flex-column flex-md-row">
              <div className="Airbnb-earning">
                <div className="chart-headning">
                  <p className="fontSize40_green">Airbnb</p>
                </div>
                <div className="graph-image-card" id="long-term-card-2">
                  <div id="landing-page-long-term-card">
                    <p className="fontSize15_Gray">
                      Potential earnings over time
                    </p>
                    <h4 className="fontSize50_black">128,7K</h4>
                    <img src={imagePath.Graph2} alt="" />
                  </div>

                  <p className="fontSize17_black">Average monthly income</p>
                  <div className="expense-card bg-light">
                    <p className="fontSize16_black ">$3,000</p>
                    <h3 className="fontSize24_black">$2,500</h3>
                    <p className="fontSize16_Green">+5%</p>
                  </div>
                </div>
              </div>
              <div className="Airbnb-earning">
                <div className="chart-headning">
                  <p className="fontSize40_bluishGray">Long-term rental</p>
                </div>
                <div className="graph-image-card" id="long-term-card-2">
                  <div id="landing-page-long-term-card">
                    <p className="fontSize15_Gray">
                      Potential earnings over time
                    </p>
                    <h4 className="fontSize50_black">128,7K</h4>
                    <img src={imagePath.Graph1} alt="" />
                  </div>
                  <div className="expense-card bg-light" id="expenses">
                    <p className="fontSize16_black ">$1,500</p>
                    <h3 className="fontSize24_black">$1,000</h3>
                    <p className="fontSize16_Red">-5%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Get Full Report</button>
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card" id="enter-email-card-2">
              <h3 className="fontSize20_white">
                Enter Your Email to Receive Your Full Analysis Report
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-testimonials-main-section">
          <div className="container">
            <div className="client-testimonials">
              <div className="testimonials">
                <h1 className="fontSize48_black" id="heading">
                  What Our <span className="fontSize48_green">Clients Say</span>{" "}
                </h1>
                <p className="fontSize22_gray  mb-4">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>
              <div className="industry-slider" id="client-say-section">
                <Slider {...industrSettings}>
                  {/* <<<<<<<<<<Slide 1>>>>>>>>>>> */}
                  <div className="industry-news-section">
                    <Testimonial
                      backgrundColor="#1AAE67"
                      feedback="I couldn't be happier. I use Uplisting daily and it's really helped me turn a corner for my business from managing a few homes (that I could do manually) to many, many more."
                      image={imagePath.Testimonial_1}
                      userName="T. Sanchez"
                      paraId="landing-para"
                      id="landing-para2"
                      place="Scottsdale, AZ"
                      quetesImage={imagePath.whiteQuotes}
                      paraColor="#fff"
                      userNameColor="#fff"
                      placeNameColor="#FFC97C"
                      paraFont="18px"
                      userNameFont="16px"
                      placeNameFont="14px"
                      maxWidth="58px"
                      maxWidthQuote='32px'
                    />
                    <div
                      className="tips_guide_section gap-4"
                      id="industry-tips"
                    >
                      <Testimonial
                        feedback="I couldn't be happier. I use Uplisting daily and it's really helped me turn a corner for my business from managing a few homes (that I could do manually) to many, many more."
                        image={imagePath.Testimonial_1}
                        userName="T. Sanchez"
                        paraId="landing-para"
                        id="landing-para2"
                        place="Scottsdale, AZ"
                        quetesImage={imagePath.YellowQoutes}
                        paraFont="14px"
                        paraColor="#000"
                        userNameFont="12px"
                        placeNameFont="14px"
                        maxWidth="40px"
                        maxWidthQuote='25px'
                      />

                      <Testimonial
                        feedback="I couldn't be happier. I use Uplisting daily and it's really helped me turn a corner for my business from managing a few homes (that I could do manually) to many, many more."
                        image={imagePath.Testimonial_1}
                        userName="T. Sanchez"
                        paraId="landing-para"
                        id="landing-para2"
                        place="Scottsdale, AZ"
                        quetesImage={imagePath.YellowQoutes}
                        paraFont="14px"
                        paraColor="#000"
                        userNameFont="12px"
                        placeNameFont="14px"
                        maxWidth="40px"
                        maxWidthQuote='25px'
                      />
                    </div>
                  </div>

                  {/* <<<<<<<<<<Slide 2>>>>>>>>>>> */}
                  <div className="industry-news-section">
                    <Testimonial
                      backgrundColor="#1AAE67"
                      feedback="I couldn't be happier. I use Uplisting daily and it's really helped me turn a corner for my business from managing a few homes (that I could do manually) to many, many more."
                      image={imagePath.Testimonial_1}
                      userName="T. Sanchez"
                      paraId="landing-para"
                      id="landing-para2"
                      place="Scottsdale, AZ"
                      quetesImage={imagePath.whiteQuotes}
                      paraColor="#fff"
                      userNameColor="#fff"
                      placeNameColor="#FFC97C"
                      paraFont="18px"
                      userNameFont="16px"
                      placeNameFont="14px"
                      maxWidth="58px"
                      maxWidthQuote='32px'
                    />
                    <div
                      className="tips_guide_section gap-4"
                      id="industry-tips"
                    >
                      <Testimonial
                        feedback="I couldn't be happier. I use Uplisting daily and it's really helped me turn a corner for my business from managing a few homes (that I could do manually) to many, many more."
                        image={imagePath.Testimonial_1}
                        userName="T. Sanchez"
                        paraId="landing-para"
                        id="landing-para2"
                        place="Scottsdale, AZ"
                        quetesImage={imagePath.YellowQoutes}
                        paraFont="14px"
                        paraColor="#000"
                        userNameFont="12px"
                        placeNameFont="14px"
                        maxWidth="40px"
                        maxWidthQuote='25px'
                      />

                      <Testimonial
                        feedback="I couldn't be happier. I use Uplisting daily and it's really helped me turn a corner for my business from managing a few homes (that I could do manually) to many, many more."
                        image={imagePath.Testimonial_1}
                        userName="T. Sanchez"
                        paraId="landing-para"
                        id="landing-para2"
                        place="Scottsdale, AZ"
                        quetesImage={imagePath.YellowQoutes}
                        paraFont="14px"
                        paraColor="#000"
                        userNameFont="12px"
                        placeNameFont="14px"
                        maxWidth="40px"
                        maxWidthQuote='25px'
                      />
                    </div>
                  </div>
                </Slider>
              </div>
             
            </div>
          </div>
        </div>

        <div className="why-choose-us-section">
          <div className="container">
            <div className="core-values-main ">
              <div className="why-choose-angies-heading">
                <h1 className="fontSize48_black" id="landing-heading">
                  Why Use Our Rental{"  "}
                  <span className="fontSize48_green">Estimate Tool?</span>{" "}
                </h1>
                <p className="fontSize16_gray ">
                  Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings.
                </p>
              </div>
              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Estimation}
                  cardTitle="Accurate Estimates"
                  cardDescription="Utilize our advanced AI technology to compare rental incomes with unparalleled precision, ensuring you make informed and profitable decisions."
                />

                <Cards
                  image={imagePath.Timer}
                  cardTitle="Quick and Easy"
                  cardDescription="Enhance your rental approach with our expertly crafted strategies, specifically designed to help you optimize your property and achieve maximum profit"
                />

                <Cards
                  image={imagePath.Report}
                  cardTitle="Comprehensive Reports"
                  cardDescription="Access detailed and in-depth reports that provide actionable insights, helping you understand your rental market better and make smarter investment choices."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>

        <Landing_Footer />
      </div>
    </>
  );
};

export default LandingPage_2;
