import React, { useState } from "react";

const Date_Input = ({ placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [date, setDate] = useState("");

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!date) {
      setIsFocused(false);
    }
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  return (

    <div className="date-container" id="calendar-input">
      {isFocused ? (
        <input
          type="date"
          value={date}
          onChange={handleDateChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <input
          type="text"
          value={date}
          onFocus={handleFocus}
          placeholder={placeholder}
          readOnly
        />
      )}
    </div>
  );
};

export default Date_Input;
