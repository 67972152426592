import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Cards from "../../components/Cards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { imagePath } from "../../constants/imagePath";
import Testimonial from "../../components/Testimonial";
import Landing_Footer from "../../components/Landing_Footer";
import Blog_Card_Vertical from "../../components/Blog_Card_Vertical";
import Blog_Card from "../../components/Blog_Card";
import Date_Input from "../../components/Date_Input";

const LandingPage_3 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-three-section">
          <div className="container">
            <div className="landing-page-3">
              <h1 className="fontSize48_white ">
                Lease Generator : Create a Professional Lease in Minutes
              </h1>
              <p className="fontSize17_white">
                Quick and easy lease creation tailored to your property.
              </p>
              <div className="why-choose-btn">
                <button className="btn-style">Get Start Now</button>
              </div>
            </div>
          </div>
        </div>

        <div className="why-choose-section-landing-page">
          <div className="container">
            <div className="why-choose-angies-main">
              <div
                className="why-choose-angies-heading"
                id="landing-page-heading"
              >
                <h1 className="fontSize48_black" id="whyChoose-heading">
                  A comprehensive
                  <span className="fontSize48_green">
                    {" "}
                    lease agreement
                  </span>{" "}
                </h1>
                <p className="fontSize22_gray" id="lease-agreement-para">
                  Sure, here’s a step-by-step guide for creating a comprehensive
                  lease agreement for your rental property using a lease
                  generator
                </p>
              </div>
              <div className="landing-grid-container" id="landing-page-2-grid">
                <Cards
                  image={imagePath.Search}
                  cardTitle="Enter Your Property Address"
                  cardDescription="We handle listing optimization, pricing, guest communication, check-ins/outs, cleaning, and maintenance to maximize occupancy and income."
                />

                <Cards
                  image={imagePath.Detail_Board}
                  cardTitle="Rent Details"
                  cardDescription="We provide tenant screening, lease management, rent collection, maintenance, and tenant relations to ensure satisfaction and upkeep."
                />

                <Cards
                  image={imagePath.Dollar}
                  cardTitle="Security Deposit"
                  cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
                />
              </div>
            </div>
          </div>
          <div className="why-choose-btn">
            <button className="btn-style">Get Start Now</button>
          </div>
        </div>

        <div className="property-detail-section" id="landing-page-3">
          <div className="container">
            <div className="property-detail-form">
              <div className="why-choose-angies-heading">
                <h1 className="fontSize48_black" id="landing-heading">
                  Lease <span className="fontSize48_green"> Details</span>{" "}
                </h1>
                <p className="fontSize22_gray">
                  At AngiesHelpers.com, our journey began with a simple mission:
                  to provide property owners with a hassle-free, profitable
                  rental management experience
                </p>
              </div>
              <div className="contact-section" id="property-section">
                <div className="contact-form" id="property-details">
                  <div className="contact-bg-left-card"></div>
                  <div className="property-form">
                    <p className="fontSize18_black">
                      Enter Your Lease Details Below
                    </p>
                    <form>
                      <div>
                        <div className="mb-4">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Address"
                          />
                        </div>
                        <div className="date-input">
                        <Date_Input placeholder="Start Date"/>
                        <Date_Input placeholder="End Date"/>
                        </div>

                        <div className="mb-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Rent Amount"
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Security Deposit"
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Tenant Names"
                          />
                        </div>
                        <div class="form-floating">
                          <textarea
                            class="form-control"
                            placeholder="Additional Clauses"
                            id="floatingTextarea3"
                          ></textarea>
                          <label for="floatingTextarea2">
                            Additional Clauses
                          </label>
                        </div>
                      </div>
                      <button className="btn-style" id="form-button">
                        Review Lease
                      </button>
                    </form>
                  </div>
                  <div className="contact-bg-right-card"></div>
                </div>
                <div id="property-detail-img">
                  <img src={imagePath.Property_Details_3} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="property-potential-earning">
          <div className="container">
            <div className="testimonials">
              <h1 className="fontSize48_black" id="lease-heading">
                Lease <span className="fontSize48_green">Preview</span>{" "}
              </h1>
              <p className="fontSize20_gray ">Your Lease Agreement</p>
            </div>
            <p className="fontSize22_black" id="earnings-2">
              Property Details
            </p>
            <div className="lease-agreement">
              <div className="lease-agreement-1">
                <div className="lease-details">
                  <hr />
                  <p className="fontSize14_DarkGray">Property Address</p>
                  <h4 className="fontSize20_black">1234 5th Ave</h4>
                </div>

                <div className="lease-details">
                  <hr />
                  <p className="fontSize14_DarkGray">State</p>
                  <h4 className="fontSize20_black">NY</h4>
                </div>

                <div className="lease-details">
                  <hr />
                  <p className="fontSize14_DarkGray">Country</p>
                  <h4 className="fontSize20_black">USA</h4>
                </div>
              </div>
              <div className="lease-agreement-2">
                <div className="lease-details">
                  <hr />
                  <p className="fontSize14_DarkGray">City</p>
                  <h4 className="fontSize20_black">New York</h4>
                </div>

                <div className="lease-details">
                  <hr />
                  <p className="fontSize14_DarkGray">Zip Code</p>
                  <h4 className="fontSize20_black">10001</h4>
                </div>

                <div className="lease-details">
                  <hr />
                  <p className="fontSize14_DarkGray">Unit #</p>
                  <h4 className="fontSize20_black">3rd Floor</h4>
                </div>
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Download Lease</button>
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card" id="enter-email-card-3">
              <h3 className="fontSize20_white">
                Enter Your Email to Receive Your Full Analysis Report
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-testimonials-main-section">
          <div className="container">
            <div className="client-testimonials" id="client-testimonial-page3">
              <div className="testimonials" id="page3-slider">
                <h1 className="fontSize48_black" id="heading">
                  What Our{" "}
                  <span className="fontSize48_green">Clients Say </span>{" "}
                </h1>
                <p className="fontSize22_gray">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>

              <div className="industry-slider" id="client-say-section">
                <Slider {...settings}>
                  <Testimonial
                    feedback="We are extremely satisfied with our purchase from National Industries. Your products and support exceeded expectations"
                    starImg={imagePath.Yellow_Star}
                    greenQoutes
                    greenUserName="Berry Gunawan"
                    greenImage={imagePath.Client_1}
                    qoutesImg={imagePath.Green_Qoutes}
                    ratings="3.5"
                    greenMaxWidth={50}
                  />
                  <Testimonial
                    feedback="We are extremely satisfied with our purchase from National Industries. Your products and support exceeded expectations"
                    starImg={imagePath.Yellow_Star}
                    greenQoutes
                    greenUserName="Cobocannaeru"
                    greenImage={imagePath.Client_2}
                    qoutesImg={imagePath.Green_Qoutes}
                    ratings="3.5"
                    greenMaxWidth={50}
                  />
                  <Testimonial
                    feedback="We are extremely satisfied with our purchase from National Industries. Your products and support exceeded expectations"
                    starImg={imagePath.Yellow_Star}
                    greenQoutes
                    greenUserName="Berry Gunawan"
                    greenImage={imagePath.Client_1}
                    qoutesImg={imagePath.Green_Qoutes}
                    ratings="3.5"
                    greenMaxWidth={50}
                  />
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section">
          <div className="container">
            <div className="core-values-main ">
              <div className="why-choose-angies-heading">
                <h1 className="fontSize48_black" id="landing-heading">
                  Why Use Our {"  "}
                  <span className="fontSize48_green">
                    Lease Generator?
                  </span>{" "}
                </h1>
                <p className="fontSize16_gray ">
                  Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings.
                </p>
              </div>
              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Estimation}
                  cardTitle="Quick and Easy"
                  cardDescription="Utilize our advanced AI technology to compare rental incomes with unparalleled precision, ensuring you make informed and profitable decisions."
                />

                <Cards
                  image={imagePath.Filter}
                  cardTitle="Customizable"
                  cardDescription="Enhance your rental approach with our expertly crafted strategies, specifically designed to help you optimize your property and achieve maximum profit"
                />

                <Cards
                  image={imagePath.Professional_Quality}
                  cardTitle="Professional Quality"
                  cardDescription="Access detailed and in-depth reports that provide actionable insights, helping you understand your rental market better and make smarter investment choices."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>

        <Landing_Footer />
      </div>
    </>
  );
};

export default LandingPage_3;
