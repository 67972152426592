import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { imagePath } from '../constants/imagePath'
import RightSection_Comp from '../components/RightSection_Comp'

const ContactUs = () => {
  return (
   <>
   <Navbar mainNavbar/>
    <div className="container-fluid">
      <div className="contact-heading-section">
        <div className="container">
          <h1 className='fontSize55_black'>Contact Us</h1>
        </div>
      </div>
    <div className="contact-main-section">
            <div className="container">
              <div className="contact-section">
                <div className="contact-details">
                 <div className="contact-heading">
                 <h1 className="fontSize48_black">Contact Us</h1>
                  <p className="fontSize22_Green">
                    <b> Have questions or need help?</b>{" "}
                    <span className="fontSize22_gray">
                      Contact us via email, phone, or our online form. We're
                      dedicated to exceptional service and support for all your
                      property management needs
                    </span>
                  </p>
                 </div>

                  <div className="contact-feilds">
                    <div className="feilds-img">
                      <img src={imagePath.Call} alt="" />
                    </div>
                    <div className="feilds-details">
                      <h3 className="fontSize28_black">Phone Number</h3>
                      <p className="fontSize24_Gray">1-548-634-5896</p>
                    </div>
                  </div>

                  <div className="contact-feilds">
                    <div className="feilds-img">
                      <img src={imagePath.Email} alt="" />
                    </div>
                    <div className="feilds-details">
                      <h3 className="fontSize28_black">Email Address</h3>
                      <p className="fontSize24_Gray">angieshelpers@gmail.com</p>
                    </div>
                  </div>

                  <div className="contact-feilds">
                    <div className="feilds-img" id="email">
                      <img src={imagePath.Location} alt="" />
                    </div>
                    <div className="feilds-details">
                      <h3 className="fontSize28_black">Location</h3>
                      <p className="fontSize24_Gray">
                        2963 E Thousand Oaks Blvd, Thousand Oaks, California
                        (United States)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contact-form">
                  <div className="contact-bg-left-card"></div>
                  <div className="form">
                    <form>
                      <div className="mb-4">
                        <label className="form-label">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chance Kenter"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Email address</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email address"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Phone Number</label>
                        <input
                          className="form-control"
                          placeholder="Chance Kenter"
                        />
                      </div>
                      <div className="mb-4">
                        <label for="floatingTextarea2" id="label">Message</label>
                        <textarea
                          className="form-control"
                          placeholder="Chance Kenter"
                          id="floatingTextarea2"
                          style={{ height: "140px" }}
                        ></textarea>
                      </div>
                      <button className="btn-style" id="form-button">
                        Send Message
                      </button>
                    </form>
                  </div>
                  <div className="contact-bg-right-card"></div>
                </div>
              </div>
            </div>
          </div>

    
        <div className="contact-container">
        <div className="why-choose-angies-heading" id='contact'>
                  <h1 className="fontSize48_white" id="heading">
                  Follow us on {" "}
                    <span className="fontSize48_green">social media
                    </span>{" "}
                  </h1>
                  <p className="fontSize22_white">
                  Stay connected! Follow us on social media for updates, exclusive content, and the latest news. Join the conversation!
                  </p>
                </div>
                <div className="social-network-links" id='social-links'>
                <a href="https://www.facebook.com/">   <div id="social-background">
                   <img src={imagePath.Green_fb} className='social'/>
                  </div></a>
                  <a href="https://x.com/"><div id="social-background">
                   <img src={imagePath.Green_twitter} className='social'/>
                  </div></a>
                  <a href="https://in.linkedin.com/"> <div id="social-background">
                   <img src={imagePath.Green_Linkedin} className='social'/>
                  </div></a>
                  <a href="https://www.instagram.com/"> <div id="social-background">
                 <img src={imagePath.Green_insta} className='social'/>
                  </div></a>
                </div>

        </div>
        <div className="container">
        <RightSection_Comp
              mainHeading="We're Here to"
              subHeading="Help"
              paragraph="At AngiesHelpers.com, we value communication and are always available to address your inquiries and concerns. Contact us to learn more about our services or to discuss how we can assist you with your property management needs.
"
              btnText="Start Chat"
              image={imagePath.Contact_Help}
              visibleButton
            />
        </div>
    </div>
   <Footer/>
   </>
  )
}

export default ContactUs