import React from "react";
import Navbar from "../../components/Navbar";
import Cards from "../../components/Cards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { imagePath } from "../../constants/imagePath";
import Testimonial from "../../components/Testimonial";
import Landing_Footer from "../../components/Landing_Footer";

const LandingPage_1 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-hero-section">
          <div className="container">
            <div className="landing-page-img">
              <h1 className="fontSize48_white" id="main-text">
                Airbnb or Long-Term Rental? Discover Your Best Option!"
              </h1>
              <p className="fontSize17_white">
                Let our AI-driven analysis maximize your property’s income
                potential.
              </p>
            </div>
          </div>
        </div>

        <div className="why-choose-section-landing-page">
          <div className="container">
            <div className="why-choose-angies-main">
              <div
                className="why-choose-angies-heading"
                id="landing-page-heading"
              >
                <h1 className="fontSize48_black" id="whyChoose-heading">
                  Why choose
                  <span className="fontSize48_green"> AngiesHelpers</span>{" "}
                </h1>
                <p className="fontSize22_gray" id="why-choose-para">
                  Curious about which rental strategy will bring you more
                  income? Our AI compares market data to help you decide between
                  Airbnb and long-term rentals. Get a personalized report today!
                </p>
              </div>
              <div className="landing-grid-container">
                <div className="right-div">
                  <Cards
                    image={imagePath.Airbnb}
                    cardTitle="Airbnb Management"
                    cardDescription="We handle listing optimization, pricing, guest communication, check-ins/outs, cleaning, and maintenance to maximize occupancy and income."
                  />
                </div>
                <div className="right-div">
                  <Cards
                    image={imagePath.Home}
                    cardTitle="Long-Term Rentals"
                    cardDescription="We provide tenant screening, lease management, rent collection, maintenance, and tenant relations to ensure satisfaction and upkeep."
                  />
                </div>
                <div className="last-div">
                  <Cards
                    image={imagePath.Settings}
                    cardTitle="On-Site Assistance"
                    cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
                  />
                </div>
                <div className="left-div">
                  <Cards
                    image={imagePath.Headphone}
                    cardTitle="24/7 Offshore Support"
                    cardDescription="Continuous support for administrative tasks, guest inquiries, booking management, and customer service to enhance efficiency."
                  />
                </div>

                <div className="left-div">
                  <Cards
                    image={imagePath.Socket}
                    cardTitle="Technology Integration"
                    cardDescription="Advanced software for streamlined operations, performance tracking, and transparent reporting, aiding informed decisions."
                  />
                </div>

                <div className="bottom-div">
                  <Cards
                    image={imagePath.Star}
                    cardTitle="Customized Service Packages"
                    cardDescription="Flexible packages tailored to your needs, offering full-service management or select services."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="why-choose-btn">
            <button className="btn-style">Get Start Now</button>
          </div>
        </div>

        <div className="property-detail-section">
          <div className="container">
            <div className="property-detail-form">
              <div className="why-choose-angies-heading">
                <h1 className="fontSize48_black" id="landing-heading">
                  Property <span className="fontSize48_green"> Details</span>{" "}
                </h1>
                <p className="fontSize22_gray">
                  At AngiesHelpers.com, our journey began with a simple mission:
                  to provide property owners with a hassle-free, profitable
                  rental management experience
                </p>
              </div>
              <div className="contact-section" id="property-section">
                <div className="contact-form" id="property-details">
                  <div className="contact-bg-left-card"></div>
                  <div className="form">
                    <form>
                      <div className="mb-4">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Address"
                        />
                      </div>
                      <select
                        class="form-select mb-4"
                        id="property-selection"
                        aria-label="Default select example"
                      >
                        <option selected>Property Type</option>
                        <option value="Apartment">
                          <img src={imagePath.Left_Caret} alt="" /> Apartment
                        </option>
                        <option value="House">
                          <img src={imagePath.Left_Caret} alt="" /> House
                        </option>
                        <option value="Condo">
                          <img src={imagePath.Left_Caret} alt="" /> Condo
                        </option>
                      </select>

                      <select
                        class="form-select mb-4"
                        aria-label="Default select example"
                      >
                        <option selected>Number Of Bedrooms</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>

                      <select
                        class="form-select mb-4"
                        aria-label="Default select example"
                      >
                        <option selected>Number Of Bathrooms</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>

                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Pool
                        </label>
                      </div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label class="form-check-label" for="flexCheckChecked">
                          Garage
                        </label>
                      </div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label class="form-check-label" for="flexCheckChecked">
                          Furnished
                        </label>
                      </div>

                      <button className="btn-style" id="form-button">
                        Income Potential
                      </button>
                    </form>
                  </div>
                  <div className="contact-bg-right-card"></div>
                </div>
                <div className="property-detail-img">
                  <img src={imagePath.Property_Details} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="property-potential-earning">
          <div className="container">
            <div className="testimonials">
              <h1 className="fontSize48_black" id="heading">
                Your Property's{" "}
                <span className="fontSize48_green"> Potential Earnings</span>{" "}
              </h1>
              <p className="fontSize22_gray">
                Unlock the maximum rental income with expert management and
                strategic property enhancement.
              </p>
            </div>
            <p className="fontSize16_black" id="earnings">
              Your property's potential earnings
            </p>
            <div className="potential-earnings">
              <div className="Airbnb-earning">
                <div className="chart-headning">
                  <p className="fontSize16_Green">Airbnb</p>
                </div>
                <div className="chart-image-card" id="Airbnb-card">
                  <img src={imagePath.Chart1} alt="" />

                  <p className="fontSize17_black">Average monthly income</p>
                  <div className="expense-card">
                    <p className="fontSize16_black ">$3,000</p>
                    <h3 className="fontSize24_black">$2,500</h3>
                    <p className="fontSize16_Green">+5%</p>
                  </div>
                </div>
              </div>
              <div className="Airbnb-earning">
                <div className="chart-headning">
                  <p className="fontSize16_bluishGray"></p>Long-term rental
                </div>
                <div className="chart-image-card" id="long-term-card">
                  <img src={imagePath.Chart2} alt="" />
                  <div className="expense-card" id="expenses">
                    <p className="fontSize16_black ">$1,500</p>
                    <h3 className="fontSize24_black">$1,000</h3>
                    <p className="fontSize16_Red">-5%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Get Full Report</button>
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card">
              <h3 className="fontSize20_white">
                Enter Your Email to Receive Your Full Analysis Report
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-testimonials-main-section">
          <div className="container">
            <div className="client-testimonials">
              <div className="testimonials">
                <h1 className="fontSize48_black" id="heading">
                  Client <span className="fontSize48_green"> Testimonials</span>{" "}
                </h1>
                <p className="fontSize22_gray ">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>

              <Slider {...settings}>
                <Testimonial
                  feedback="I couldn't be happier. I use Uplisting daily and it's really helped me turn a corner for my business from managing a few homes (that I could do manually) to many, many more."
                  image={imagePath.Testimonial_1}
                  userName="T. Sanchez"
                  paraId="landing-para"
                  id="landing-para2"
                  place="Scottsdale, AZ"
                />

                <Testimonial
                  feedback="Managing our own vacation rental properties was exhausting until we found AngiesHelpers. They took over the management side, and now we're making more money than ever!"
                  image={imagePath.Testimonial_2}
                  userName="Abram Dorwart"
                  place="Scottsdale, AZ"
                  paraId="landing-para"
                  id="landing-para2"
                />

                <Testimonial
                  feedback="After finding AngiesHelpers, I focus on buying new properties while they handle the rest. I now have the freedom to grow my portfolio without worrying about property management."
                  image={imagePath.Testimonial_3}
                  userName="Dulce Torff"
                  place="Scottsdale, AZ"
                  paraId="landing-para"
                  id="landing-para2"
                />
              </Slider>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section">
          <div className="container">
            <div className="core-values-main ">
              <div className="why-choose-angies-heading">
                <h1 className="fontSize48_black" id="landing-heading">
                  Why <span className="fontSize48_green">Choose Us?</span>{" "}
                </h1>
                <p className="fontSize16_gray ">
                  Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings.
                </p>
              </div>
              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Expert_Analysis}
                  cardTitle="Expert Analysis"
                  cardDescription="Utilize our advanced AI technology to compare rental incomes with unparalleled precision, ensuring you make informed and profitable decisions."
                />

                <Cards
                  image={imagePath.Proven_Strategies}
                  cardTitle="Proven Strategies"
                  cardDescription="Enhance your rental approach with our expertly crafted strategies, specifically designed to help you optimize your property and achieve maximum profit"
                />

                <Cards
                  image={imagePath.Report}
                  cardTitle="Comprehensive Reports"
                  cardDescription="Access detailed and in-depth reports that provide actionable insights, helping you understand your rental market better and make smarter investment choices."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>

        <Landing_Footer />
      </div>
    </>
  );
};

export default LandingPage_1;
