import React from "react";
import { imagePath } from "../constants/imagePath";

const Blog_Card = ({
  topic,
  image,
  information,
  visibleButton,
  btnText,
  detail,
  date,
  calendar,
  btn_info,
  info,

}) => {
  return (
    <>

    <div className="blog-card">
    <img src={image} className="card-img-top" alt="" />
  <div> {detail ? <button className="detail-btn">{btn_info}</button> : ""}</div>
    <div className="blog-subcard">
   
      <h5 className="fontSize24_black_Tiempos" id="topic">
        {topic}
      </h5>

      {calendar ? (
        <span className="fontSize16_DarkGray " id="calendar">
          <img src={imagePath.Calender} /> {date}
        </span>
      ) : (
        ""
      )}


      {info ? (
        <p className="fontSize18_Gray" id="information">
          {information}
        </p>
      ) : (
        ""
      )}


      {visibleButton ? (
        <button className="btn-style" style={{ width: "100%" }}>
          {btnText}
          <i className="fa-solid fa-angle-right"></i>
        </button>
      ) :  <button className="btn-style" style={{ width: "fit-content" }}>
      {btnText}
      <i className="fa-solid fa-angle-right"></i>
    </button>}


    </div>
  </div>

    </>
  );
};

export default Blog_Card;
