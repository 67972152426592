import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RightSection_Comp from "../components/RightSection_Comp";
import HeroSection from "../components/HeroSection";
import { imagePath } from "../constants/imagePath";
import LeftSection_Comp from "../components/LeftSection_Comp";
import Cards from "../components/Cards";

const AboutUs = () => {
  return (
    <>
      <Navbar mainNavbar/>
      <div className="container-fluid">
        <div className="about-main-section">
          <div className="container">
            <HeroSection
              mainHeading="Building Trust, Delivering Excellence"
              paragraph="AngiesHelpers.com specializes in short-term and long-term rental management. Our experts maximize your rental income and ensure guest satisfaction with local expertise and 24/7 offshore support. We offer comprehensive, tailored management solutions."
              btnText="Get Started Now"
              image={imagePath.Hero_Img_2}
            />
          </div>
        </div>
        <div className="container">
          <RightSection_Comp
            mainHeading="How It All"
            subHeading="Started"
            paragraph="At AngiesHelpers.com, we began with a mission to provide hassle-free, profitable rental management."
            image={imagePath.About_Sec_Img_1}
          subParagraph="Founded by industry experts, we combine local knowledge and offshore support to offer exceptional services for both short-term and long-term rentals, ensuring optimized listings and seamless guest experiences."
          />

          <LeftSection_Comp
            mainHeading="Our"
            paragraph="At AngiesHelpers.com, our mission is dedicated to helping property owners maximize their rental income and providing outstanding service to guests."
            subParagraph="We envision a future where property management is seamlessly efficient and highly profitable for our clients, setting new industry standards for ease and rewarding property management experiences."
            subHeading="Mission & Vision"
            image={imagePath.About_Sec_Img_2}
          />
        </div>

        <div className="core-values-section">
            <div className="container">
              <div className="core-values-main ">
                <div className="why-choose-angies-heading">
                  <h1 className="fontSize48_white" id="heading">
                  Core
                    <span className="fontSize48_green">Values</span>{" "}
                  </h1>
                  <p className="fontSize22_white_regular ">
                  Upholding Excellence in Property Management Through Commitment, Integrity, Innovation, and Continuous Improvement
                  </p>
                </div>
                <div className="grid-container" id="core-values">
                  <Cards
                    image={imagePath.Heart}
                    cardTitle="Customer Satisfaction"
                    cardDescription="Ensuring every client receives exceptional service and support, exceeding expectations with every interaction."
                  />

                  <Cards
                    image={imagePath.main_Settings}
                    cardTitle="Integrity"
                    cardDescription="Operating with honesty and transparency in all our business practices, maintaining trust and respect with clients and partners."
                  />

                  <Cards
                    image={imagePath.Rocket}
                    cardTitle="Innovation"
                    cardDescription="Continuously seeking new ideas and technologies to enhance property management efficiency and effectiveness."
                  />

                  <Cards
                    image={imagePath.People}
                    cardTitle="Team Collaboration"
                    cardDescription="Fostering a culture of collaboration and mutual respect among our diverse team, ensuring unified efforts towards common goals."
                  />

                  <Cards
                    image={imagePath.Accountability}
                    cardTitle="Accountability"
                    cardDescription="Taking responsibility for our actions and commitments, delivering results with reliability and professionalism."
                  />

                  <Cards
                    image={imagePath.Light}
                    cardTitle="Continuous Learning"
                    cardDescription="Embracing growth and development through ongoing education and feedback, adapting to industry advancements and client needs."
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
          <div className="meet-our-team">
          <div className="why-choose-angies-heading" id='pricing-heading'>
                  <h1 className="fontSize48_black" id="heading">
                  Meet Our{"  "}
                    <span className="fontSize48_green"> Team</span>{" "}
                  </h1>
                  <p className="fontSize22_gray ">
                  Our expert team at AngiesHelpers.com is dedicated to top-tier service and lasting client relationships. Learn more about the individuals behind our trusted name.
                  </p>
                </div>

                <div className="team-section">
                  <div className="team-grid-container">
                  

                   <div className="team-card">
                   {/* <div className="team-images"> */}
                <div id="team-card"><img src={imagePath.TeamMate1} /></div>
                   {/* </div> */}
                    <p className="fontSize26_Gray ">Martin Gouse</p>
                    <p className="fontSize18_Gray">Founder / CEO</p>
                   </div>

                   <div className="team-card">
                   <div id="team-card"><img src={imagePath.TeamMate2} /></div>
                    <p className="fontSize26_Gray ">Zain Aminoff</p>
                    <p className="fontSize18_Gray">Head of Sales</p>
                   </div>

                   <div className="team-card">
                   <div id="team-card"><img src={imagePath.TeamMate3} /></div>
                    <p className="fontSize26_Gray ">Zaire Bator</p>
                    <p className="fontSize18_Gray">Head of Operations</p>
                   </div>

                   <div className="team-card">
                   <div id="team-card"><img src={imagePath.TeamMate4} /></div>
                    <p className="fontSize26_Gray ">Craig Korsgaard</p>
                    <p className="fontSize18_Gray">Head of Design</p>
                   </div>

                   <div className="team-card">
                   <div id="team-card"><img src={imagePath.TeamMate5} /></div>
                    <p className="fontSize26_Gray ">Tiana Herwitz</p>
                    <p className="fontSize18_Gray">Ui/UX Designer</p>
                   </div>

                  

                   <div className="team-card">
                   <div id="team-card"><img src={imagePath.TeamMate6} /></div>
                    <p className="fontSize26_Gray ">Jaxson Dorwart</p>
                    <p className="fontSize18_Gray">Head of Marketing</p>
                   </div>

                   <div className="team-card">
                   <div id="team-card"><img src={imagePath.TeamMate7} /></div>
                    <p className="fontSize26_Gray ">Rayna Philips</p>
                    <p className="fontSize18_Gray">Operations Lead</p>
                   </div>

                   <div className="team-card">
                   <div id="team-card"><img src={imagePath.TeamMate8} /></div>

                    <p className="fontSize26_Gray ">Skylar Geidt</p>
                    <p className="fontSize18_Gray">Operations Manager</p>
                   </div>
                  </div>
                </div>
          </div>
          </div>

      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
