import React from "react";
import { imagePath } from "../constants/imagePath";
import "../styles/commonStyles.css";
import { Link } from "react-router-dom";

const Navbar = ({
  mainNavbar
}) => {
  return (
    <>
        {
          mainNavbar ?
          <nav className="navbar navbar-expand-lg bg-body-light">
          <div className="container-fluid">
         <div className="container">
      <div className="navbar justify-content-between">
      <Link className="navbar-brand" to="/">
              <img src={imagePath.Logo} alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav  mb-2 mb-lg-0" id="fontSize15_bluishBlack">
                <li className="nav-item" id="fontSize15_bluishBlack">
                  <Link className="nav-link" to="/about">About Us</Link>
                </li>
                <li className="nav-item" id="fontSize15_bluishBlack">
                  <Link className="nav-link" to="/services">Services</Link>
                </li>
                <li className="nav-item" id="fontSize15_bluishBlack">
                  <Link className="nav-link" to="/working">How it works</Link>
                </li>
                <li className="nav-item" id="fontSize15_bluishBlack">
                  <Link className="nav-link" to="/pricing">Pricing</Link>
                </li>
                <li className="nav-item" id="fontSize15_bluishBlack">
                  <Link className="nav-link" to="/testimonials">Testimonials</Link>
                </li>
                <li className="nav-item" id="fontSize15_bluishBlack">
                  <Link className="nav-link" to="/blog">Blog</Link>
                </li>
                <li className="nav-item" id="fontSize15_bluishBlack">
                  <Link className="nav-link" to="/contact">Contact Us</Link>
                </li>
              </ul>
              <div className="d-flex align-items-center gap-2">
                <Link to="/signup" className="nav-link fontSize16_Green">Sign up</Link>
             <Link to="/login"><button className="btn btn-style">Login</button></Link>
              </div>
            </div>
      </div>
         </div>
          </div>
        </nav>

        : 
        <nav className="navbar navbar-expand-lg bg-body-light">
        <div className="container-fluid">
       <div className="container">
    <div className="navbar justify-content-between">
    <Link className="navbar-brand" to="/">
            <img src={imagePath.Logo} alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav  mb-2 mb-lg-0" id="fontSize15_bluishBlack">
            <li className="nav-item" id="fontSize15_bluishBlack">
                <Link className="nav-link" to="/home">Home</Link>
              </li>
              <li className="nav-item" id="fontSize15_bluishBlack">
                <Link className="nav-link" to="/services">Services</Link>
              </li>
              <li className="nav-item" id="fontSize15_bluishBlack">
                <Link className="nav-link" to="/about">About Us</Link>
              </li>
              <li className="nav-item" id="fontSize15_bluishBlack">
                <Link className="nav-link" to="/contact">Contact Us</Link>
              </li>
            </ul>
          
          </div>
    </div>
       </div>
        </div>
      </nav>
        }
    </>
  );
};

export default Navbar;
