import React from "react";

const Heading_Section = ({
  left,
  heading_Black,
  heading_Green,
  subcontent,
  fontSize,
  padding,
  white = true,
  heading_White,
}) => {
  console.log(white,"white>>>>>>>>>>>")
  return (
    <>
      {
      left ? (
        <div
          className="testimonials"
          id="page3-slider"
          style={{ padding: padding }}
        >
     
            <h1 className="fontSize48_black" id="heading">
              {heading_Black}{" "}
              <span className="fontSize48_green">{heading_Green}</span>{" "}
            </h1>

          <p className="fontSize22_gray">{subcontent}</p>
        </div>
      ) : (
        <div className="why-choose-angies-heading" style={{ padding: padding }}>
          {
            white ?
            <h1 className="fontSize48_white" id="landing-heading">
            {heading_White}
            {"  "}
            <span className="fontSize48_green">{heading_Green}</span>{" "}
          </h1>
          :
          <h1 className="fontSize48_black" id="landing-heading">
            {heading_Black}
            {"  "}
            <span className="fontSize48_green">{heading_Green}</span>{" "}
          </h1>
          }
      {fontSize == 16 ? (
  <p className="fontSize16_gray">{subcontent}</p>
) : fontSize == 17 ? (
  <p className="fontSize17_lightGray">{subcontent}</p>
) : fontSize == 20 ? (
  <p className="fontSize20_gray">{subcontent}</p>
): (
  <p className="fontSize22_gray">{subcontent}</p>
)}
        </div>
      )}
    </>
  );
};

export default Heading_Section;
