import React from "react";
import Heading_Section from "./Heading_Section";
import { imagePath } from "../constants/imagePath";

const Image_Text_Comp = ({
  vectorImage,
  image1,
  image2,
  padding,
  subcontent,
  heading_Black,
  heading_white,
  heading_White,
  white,
  vector_green,
  fontSize
}) => {
  return (
    <>
      <div className="hero-section-design">
        <div className="interior-design-1">
          <img src={image1} />
        </div>
        <Heading_Section
        heading_White={heading_White}
          padding={padding}
          white={white}
          heading_white={heading_white}
          heading_Black={heading_Black}
          subcontent={subcontent}
          fontSize={fontSize}
        />
        <div className="vector-design">
         {
          vector_green ?
          <img src={vectorImage} />
          :
          <img src={vectorImage} />
         }
        </div>
        <div className="interior-design-2">
          <img src={image2} />
        </div>
      </div>
    </>
  );
};

export default Image_Text_Comp;
