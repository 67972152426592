import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Landing_Footer from "../../components/Landing_Footer";
import Heading_Section from "../../components/Heading_Section";
import { imagePath } from "../../constants/imagePath";
import Image_Text_Comp from "../../components/Image_Text_Comp";
import Card_With_Heading from "../../components/Card_With_Heading";
import Cards from "../../components/Cards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Date_Input from "../../components/Date_Input";

const LandingPage_7 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-seven">
          <div className="landing-page-seven-section">
            <div className="container">
              <Image_Text_Comp
                white={false}
                fontSize={16}
                image1={imagePath.Interior_Design_5}
                image2={imagePath.Interior_Design_6}
                padding="40px 0px"
                vectorImage={imagePath.Design_vector_yellow}
                heading_Black="Calculate Your Rental Income Potential Instantly!"
                subcontent="Enter your property details and get an accurate rental income estimate."
              />
            </div>
          </div>
        </div>
        <div className="gray-background">
          <div className="container">
            <Card_With_Heading
              heading_black="Why Choose"
              heading_green=" Angies Helpers ? "
              subContent="Providing expert insights and updates on the latest trends in home stay and property management"
            />

            <div className="landing-grid-container" id="landing-page-2-grid">
              <Cards
                image={imagePath.Accuracy}
                cardTitle="Accuracy"
                cardDescription="We handle listing optimization, pricing, guest communication, check-ins/outs, cleaning, and maintenance to maximize occupancy and income."
              />

              <Cards
                image={imagePath.Timer}
                cardTitle="Speed"
                cardDescription="We provide tenant screening, lease management, rent collection, maintenance, and tenant relations to ensure satisfaction and upkeep."
              />

              <Cards
                image={imagePath.Expertise}
                cardTitle="Expertise"
                cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
              />
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Get Start Now</button>
            </div>
          </div>
        </div>

        <div className="choose-your-appointment-section">
          <div className="container">
            <div className="choose-your-appointment">
              <Heading_Section
                white={false}
                heading_Black="Property"
                heading_Green="Details"
                subcontent="At AngiesHelpers.com, our journey began with a simple mission: to provide property owners with a hassle-free, profitable rental management experience"
              />

              <div className="property-detail-section" id="landing-page-5-form">
                <div className="container">
                  <div className="property-detail-form">
                    <div
                      className="property-form-section"
                      id="property-section"
                    >
                      <div id="property-details-form">
                        <div className="property-form-5">
                          <p className="fontSize18_black">
                            Enter Your Property Details Below
                          </p>
                          <form>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Property Address"
                              />
                            </div>

                            <select
                              class="form-select mb-2"
                              id="property-selection"
                              aria-label="Default select example"
                            >
                              <option selected>Property Type</option>
                              <option value="Apartment">
                                <img src={imagePath.Left_Caret} alt="" />{" "}
                                Apartment
                              </option>
                              <option value="House">
                                <img src={imagePath.Left_Caret} alt="" /> House
                              </option>
                              <option value="Condo">
                                <img src={imagePath.Left_Caret} alt="" /> Condo
                              </option>
                            </select>

                            <select
                              class="form-select mb-2"
                              aria-label="Default select example"
                            >
                              <option selected>Number Of Bedrooms</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>

                            <select
                              class="form-select mb-2"
                              aria-label="Default select example"
                            >
                              <option selected>Number Of Bathrooms</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>

                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Square Footage"
                              />
                            </div>

                            <div class="form-check mb-2">
                              <input
                                class="form-check-input "
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                Pool
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Garage
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Furnished
                              </label>
                            </div>

                            <button className="btn-style" id="form-button">
                              Next: Calculate Income
                            </button>
                          </form>
                        </div>
                      </div>
                      <div className="property-image">
                        <img src={imagePath.Property_Details_6} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="property-description-section">
          <div className="container">
            <Heading_Section
              left
              heading_Black="Your Property's "
              heading_Green="Rental Income "
              subcontent="We're working hard to find you the best matches. This may take a few seconds."
            />
            <div className="property-rental-income">
              <div className="property-one" id="rental-income">
                <p className="fontSize15_mediumGray">Spendings</p>
                <h1 className="fontSize32_black_Suisse">Income</h1>
                <img src={imagePath.Bar_Chart_1} />
                <div className="spending-section">
                  <h1 className="fontSize15_Gray">
                    Current margin: April Spendings
                  </h1>
                  <p className="fontSize20_black ">$2,000 - $4,000</p>
                </div>
              </div>
              <div className="property-one" id="rental-income">
                <p className="fontSize15_mediumGray">Spendings</p>
                <h1 className="fontSize32_black_Suisse">Income</h1>
                <img src={imagePath.Bar_Chart_2} />
                <div className="spending-section">
                  <h1 className="fontSize15_Gray">
                    Current margin: April Spendings
                  </h1>
                  <p className="fontSize20_black ">$2,000 - $4,000</p>
                </div>
              </div>
            </div>

            <div className="why-choose-btn">
              <button className="btn-style">Request More Info</button>
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div id="enter-email-card-6">
              <h3 className="fontSize20_white">
                Enter Your Email to Receive Your Full Rental Income Report
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-testimonials-main-section">
          <div className="container">
            <div className="client-testimonials" id="landing-page-7-slide">
              <div className="testimonials">
                <h1 className="fontSize48_black" id="heading">
                  What Our <span className="fontSize48_green">Client Says</span>{" "}
                </h1>
                <p className="fontSize22_gray ">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>

              <Slider {...settings}>
                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div className="testimonial-user">
                    <img src={imagePath.Client_9} alt="" />
                    <div className="user-name d-flex flex-column gap-1">
                      <p className="fontSize16_mediumGray  d-flex gap-1 mb-2">
                        “Lobortis leo pretium facilisis amet nisl at nec.
                        Scelerisque risus tortor donec ipsum consequat semper
                        consequat adipiscing ultrices.”
                      </p>
                      <b>
                        {" "}
                        <h3 className="fontSize18_Green">Brian Baker</h3>
                      </b>
                      <h5 className="fontSize13_Gray m-0">Developer</h5>
                    </div>
                  </div>
                </div>

                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div className="testimonial-user">
                    <img src={imagePath.Client_10} alt="" />
                    <div className="user-name d-flex flex-column gap-1">
                      <p className="fontSize16_mediumGray  d-flex gap-1 mb-2">
                        “Lobortis leo pretium facilisis amet nisl at nec.
                        Scelerisque risus tortor donec ipsum consequat semper
                        consequat adipiscing ultrices.”
                      </p>
                      <b>
                        {" "}
                        <h3 className="fontSize18_Green">Brian Baker</h3>
                      </b>
                      <h5 className="fontSize13_Gray m-0">Developer</h5>
                    </div>
                  </div>
                </div>

                <div
                  className="testimonials-card"
                  id="landing-testimonials-card"
                >
                  <div className="testimonial-user">
                    <img src={imagePath.Client_9} alt="" />
                    <div className="user-name d-flex flex-column gap-1">
                      <p className="fontSize16_mediumGray  d-flex gap-1 mb-2">
                        “Lobortis leo pretium facilisis amet nisl at nec.
                        Scelerisque risus tortor donec ipsum consequat semper
                        consequat adipiscing ultrices.”
                      </p>
                      <b>
                        {" "}
                        <h3 className="fontSize18_Green">Brian Baker</h3>
                      </b>
                      <h5 className="fontSize13_Gray m-0">Developer</h5>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section" id="why-choose-us">
          <div className="container" id="landing-page-7-container">
            <div className="core-values-main ">
              <Heading_Section
                white={false}
                heading_Black="Why Use Our"
                heading_Green=" Rental Income Calculator?"
                subcontent="Unlock your property's full income potential with our AI-driven analysis, providing precision insights to maximize your rental earnings."
              />

              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Estimation}
                  cardTitle="Accurate Estimates"
                  cardDescription="Utilize our advanced AI technology to compare rental incomes with unparalleled precision, ensuring you make informed and profitable decisions."
                />

                <Cards
                  image={imagePath.Timer}
                  cardTitle="Quick and Easy"
                  cardDescription="Enhance your rental approach with our expertly crafted strategies, specifically designed to help you optimize your property and achieve maximum profit"
                />

                <Cards
                  image={imagePath.Report}
                  cardTitle="Comprehensive Reports"
                  cardDescription="Access detailed and in-depth reports that provide actionable insights, helping you understand your rental market better and make smarter investment choices."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <Landing_Footer />
    </>
  );
};

export default LandingPage_7;
