// import React from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import HeroSection from "../components/HeroSection";
// import { imagePath } from "../constants/imagePath";
// import Right_Step_Comp from "../components/Right_Step_Comp";
// import Left_Step_Comp from "../components/Left_Step_Comp";

// const Work = () => {
//   return (
//     <>
//       <Navbar mainNavbar/>
//       <div className="container-fluid">
//         <div className="about-main-section">
//           <div className="container">
//             <HeroSection
//               mainHeading="Effortless Property Management"
//               paragraph="Managing your property with AngiesHelpers.com is simple and straightforward. Our streamlined approach ensures professional service from consultation to ongoing management."
//               btnText="Get Started Now"
//               image={imagePath.Hero_Img_4}
//             />
//           </div>
//         </div>

//         <div className="pricing-container">
//           <div className="why-choose-angies-heading" id="pricing-heading">
//             <h1 className="fontSize48_black" id="heading">
//               Simple Steps to
//               <span className="fontSize48_green"> Maximum Income</span>{" "}
//             </h1>
//             <p className="fontSize22_gray ">
//               At AngiesHelpers.com, our streamlined process ensures efficiency
//               and maximizes your rental income, handling everything from initial
//               contact to daily management. Here’s how our process works.
//             </p>
//           </div>
//         </div>

//         <div className="container">
//         <div className="income-step-section">
//         <Right_Step_Comp
//             image={imagePath.Income_Step_1}
//             heading="Initial Consultation"
//             paragraph="The first step in our process is an initial consultation. During this meeting, we discuss your property management needs, answer any questions you may have, and provide an overview of our services. This consultation helps us understand your goals and tailor our services to meet your specific requirements."
//           />

//           <Left_Step_Comp
//           image={imagePath.Income_Step_2}
//           heading="Onboarding"
//           paragraph="Once you decide to work with us, we begin the onboarding process. This includes gathering all necessary information about your property, setting up your listing on various platforms, and ensuring that all legal and compliance requirements are met. Our team works efficiently to get your property ready for guests as quickly as possible."
//           />

// <Right_Step_Comp
//             image={imagePath.Income_Step_3}
//             heading="Listing Optimization"
//             paragraph="We optimize your property listing to attract the maximum number of guests. This includes professional photography, compelling descriptions, and strategic pricing. Our goal is to make your property stand out in the crowded rental market and attract high-quality guests."
//           />

//           <Left_Step_Comp
//           image={imagePath.Income_Step_4}
//           heading="Dynamic Pricing"
//           paragraph="Our dynamic pricing strategy adjusts your rental rates based on market demand, seasonality, and local events. This ensures that you maximize your rental income while maintaining high occupancy rates. Our advanced software continuously monitors market trends to provide the best pricing for your property."
//           />

// <Right_Step_Comp
//             image={imagePath.Income_Step_5}
//             heading="Guest Communication"
//             paragraph="We handle all guest communication, from inquiries and bookings to check-ins and check-outs. Our team is available 24/7 to answer questions, resolve issues, and ensure that your guests have a positive experience. We provide detailed information to guests about your property and the local area to enhance their stay."
//           />

//           <Left_Step_Comp
//           image={imagePath.Income_Step_6}
//           heading="Property Maintenance"
//           paragraph="Regular maintenance is crucial to keeping your property in top condition. Our team handles all maintenance tasks, including cleaning, repairs, and inspections. We work with trusted local professionals to ensure that your property is always well-maintained and ready for guests."
//           />

// <Right_Step_Comp
//             image={imagePath.Income_Step_7}
//             heading="Reporting and Analytics"
//             paragraph="We provide detailed reports and analytics to keep you informed about your property's performance. This includes occupancy rates, rental income, guest feedback, and maintenance activities. Our transparent reporting helps you make informed decisions about your property and ensures that you are always in control."
//           />

//           <Left_Step_Comp
//           image={imagePath.Income_Step_8}
//           heading="Ongoing Support"
//           paragraph="Our support doesn’t end after the initial setup. We provide continuous support to ensure that your property runs smoothly. Our team is always available to answer questions, resolve issues, and provide assistance whenever you need it. We are committed to your success and work tirelessly to help you achieve your property management goals."
//           />
//         </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Work;


import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import { imagePath } from "../constants/imagePath";
import Right_Step_Comp from "../components/Right_Step_Comp";
import Left_Step_Comp from "../components/Left_Step_Comp";
import "./Test.css"

const Work = () => {
  return (
    <>
      <Navbar mainNavbar />
      <div className="container-fluid">
        <div className="about-main-section">
          <div className="container">
            <HeroSection
              mainHeading="Effortless Property Management"
              paragraph="Managing your property with AngiesHelpers.com is simple and straightforward. Our streamlined approach ensures professional service from consultation to ongoing management."
              btnText="Get Started Now"
              image={imagePath.Hero_Img_4}
            />
          </div>
        </div>

        <div className="pricing-container">
          <div className="why-choose-angies-heading" id="pricing-heading">
            <h1 className="fontSize48_black" id="heading">
              Simple Steps to
              <span className="fontSize48_green"> Maximum Income</span>{" "}
            </h1>
            <p className="fontSize22_gray ">
              At AngiesHelpers.com, our streamlined process ensures efficiency
              and maximizes your rental income, handling everything from initial
              contact to daily management. Here’s how our process works.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="income-step-section">
            <div className="row align-items-center how-it-works">
              <div className="col-2 text-center bottom">
                <Right_Step_Comp
                  image={imagePath.Income_Step_1}
                  heading="Initial Consultation"
                  paragraph="The first step in our process is an initial consultation. During this meeting, we discuss your property management needs, answer any questions you may have, and provide an overview of our services. This consultation helps us understand your goals and tailor our services to meet your specific requirements."
                />
              </div>
              <div className="col-6">
                {/* Your timeline content */}
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner top-right"></div>
              </div>
              <div className="col-8">
                <hr />
              </div>
              <div className="col-2">
                <div className="corner left-bottom"></div>
              </div>
            </div>

            <div className="row align-items-center justify-content-end how-it-works">
              <div className="col-6 text-right">
                {/* Your timeline content */}
              </div>
              <div className="col-2 text-center full">
                <Left_Step_Comp
                  image={imagePath.Income_Step_2}
                  heading="Onboarding"
                  paragraph="Once you decide to work with us, we begin the onboarding process. This includes gathering all necessary information about your property, setting up your listing on various platforms, and ensuring that all legal and compliance requirements are met. Our team works efficiently to get your property ready for guests as quickly as possible."
                />
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner right-bottom"></div>
              </div>
              <div className="col-8">
                <hr />
              </div>
              <div className="col-2">
                <div className="corner top-left"></div>
              </div>
            </div>

            <div className="row align-items-center how-it-works">
              <div className="col-2 text-center top">
                <Right_Step_Comp
                  image={imagePath.Income_Step_3}
                  heading="Listing Optimization"
                  paragraph="We optimize your property listing to attract the maximum number of guests. This includes professional photography, compelling descriptions, and strategic pricing. Our goal is to make your property stand out in the crowded rental market and attract high-quality guests."
                />
              </div>
              <div className="col-6">
                {/* Your timeline content */}
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner top-right"></div>
              </div>
              <div className="col-8">
                <hr />
              </div>
              <div className="col-2">
                <div className="corner left-bottom"></div>
              </div>
            </div>

            <div className="row align-items-center justify-content-end how-it-works">
              <div className="col-6 text-right">
                {/* Your timeline content */}
              </div>
              <div className="col-2 text-center full">
                <Left_Step_Comp
                  image={imagePath.Income_Step_4}
                  heading="Dynamic Pricing"
                  paragraph="Our dynamic pricing strategy adjusts your rental rates based on market demand, seasonality, and local events. This ensures that you maximize your rental income while maintaining high occupancy rates. Our advanced software continuously monitors market trends to provide the best pricing for your property."
                />
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner right-bottom"></div>
              </div>
              <div className="col-8">
                <hr />
              </div>
              <div className="col-2">
                <div className="corner top-left"></div>
              </div>
            </div>

            <div className="row align-items-center how-it-works">
              <div className="col-2 text-center top">
                <Right_Step_Comp
                  image={imagePath.Income_Step_5}
                  heading="Guest Communication"
                  paragraph="We handle all guest communication, from inquiries and bookings to check-ins and check-outs. Our team is available 24/7 to answer questions, resolve issues, and ensure that your guests have a positive experience. We provide detailed information to guests about your property and the local area to enhance their stay."
                />
              </div>
              <div className="col-6">
                {/* Your timeline content */}
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner top-right"></div>
              </div>
              <div className="col-8">
                <hr />
              </div>
              <div className="col-2">
                <div className="corner left-bottom"></div>
              </div>
            </div>

            <div className="row align-items-center justify-content-end how-it-works">
              <div className="col-6 text-right">
                {/* Your timeline content */}
              </div>
              <div className="col-2 text-center full">
                <Left_Step_Comp
                  image={imagePath.Income_Step_6}
                  heading="Property Maintenance"
                  paragraph="Regular maintenance is crucial to keeping your property in top condition. Our team handles all maintenance tasks, including cleaning, repairs, and inspections. We work with trusted local professionals to ensure that your property is always well-maintained and ready for guests."
                />
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner right-bottom"></div>
              </div>
              <div className="col-8">
                <hr />
              </div>
              <div className="col-2">
                <div className="corner top-left"></div>
              </div>
            </div>

            <div className="row align-items-center how-it-works">
              <div className="col-2 text-center top">
                <Right_Step_Comp
                  image={imagePath.Income_Step_7}
                  heading="Reporting and Analytics"
                  paragraph="We provide detailed reports and analytics to keep you informed about your property's performance. This includes occupancy rates, rental income, guest feedback, and maintenance activities. Our transparent reporting helps you make informed decisions about your property and ensures that you are always in control."
                />
              </div>
              <div className="col-6">
                {/* Your timeline content */}
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner top-right"></div>
              </div>
              <div className="col-8">
                <hr />
              </div>
              <div className="col-2">
                <div className="corner left-bottom"></div>
              </div>
            </div>

            <div className="row align-items-center justify-content-end how-it-works">
              <div className="col-6 text-right">
                {/* Your timeline content */}
              </div>
              <div className="col-2 text-center full">
                <Left_Step_Comp
                  image={imagePath.Income_Step_8}
                  heading="Ongoing Support"
                  paragraph="Our support doesn’t end after the initial setup. We provide continuous support to ensure that your property runs smoothly. Our team is always available to answer questions, resolve issues, and provide assistance whenever you need it. We are committed to your success and work tirelessly to help you achieve your property management goals."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Work;





