import React from 'react';

import styles from './PrivacyPolicy.module.css';


const PolicySection = ({ title, content,subcontent }) => (

  <>

    <h2 className={styles.sectionTitle}>{title}</h2>

    <div className={styles.sectionContent}>{content}</div>
    <div className={styles.sectionContent}>{subcontent}</div>
  </>

);


export default PolicySection;