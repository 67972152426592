import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import { imagePath } from "../constants/imagePath";
import Blog_Card from "../components/Blog_Card";
import { Link } from "react-router-dom";
import Blog_Card_Horizontal from "../components/Blog_Card_Horizontal";
import Blog_Card_Vertical from "../components/Blog_Card_Vertical";

const Blog = () => {
  const industrSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // screens smaller than 1024px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // screens smaller than 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // screens smaller than 1024px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // screens smaller than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar mainNavbar/>
      <div className="container-fluid">
        <div className="blog-main-section">
          <div className="container">
            <HeroSection
              mainHeading="Expert Tips and Advice"
              paragraph="At AngiesHelpers.com, we are committed to helping property owners succeed. Our blog is packed with expert tips and advice on all aspects of property management. Whether you're new to the rental market or a seasoned pro, our articles provide valuable insights to help you achieve your goals."
              btnText="Get Started Now"
              image={imagePath.Hero_Img_7}
            />
          </div>
        </div>
        <div className="container">
          <div className="pricing-container">
            <div className="why-choose-angies-heading" id="pricing-heading">
              <h1 className="fontSize48_black" id="heading">
                Blogs & <span className="fontSize48_green">Articles</span>{" "}
              </h1>
              <p className="fontSize22_gray ">
                Informative Articles, Tips, and Industry News on Property
                Management, Airbnb, and Long-Term Rentals
              </p>
            </div>
          </div>

          <div className="container">
            <div className="blog-section">
              <Slider {...settings}>
                <Blog_Card
                  vertical
                  image={imagePath.Blog_Img_1}
                  topic="Latest Trends in Property Management"
                  btnText="Read More"
                  detail
                  ver
                  calendar
                  date="June 17, 2014"
                  btn_info="Property Management"
                />

                <Blog_Card
                  vertical
                  image={imagePath.Blog_Img_2}
                  topic="Ultimate Guide to Successful Property Management"
                  btnText="Read More"
                  detail
                  calendar
                  date="May 26, 2024"
                  btn_info="Stay Informed"
                />

                <Blog_Card
                  vertical
                  image={imagePath.Blog_Img_3}
                  topic="Maximizing Your Airbnb Income: Tips and Tricks"
                  btnText="Read More"
                  detail
                  calendar
                  date="June 28, 2024"
                  btn_info="Tips & Tricks"
                />
              </Slider>
            </div>
          </div>
        </div>

        <div className="tips_main_section">
          <div className="container">
            <div className="tips_guides">
              <div className="tips-section" id="tips-heading">
                <span id="tips">
                  <h1 className="fontSize48_black">
                    Tips and <span className="fontSize48_green">Guides</span>{" "}
                  </h1>
                  <p className="fontSize22_gray ">
                    Informative Articles, Tips, and Industry News on Property
                    Management, Airbnb, and Long-Term Rentals
                  </p>
                </span>
                <span className="view">
                  <Link to="">
                    <button className="view-btn">
                      View More <i className="fa-solid fa-angle-right"></i>{" "}
                    </button>
                  </Link>
                </span>
              </div>
            </div>

            <div className="tips_guide_section">
              <Blog_Card_Horizontal
                image={imagePath.Regular_Maintanence}
                topic=" Regular Maintenance and Inspections"
                info
                detail
                visibleButton
                left
                calendar
                btn_info="Tips and Trick"
                date="June 17, 2014"
                btnText="Read More"
                information="Informative Articles, Tips, and Industry News on Property Management, Airbnb, and Long-Term Rentals"
              />

              <Blog_Card_Horizontal
                image={imagePath.Financial_Planning}
                topic="Financial Planning and Budgeting
"
                visibleButton
                info
                detail
                calendar
                btn_info="Tips and Trick"
                date="June 17, 2014"
                btnText="Read More"
                information="Develop a comprehensive budget that includes all potential expenses, such as mortgage payments, insurance, property taxes, and maintenance costs."
              />

              <Blog_Card_Horizontal
                image={imagePath.Tenant_Management}
                topic="Tenant Management"
                info
                visibleButton
                detail
                left
                calendar
                btn_info="Tips and Trick"
                date="June 17, 2014"
                btnText="Read More"
                information="Conduct background checks, credit checks, and reference checks to ensure reliable tenants.
Clearly outline tenant responsibilities and expectations in the lease agreement.
"
              />
            </div>
          </div>
        </div>

        {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
        <div className="pricing-container">
          <div className="why-choose-angies-heading" id="pricing-heading">
            <h1 className="fontSize48_black" id="heading">
              Industry <span className="fontSize48_green">News</span>{" "}
            </h1>
            <p className="fontSize22_gray ">
              Our blog is your go-to source for expert tips, industry news, and
              practical advice on property management.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="industry-slider">
            <Slider {...industrSettings}>
              {/* <<<<<<<<<<Slide 1>>>>>>>>>>> */}
              <div className="industry-news-section">
                <div className="industry-blog-card">
                  <div>
                    <img
                      src={imagePath.Industy_Img}
                      className="card-img-top"
                      alt=""
                    />
                  </div>
                  <div className="blog-content-card">
                    <div className="date-card">
                      {/* {detail ? <button className="detail-btn">{btn_info}</button> : ""} */}

                      <span className="fontSize16_DarkGray " id="calendar">
                        <img src={imagePath.Calender} />
                        June 17, 2014{" "}
                      </span>
                    </div>
                    <div className="blog-subcard">
                      <h5 className="fontSize24_black_Tiempos">
                        Airbnb's Financial Performance
                      </h5>

                      <p className="fontSize18_Gray">
                        Airbnb has reported strong financial results in recent
                        quarters, driven by a surge in travel demand. The
                        company has seen significant growth in both bookings and
                        revenue, highlighting the ongoing recovery of the travel
                        industry post-pandemic.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tips_guide_section" id="industry-tips">
                  <Blog_Card_Vertical
                    image={imagePath.New_Features}
                    topic="New Features and Services"
                    info
                    left
                    calendar
                    date="June 17, 2014"
                    information="Airbnb continues to innovate with new features and service"
                  />

                  <Blog_Card_Vertical
                    image={imagePath.Innovation}
                    topic="Technology and Innovation"
                    info
                    left
                    calendar
                    date="June 17, 2014"
                    information="Investment in technology continues to be a key focus, with advancements in AI and machine learning."
                  />

                  <Blog_Card_Vertical
                    image={imagePath.Sustainability}
                    topic="Sustainability Initiatives"
                    info
                    left
                    calendar
                    date="June 17, 2014"
                    information="Airbnb has launched initiatives to promote sustainable travel, encouraging eco-friendly .
"
                  />
                </div>
              </div>

              {/* <<<<<<<<<<Slide 2>>>>>>>>>>> */}
              <div className="industry-news-section">
                <div className="industry-blog-card">
                  <div>
                    <img
                      src={imagePath.Industy_Img}
                      className="card-img-top"
                      alt=""
                    />
                  </div>
                  <div className="blog-content-card">
                    <div className="date-card">
                      <span className="fontSize16_DarkGray " id="calendar">
                        <img src={imagePath.Calender} />
                        June 17, 2014{" "}
                      </span>
                    </div>
                    <div className="blog-subcard">
                      <h5 className="fontSize24_black_Tiempos">
                        Airbnb's Financial Performance
                      </h5>

                      <p className="fontSize18_Gray">
                        Airbnb has reported strong financial results in recent
                        quarters, driven by a surge in travel demand. The
                        company has seen significant growth in both bookings and
                        revenue, highlighting the ongoing recovery of the travel
                        industry post-pandemic.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tips_guide_section" id="industry-tips">
                  <Blog_Card_Vertical
                    image={imagePath.New_Features}
                    topic="New Features and Services"
                    info
                    left
                    calendar
                    date="June 17, 2014"
                    information="Airbnb continues to innovate with new features and service"
                  />

                  <Blog_Card_Vertical
                    image={imagePath.Innovation}
                    topic="Technology and Innovation"
                    info
                    left
                    calendar
                    date="June 17, 2014"
                    information="Investment in technology continues to be a key focus, with advancements in AI and machine learning."
                  />

                  <Blog_Card_Vertical
                    image={imagePath.Sustainability}
                    topic="Sustainability Initiatives"
                    info
                    left
                    calendar
                    date="June 17, 2014"
                    information="Airbnb has launched initiatives to promote sustainable travel, encouraging eco-friendly .
"
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>

        {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
        <div className="tips_main_section">
          <div className="container">
            <div className="tips_guides">
              <div className="tips-section" id="tips-heading">
                <span id="tips">
                  <h1 className="fontSize48_black">
                    Company <span className="fontSize48_green">Updates</span>{" "}
                  </h1>
                  <p className="fontSize22_gray ">
                    Welcome to the AngiesHelpers.com blog, your go-to source for
                    expert insights and updates on property management
                  </p>
                </span>
              </div>
            </div>

            <div className="tips_guide_section">
              <Blog_Card_Horizontal
                image={imagePath.Regular_Maintanence}
                topic="New Partnerships with Local Cleaning Services"
                info
                detail
                left
                calendar
                visibleButton
                btn_info="Updates"
                date="June 17, 2014"
                btnText="Read More"
                information="We are excited to announce new partnerships with several local cleaning services to ensure our properties are maintained to the highest standards."
              />

              <Blog_Card_Horizontal
                image={imagePath.Financial_Planning}
                topic=" Introduction of Smart Home Technology"
                info
                left
                visibleButton
                detail
                calendar
                btn_info="Updates"
                date="June 17, 2014"
                btnText="Read More"
                information="To elevate the guest experience, we have started integrating smart home technology into our properties."
              />

              <Blog_Card_Horizontal
                image={imagePath.Tenant_Management}
                topic="Enhanced Host Support Services"
                info
                detail
                visibleButton
                left
                calendar
                btn_info="Updates"
                date="June 17, 2014"
                btnText="Read More"
                information="We understand the importance of support for our hosts. Therefore, we have bolstered our host support services with 24/7 customer service, dedicated account managers,
"
              />
            </div>
          </div>
        </div>
        <div className="blog-btn">
          <button className="load-more-btn">Load More</button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
