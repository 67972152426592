import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar";
import Testimonial_Video from "../components/testimonial_video";
import Footer from "../components/Footer";
import { imagePath } from "../constants/imagePath";
import Testimonial from "../components/Testimonial";
import Blog_Card from "../components/Blog_Card";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // screens smaller than 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // screens smaller than 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Navbar mainNavbar/>
      <div className="container-fluid">
        <div className="testimonial-background">
        {/* <div className="contact-heading-section"> */}
        <div className="container">
          <h1 className='fontSize55_black'>Testimonials</h1>
        </div>
      {/* </div> */}
        </div>
        <div className="pricing-container">
          <div className="why-choose-angies-heading" id="pricing-heading">
            <h1 className="fontSize48_black" id="heading">
              What They Are <span className="fontSize48_green">Saying</span>{" "}
            </h1>
            <p className="fontSize22_gray ">
              Hear from our satisfied clients about their positive experiences
              with AngiesHelpers.com. Discover how we've helped manage their
              properties effectively and enhanced their rental income.
            </p>
          </div>
        
        </div>
        <Testimonial_Video />
        <div className="client-testimonials-main-section">
          <div className="container">
            <div className="client-testimonials">
              <div className="testimonials">
                <h1 className="fontSize48_white" id="heading">
                  Client <span className="fontSize48_green"> Testimonials</span>{" "}
                </h1>
                <p className="fontSize22_white">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>

              <Slider {...settings}>
                <Testimonial
                  feedback="I couldn't be happier. I use Uplisting daily and it's really helped me turn a corner for my business from managing a few homes (that I could do manually) to many, many more."
                  image={imagePath.Testimonial_1}
                  userName="T. Sanchez"
                  place="Scottsdale, AZ"
                />

                <Testimonial
                  feedback="Managing our own vacation rental properties was exhausting until we found AngiesHelpers. They took over the management side, and now we're making more money than ever!"
                  image={imagePath.Testimonial_2}
                  userName="Abram Dorwart"
                  place="Scottsdale, AZ"
                />

                <Testimonial
                  feedback="After finding AngiesHelpers, I focus on buying new properties while they handle the rest. I now have the freedom to grow my portfolio without worrying about property management."
                  image={imagePath.Testimonial_3}
                  userName="Dulce Torff"
                  place="Scottsdale, AZ"
                />
              </Slider>
            </div>
          </div>
        </div>

        <div className="pricing-container">
          <div className="why-choose-angies-heading" id="pricing-heading">
            <h1 className="fontSize48_black" id="heading">
              Case <span className="fontSize48_green">Studies </span>{" "}
            </h1>
            <p className="fontSize22_gray ">
              Explore our competitive, transparent pricing options tailored to
              your property management needs. Choose from a range of affordable
              plans to maximize value.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="blog-grid-container">
            <Blog_Card
              image={imagePath.caseStudies_Img_1}
              topic="Maximizing Rental Income in Miami Beach"
              information="Discover how AngiesHelpers.com helped the Smith family achieve a 30% increase in rental income through strategic pricing, enhanced marketing, and exceptional guest services."
              btnText="Read Full case Study"
              visibleButton
              info
            />

            <Blog_Card
              image={imagePath.caseStudies_Img_2}
              topic="Marketing Mastery for the Rodriguez Property in San Francisco"
              information="See how AngiesHelpers.com implemented effective marketing strategies to elevate the Rodriguez property's visibility and rental bookings in San Francisco."
              btnText="Read Full case Study"
              visibleButton
              info
            />

            <Blog_Card
              image={imagePath.caseStudies_Img_3}
              topic="Renovating Success for the Johnsons in Los Angeles"
              information="Explore how AngiesHelpers.com streamlined operations for the Park family's rental property in New York City, ensuring efficiency and guest satisfaction."
              btnText="Read Full case Study"
              visibleButton
              info
            />

            <Blog_Card
              image={imagePath.caseStudies_Img_4}
              topic="Enhanced Guest Experiences for the Patel Residence in Orlando"
              information="Discover how AngiesHelpers.com helped the Smith family achieve a 30% increase in rental income through strategic pricing, enhanced marketing, and exceptional guest services."
              btnText="Read Full case Study"
              visibleButton
              info
            />

            <Blog_Card
              image={imagePath.caseStudies_Img_5}
              topic="Seamless Management for the Park Family in New York City"
              information="Explore how AngiesHelpers.com streamlined operations for the Park family's rental property in New York City, ensuring efficiency and guest satisfaction."
              btnText="Read Full case Study"
              visibleButton
              info
            />

            <Blog_Card
              image={imagePath.caseStudies_Img_6}
              topic="Legal Support and Resolution for the Thompsons in Chicago"
              information="Learn how AngiesHelpers.com provided legal support and resolved disputes promptly for the Thompsons' property in Chicago, ensuring peace of mind and compliance."
              btnText="Read Full case Study"
              visibleButton
              info
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Testimonials;

