import React from 'react'
import { imagePath } from '../constants/imagePath'
import useScrollToTop from '../constants/useScrollToTop';

const Landing_Footer = () => {


  useScrollToTop(); 
  return (
   <>
   <div className="landing-footer-section">
    <div className="container">
        <div className="landing-footer">
        <img src={imagePath.Logo}/>
        <hr className='landing-footer-line'/>
       <h4 className='fontSize20_white'>Contact Info</h4>
       <div className="contact-info">
        <span>
            <img src={imagePath.Green_Call}/>
          <a href=""><p className='fontSize14_OffWhite'>1-548-634-5896</p></a>
        </span>
        <span>
            <img src={imagePath.Green_Email}/>
           <a href=""> <p className='fontSize14_OffWhite'>angieshelpers@gmail.com</p></a>
        </span>
        <span>
            <img src={imagePath.Green_Location}/>
        <a href=""><p className='fontSize14_OffWhite'>2464 Royal Ln. Mesa, New Jersey </p></a>
        </span>
       </div>

       <div className="social-network-links" id='landing-social-links'>
                <a href="https://www.facebook.com/"> <div className="landing-page-social">
                   <img src={imagePath.G_fb} />
                  </div></a>
                  <a href="https://x.com/"> <div className="landing-page-social">
                   <img src={imagePath.G_twitter} />
                  </div></a>
                  <a href="https://in.linkedin.com/"><div className="landing-page-social">
                    <img src={imagePath.G_Linkedin} />
                  </div></a>
                  <a href="https://www.instagram.com/"><div className="landing-page-social">
                 <img src={imagePath.G_insta} />
                  </div></a>
                </div>
                
        </div>
    </div>
   
   </div>
   <div className="landing-copyright-section">
        <div className="container">
            <div className="landing-footer-copyright">
                <div>
               <h5 className='fontSize14_OffWhite'>Copyright  © 2024 AgiesHelpers. All rights reserved.</h5>
            </div>
            <div className='landing-footer-last-section'>
                <p className='fontSize14_OffWhite'>Privacy Policy</p>
                <p className='fontSize14_OffWhite'>Terms of Service</p>
            </div>
            </div>
        </div>
    </div>
   </>
  )
}

export default Landing_Footer