import React from 'react';

import FAQItem from './FAQItem';

import styles from './FAQComponent.module.css';


const FAQSection = ({ title, faqs,subTitle }) => {

  return (

    <>

<h1 className="fontSize32_black" id="heading">
                  {title}{"  "}
                    <span className="fontSize32_green">{subTitle}</span>{" "}
                  </h1>

      <div className={styles.faqContainer}>

        {faqs.map((faq, index) => (

          <FAQItem key={index} {...faq} />

        ))}

      </div>

    </>

  );

};


export default FAQSection;