import React from "react";
import Landing_Footer from "../../components/Landing_Footer";
import Navbar from "../../components/Navbar";
import { imagePath } from "../../constants/imagePath";
import RightSection_Comp from "../../components/RightSection_Comp";
import Heading_Section from "../../components/Heading_Section";
import Testimonial from "../../components/Testimonial";
import Testimonial_video from "../../components/testimonial_video";
import Included_Checklist from "../../components/Included_Checklist";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cards from "../../components/Cards";
import LeftSection_Comp from "../../components/LeftSection_Comp";
import Card_With_Heading from "../../components/Card_With_Heading";
import { Link } from "react-router-dom";

const LandingPage_12 = () => {
  var imageSettings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const settings = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    arrow: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const testimonial_data = [
    {
      id: 1,
      title: "District Directives Representative",
      userName: "Nick Weimann",
      feedback:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: imagePath.Client_13_1,
    },
    {
      id: 1,
      title: "District Directives Representative",
      userName: "Nick Weimann",
      feedback:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: imagePath.Client_13_1,
    },
    {
      id: 1,
      title: "District Directives Representative",
      userName: "Nick Weimann",
      feedback:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: imagePath.Client_13_1,
    },
  ];

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="landing-page-tweleve-section">
          <div className="container">
            <div className="streamline-operations">
              <h1 className="fontSize50_white">
                Streamline Your Rental Operations with Our Property Management
                Checklist!
              </h1>
              <p className="fontSize17_white ">
                Download our comprehensive checklist to stay organized and
                efficient.
              </p>
            </div>
          </div>
        </div>

        <div className="gray-background">
          <div className="container">
            <Card_With_Heading
              heading_black="Maximize Returns with"
              heading_green=" Expert Strategies"
              subContent="Providing expert insights and updates on the latest trends in home stay and property management"
            />

            <div className="landing-grid-container" id="landing-page-2-grid">
              <Cards
                image={imagePath.Advocate}
                cardTitle="Expert Tips"
                cardDescription="We handle listing optimization, pricing, guest communication, check-ins/outs, cleaning, and maintenance to maximize occupancy and income."
              />

              <Cards
                image={imagePath.Thumbs_up}
                cardTitle="Best Practices"
                cardDescription="We provide tenant screening, lease management, rent collection, maintenance, and tenant relations to ensure satisfaction and upkeep."
              />

              <Cards
                image={imagePath.Informed_Decision}
                cardTitle="Informed Decisions"
                cardDescription="Our local experts offer hands-on support for maintenance, inspections, and guest interactions, ensuring smooth operations."
              />
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Get Start Now</button>
            </div>
          </div>
        </div>

        <div className="lease-generation-section">
          <div className="container">
            <div className="checklist-section" id="lease-generation-slide">
              <Heading_Section
                white={false}
                heading_Black="Preview What's  "
                heading_Green="Inside"
                fontSize="20"
                subcontent="Discover essential tips and tasks to ensure everything is in order for a smooth and successful stay. From preparation to guest experience, our checklist has you covered"
              />
              <div className="container mb-5">
                <Slider {...imageSettings}>
                  <div className="lease-generation" id="image-preview">
                    <img
                      src={imagePath.Preview_Image_1}
                      className="w-100 rounded-3 mb-2"
                    />

                    <div className="py-2 px-3">
                      <p className="fontSize20_black_semibold">
                        Lease Generation
                      </p>

                      <p className="fontSize13_LightGray">
                        Our guide includes step-by-step instructions, best
                        practices, and expert tips to help you screen tenants
                        effectively. Download the full guide to start finding
                        reliable renters today
                      </p>

                      <Link to="">
                        <div className="d-flex  align-items-center my-4 gap-2">
                          <p className="fontSize18_Green">More info</p>
                          <button className="more-info-btn">
                            <img src={imagePath.Right_Caret} />
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="lease-generation" id="image-preview">
                    <img
                      src={imagePath.Preview_Image_2}
                      className="w-100 rounded-3 mb-2"
                    />

                    <div className="py-2 px-3">
                      <p className="fontSize20_black_semibold">
                        Lease Generation
                      </p>

                      <p className="fontSize13_LightGray">
                        Our guide includes step-by-step instructions, best
                        practices, and expert tips to help you screen tenants
                        effectively. Download the full guide to start finding
                        reliable renters today
                      </p>

                      <Link to="">
                        <div className="d-flex  align-items-center my-4 gap-2">
                          <p className="fontSize18_Green">More info</p>
                          <button className="more-info-btn">
                            <img src={imagePath.Right_Caret} />
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="lease-generation" id="image-preview">
                    <img
                      src={imagePath.Preview_Image_3}
                      className="w-100 rounded-3 mb-2"
                    />

                    <div className="py-2 px-3">
                      <p className="fontSize20_black_semibold">
                        Lease Generation
                      </p>

                      <p className="fontSize13_LightGray">
                        Our guide includes step-by-step instructions, best
                        practices, and expert tips to help you screen tenants
                        effectively. Download the full guide to start finding
                        reliable renters today
                      </p>

                      <Link to="">
                        <div className="d-flex align-items-center my-4 gap-2">
                          <p className="fontSize18_Green">More info</p>
                          <button className="more-info-btn">
                            <img src={imagePath.Right_Caret} />
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="email-capture-section">
          <div className="container">
            <div className="email-heading">
              <h1 className="fontSize48_black" id="landing-heading">
                Email <span className="fontSize48_green"> Capture</span>{" "}
              </h1>
              <p className="fontSize16_gray">
                Let our AI-driven analysis maximize your property's income
                potential.
              </p>
            </div>

            <div className="enter-email-card" id="enter-email-card-12">
              <h3 className="fontSize20_white">
                Enter Your Email to Download the Full Investment Guide
              </h3>
              <div className="email-input-card">
                <p className="fontSize16_whiteSmoke">Email Address</p>
                <div className="input-card">
                  <input
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="landing-input"
                  />
                  <div>
                    <button className="landing-btn-style">Get My Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="included-in-checklist">
          <div className="container">
            <Heading_Section
              heading_Black="What’s Included in the "
              heading_Green="Guide?"
              subcontent="Download our free 8-point checklist to make sure you're not missing anything important."
              left={true}
            />
            <Included_Checklist listContent="Market Analysis and Trends" />

            <Included_Checklist listContent="Property Selection Criteria" />

            <Included_Checklist listContent="Financing Options" />

            <Included_Checklist listContent="Risk Management Strategies" />

            <Included_Checklist listContent="Property Management Tips" />
            <Included_Checklist listContent="Case Studies and Expert Insights" />
            <div className="why-choose-btn">
              <button className="btn-style">Get My Guide Now</button>
            </div>
          </div>
        </div>

        <div
          className="landing-testimonials-main-section"
          id="landing-section-eight"
        >
          <div className="container">
            <div className="client-testimonials" id="landing-page-12-slide">
              <div className="testimonials">
                <h1 className="fontSize48_black" id="heading">
                  What Our<span className="fontSize48_green"> Client Says</span>{" "}
                </h1>
                <p className="fontSize22_gray ">
                  Discover how our services have transformed property
                  management. Hear firsthand from property owners about their
                  experiences and success stories
                </p>
              </div>

              <Slider {...settings}>
                <div id="landing-page-12-slider-card">
                  <div className="left-img">
                    <img src={imagePath.White_Qoutes} />
                  </div>
                  <div className="comment-section">
                    <img
                      src={imagePath.Thumbs_up_green}
                      className="thumbs-up-img"
                    />
                    <h1 className="fontSize26_green ">Nick Weimann</h1>
                    <p className="fontSize9_Gray">
                      District Directives Representative
                    </p>
                    {/* <div> */}
                    <h3 className="fontSize14_Light_black">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam consequat sunt nostrud
                      amet.
                    </h3>
                    <img src={imagePath.Rating_5} className="rating-img" />
                    {/* </div> */}
                  </div>

                  <div className="user-image">
                    <img src={imagePath.feedback_userImage} />
                  </div>
                </div>

                <div id="landing-page-12-slider-card">
                  <div className="left-img">
                    <img src={imagePath.White_Qoutes} />
                  </div>
                  <div className="comment-section">
                    <img src={imagePath.Thumbs_up_green} />
                    <h1 className="fontSize26_green ">Nick Weimann</h1>
                    <p className="fontSize9_Gray">
                      District Directives Representative
                    </p>
                    {/* <div> */}
                    <h3 className="fontSize14_Light_black">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam consequat sunt nostrud
                      amet.
                    </h3>
                    <img src={imagePath.Rating_5} />
                    {/* </div> */}
                  </div>
                  <div className="user-image">
                    <img src={imagePath.feedback_userImage} />
                    <div className="empty-div"></div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="why-choose-us-section" id="why-choose-us">
          <div className="container" id="landing-page-6-container">
            <div className="core-values-main ">
              <Heading_Section
                white={false}
                heading_Black="Why Use Our "
                heading_Green="Investment Guide?"
                subcontent=" Unlock your property's full income potential with our
                  AI-driven analysis, providing precision insights to maximize
                  your rental earnings."
              />

              <div className="grid-container" id="services">
                <Cards
                  image={imagePath.Idea}
                  cardTitle="Expert Insights"
                  cardDescription="Professional quality means delivering exceptional work that meets high standards, ensuring reliability, expertise, and client satisfaction."
                />

                <Cards
                  image={imagePath.Maximise_Returns}
                  cardTitle="Maximize Returns"
                  cardDescription="Time-saving techniques streamline tasks, enhance productivity, reduce stress, and free up time for meaningful activities"
                />

                <Cards
                  image={imagePath.Report}
                  cardTitle="Comprehensive Coverage"
                  cardDescription="Wide reach allows ideas and messages to impact diverse audiences, fostering broader connections and understanding."
                />
              </div>
            </div>
            <div className="why-choose-btn">
              <button className="btn-style">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <Landing_Footer />
    </>
  );
};

export default LandingPage_12;
