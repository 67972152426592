import React from 'react'

const Detail_Card = ({
    image,
    onClick,
    Button_Name,
    Property_Detail,
    Property_Description,

}) => {
  return (
  <>
<div className="detail-section-main">
<div className="detail-section">
   <div className="img-background">
   <img src={image} alt=''/>
   </div>
    <div className="details">
        <h4 className='fontSize18_black '>{Property_Detail}</h4>
        <p className='fontSize13_bluishGray'>{Property_Description}</p>
    </div>
  </div>
 <a href="">
 <div className="change-button">
    <button onClick={onClick}>{Button_Name}</button>
  </div>
 </a>
</div>
  </>
  )
}

export default Detail_Card