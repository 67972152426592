import React from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import Home from "./screens/Home";
import AboutUs from "./screens/AboutUs";
import Services from "./screens/Services";
import Testimonials from "./screens/Testimonials";
import Pricing from "./screens/Pricing";
import Blog from "./screens/Blog";
import ContactUs from "./screens/ContactUs";
import Work from "./screens/Work";
import FAQComponent from "./screens/FaqComponent/FAQComponent";
import LoginPage from "./screens/Login/LoginPage";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import SignUpPage from "./screens/SignUp/SignUpPage";
import Terms_Condition from "./screens/PrivacyPolicy/Terms_Condition";
import LandingPage_2 from "./screens/LandingPage/LandingPage_2";
import LandingPage_3 from "./screens/LandingPage/LandingPage_3";
import LandingPage_4 from "./screens/LandingPage/LandingPage_4";
import LandingPage_5 from "./screens/LandingPage/LandingPage_5";
import LandingPage_6 from "./screens/LandingPage/LandingPage_6";
import LandingPage_1 from "./screens/LandingPage/LandingPage_1";
import LandingPage_7 from "./screens/LandingPage/LandingPage_7";
import LandingPage_8 from "./screens/LandingPage/LandingPage_8";
import LandingPage_9 from "./screens/LandingPage/LandingPage_9";
import LandingPage_10 from "./screens/LandingPage/LandingPage_10";
import LandingPage_11 from "./screens/LandingPage/LandingPage_11";
import LandingPage_12 from "./screens/LandingPage/LandingPage_12";
import LandingPage_13 from "./screens/LandingPage/LandingPage_13";

const App = () => {
  return (
    <HashRouter>
      <Routes>
      <Route path="/landing-page-13" element={<LandingPage_13/>} />
      <Route path="/landing-page-12" element={<LandingPage_12/>} />
      <Route path="/landing-page-11" element={<LandingPage_11 />} />
        <Route path="/landing-page-10" element={<LandingPage_10 />} />
        <Route path="/landing-page-9" element={<LandingPage_9 />} />
        <Route path="/landing-page-8" element={<LandingPage_8 />} />
        <Route path="/" element={<LandingPage_7 />} />
        <Route path="/landing-page-6" element={<LandingPage_6 />} />
        <Route path="/landing-page-5" element={<LandingPage_5 />} />
        <Route path="/landing-page-4" element={<LandingPage_4 />} />
        <Route path="/landing-page-3" element={<LandingPage_3 />} />
        <Route path="/landing-page-2" element={<LandingPage_2 />} />
        <Route path="/landing-page-1" element={<LandingPage_1 />} />


        <Route  path="/home" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/terms-condition" element={<Terms_Condition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/working" element={<Work />} />
        <Route path="/faq" element={<FAQComponent />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
      </Routes>
    </HashRouter>
  );
};

export default App;
